import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Reject Reason Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function RejectReasonEdit(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const { editElem } = Store.useAction("rejectReason");


    // The Initial Data
    const initialData = {
        rejectReasonID   : 0,
        rejectReasonCode : "",
        name             : "",
        position         : "",
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("rejectReason", initialData, editElem, onSubmit);

    // Load the Data
    const { loading } = useDialog("rejectReason", open, elemID, null, setElem);



    // Do the Render
    return <EditDialog
        open={open}
        icon="reject-reason"
        title={elemID ? "REJECT_REASONS_EDIT_TITLE" : "REJECT_REASONS_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        isNarrow
    >
        <InputField
            name="name"
            label="GENERAL_NAME"
            value={data.name}
            error={errors.name}
            onChange={handleChange}
            isRequired
        />
        <InputField
            name="rejectReasonCode"
            label="GENERAL_CODE"
            value={data.rejectReasonCode}
            error={errors.rejectReasonCode}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="number"
            name="position"
            label="GENERAL_POSITION"
            value={data.position}
            error={errors.position}
            onChange={handleChange}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
RejectReasonEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default RejectReasonEdit;
