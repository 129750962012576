import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";

// Components
import ProcessFile          from "../ProcessFile";
import ProcessSection       from "../Utils/ProcessSection";

// Dashboard
import Button               from "Dashboard/Components/Form/Button";
import InputField           from "Dashboard/Components/Form/InputField";
import Icon                 from "Dashboard/Components/Common/Icon";



// Styles
const Container = Styled(ProcessSection)`
    grid-area: coverage;
`;

const Field = Styled.div`
    position: relative;
`;

const Help = Styled(Icon)`
    position: absolute;
    top: 2px;
    right: 2px;
    font-size: 20px;
    color: var(--primary-color);
    cursor: pointer;
`;



/**
 * The Process View Coverage
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ProcessCoverage(props) {
    const { data, errors, coverageFile, onChange } = props;

    const { elem, tooltips, coverageTypes, coverages } = Store.useState("dashboardProcess");

    // The Current State
    const [ showFile, setShowFile ] = React.useState(false);


    // Variables
    const canEdit         = elem.canEditOrder;
    const typeTooltip     = Commons.getTooltip(tooltips, "CoverageType");
    const planTooltip     = Commons.getTooltip(tooltips, "CoveragePlan");
    const dniTooltip      = Commons.getTooltip(tooltips, "CoverageDNI");
    const credTooltip     = Commons.getTooltip(tooltips, "CoverageCredential");

    const reqCoverage     = Utils.getValue(coverageTypes, "key", data.coverageTypeID, "reqCoverage", false);
    const showName        = !data.coverageID;
    const showPlan        = !data.coverageID || !Utils.getValue(coverages, "key", data.coverageID, "noPlanRequired", false);
    const showDNI         = Boolean(data.coverageID && Utils.getValue(coverages, "key", data.coverageID, "reqDNI", false));

    const coverageFileUrl = coverageFile ? `${process.env.REACT_APP_DRIVE}${elem.subsidiaryID}${coverageFile}` : elem.coverageFileUrl;
    const coverageIsPDF   = coverageFile ? coverageFile.endsWith(".pdf")  : elem.coverageFileIsPDF;
    const coverageIsImage = coverageFile ? !coverageFile.endsWith(".pdf") : elem.coverageFileIsImage;


    // Do the Render
    if (!reqCoverage) {
        return <Container message="ORDERS_COVERAGE">
            <InputField
                type="select"
                name="coverageTypeID"
                label="COVERAGE_TYPES_SINGULAR"
                options={coverageTypes}
                value={data.coverageTypeID}
                error={errors.coverageTypeID}
                onChange={onChange}
                isDisabled={!canEdit}
                isRequired={canEdit}
                hideClear
            />
        </Container>;
    }

    return <>
        <Container message="ORDERS_COVERAGE">
            <InputField
                type="select"
                name="coverageTypeID"
                label="COVERAGE_TYPES_SINGULAR"
                options={coverageTypes}
                value={data.coverageTypeID}
                error={errors.coverageTypeID}
                onChange={onChange}
                isDisabled={!canEdit}
                isRequired={canEdit}
                hideClear
            />
            <InputField
                isHidden={!data.coverageTypeID}
                type="select"
                name="coverageID"
                label="COVERAGES_SINGULAR"
                options={coverages}
                value={data.coverageID}
                error={errors.coverageID}
                onChange={onChange}
                isDisabled={!canEdit}
                isRequired={canEdit}
                noneText="COVERAGES_OTHER"
                shrinkLabel
                hideClear
            />

            {showName && <Field>
                <InputField
                    name="clientCoverageName"
                    label="ORDERS_COVERAGE_NAME"
                    value={data.clientCoverageName}
                    error={errors.clientCoverageName}
                    onChange={onChange}
                    isDisabled={!canEdit}
                    isRequired={canEdit}
                />
                <Help
                    isHidden={!typeTooltip}
                    icon="help"
                    tooltip={typeTooltip}
                    tooltipWidth={300}
                    tooltipDelay={0.2}
                />
            </Field>}

            {showPlan && <Field>
                <InputField
                    name="clientCoveragePlan"
                    label="ORDERS_COVERAGE_PLAN"
                    value={data.clientCoveragePlan}
                    error={errors.clientCoveragePlan}
                    onChange={onChange}
                    isDisabled={!canEdit}
                    isRequired={canEdit}
                />
                <Help
                    isHidden={!planTooltip}
                    icon="help"
                    tooltip={planTooltip}
                    tooltipWidth={300}
                    tooltipDelay={0.2}
                />
            </Field>}

            {showDNI && <Field>
                <InputField
                    name="clientCoverageDni"
                    label="ORDERS_COVERAGE_DNI"
                    value={data.clientCoverageDni}
                    error={errors.clientCoverageDni}
                    onChange={onChange}
                    isDisabled={!canEdit}
                    isRequired={canEdit}
                />
                <Help
                    isHidden={!dniTooltip}
                    icon="help"
                    tooltip={dniTooltip}
                    tooltipWidth={300}
                    tooltipDelay={0.2}
                />
            </Field>}

            <Field>
                <InputField
                    name="clientCoverageCredential"
                    label="ORDERS_COVERAGE_CREDENTIAL"
                    value={data.clientCoverageCredential}
                    error={errors.clientCoverageCredential}
                    onChange={onChange}
                    isDisabled={!canEdit}
                    isRequired={canEdit}
                />
                <Help
                    isHidden={!credTooltip}
                    icon="help"
                    tooltip={credTooltip}
                    tooltipWidth={300}
                    tooltipDelay={0.2}
                />
            </Field>

            <Button
                isHidden={!coverageFileUrl}
                variant="outlined"
                icon="attachment"
                message="ORDERS_COVERAGE_VIEW"
                onClick={() => setShowFile(true)}
                fullWidth
                isSmall
            />
        </Container>

        <ProcessFile
            open={showFile}
            title="ORDERS_COVERAGE_VIEW"
            isPDF={coverageIsPDF}
            isImage={coverageIsImage}
            fileUrl={coverageFileUrl}
            onClose={() => setShowFile(false)}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ProcessCoverage.propTypes = {
    data         : PropTypes.object.isRequired,
    errors       : PropTypes.object.isRequired,
    coverageFile : PropTypes.string.isRequired,
    onChange     : PropTypes.func.isRequired,
};

export default ProcessCoverage;
