import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Coverage Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function CoverageEdit(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const { retirementTypes, gateways, statuses } = Store.useState("coverage");
    const { editElem } = Store.useAction("coverage");


    // The Initial Data
    const initialData = {
        coverageID        : 0,
        coverageCode      : "",
        name              : "",
        allRetirements    : 0,
        retirementTypeIDs : [],
        allGateways       : 0,
        gatewayCodes      : [],
        approveMinutes    : 0,
        payMinutes        : 0,
        removeRetireExp   : 0,
        noPlanRequired    : 0,
        reqDNI            : 0,
        status            : "Active",
        position          : "",
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("coverage", initialData, editElem, onSubmit);

    // Load the Data
    const { loading } = useDialog("coverage", open, elemID, null, setElem);



    // Do the Render
    return <EditDialog
        open={open}
        icon="coverage"
        title={elemID ? "COVERAGES_EDIT_TITLE" : "COVERAGES_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <Columns>
            <InputField
                name="name"
                label="GENERAL_NAME"
                value={data.name}
                error={errors.name}
                onChange={handleChange}
                isRequired
            />
            <InputField
                name="coverageCode"
                label="GENERAL_CODE"
                value={data.coverageCode}
                error={errors.coverageCode}
                onChange={handleChange}
                isRequired
            />

            <InputField
                className="columns-double"
                type="toggle"
                name="allRetirements"
                label="COVERAGES_ALL_RETIREMENTS"
                value={data.allRetirements}
                error={errors.allRetirements}
                onChange={handleChange}
                withBorder
            />
            <InputField
                isHidden={!!data.allRetirements}
                className="columns-double"
                type="chooser"
                name="retirementTypeIDs"
                label="RETIREMENT_TYPES_NAME"
                options={retirementTypes}
                value={data.retirementTypeIDs}
                error={errors.retirementTypeIDs}
                onChange={handleChange}
                isRequired
            />

            <InputField
                className="columns-double"
                type="toggle"
                name="allGateways"
                label="COVERAGES_ALL_GATEWAYS"
                value={data.allGateways}
                error={errors.allGateways}
                onChange={handleChange}
                withBorder
            />
            <InputField
                isHidden={!!data.allGateways}
                className="columns-double"
                type="chooser"
                name="gatewayCodes"
                label="GATEWAYS_NAME"
                options={gateways}
                value={data.gatewayCodes}
                error={errors.gatewayCodes}
                onChange={handleChange}
                isRequired
            />

            <InputField
                type="number"
                name="approveMinutes"
                label="COVERAGES_APPROVE_MINUTES"
                helperText="COVERAGES_EMPTY_MINUTES"
                value={data.approveMinutes}
                error={errors.approveMinutes}
                onChange={handleChange}
            />
            <InputField
                type="number"
                name="payMinutes"
                label="COVERAGES_PAY_MINUTES"
                helperText="COVERAGES_EMPTY_MINUTES"
                value={data.payMinutes}
                error={errors.payMinutes}
                onChange={handleChange}
            />
            <InputField
                className="columns-double"
                type="toggle"
                name="removeRetireExp"
                label="COVERAGES_REMOVE_RETIRE_EXPIRATION"
                value={data.removeRetireExp}
                error={errors.removeRetireExp}
                onChange={handleChange}
                withBorder
            />

            <InputField
                type="toggle"
                name="noPlanRequired"
                label="COVERAGES_NO_PLAN_REQUIRED"
                value={data.noPlanRequired}
                error={errors.noPlanRequired}
                onChange={handleChange}
                withBorder
            />
            <InputField
                type="toggle"
                name="reqDNI"
                label="COVERAGES_REQ_AFFILIATE_DNI"
                value={data.reqDNI}
                error={errors.reqDNI}
                onChange={handleChange}
                withBorder
            />

            <InputField
                type="select"
                name="status"
                label="GENERAL_STATUS"
                options={statuses}
                value={data.status}
                error={errors.status}
                onChange={handleChange}
                hideClear
                isRequired
            />
            <InputField
                type="number"
                name="position"
                label="GENERAL_POSITION"
                value={data.position}
                error={errors.position}
                onChange={handleChange}
            />
        </Columns>
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
CoverageEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default CoverageEdit;
