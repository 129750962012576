import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";

// Components
import AgentTotals          from "./Components/AgentTotals";
import AgentCharts          from "./Components/AgentCharts";



// Styles
const Container = Styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: var(--main-gap);
`;



/**
 * The Agent Content
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function AgentContent(props) {
    const { isHidden } = props;

    const { filters } = Store.useState("dashboardAgent");
    const { fetchList } = Store.useAction("dashboardAgent");


    // Auto Update
    Utils.useAutoUpdate(fetchList, isHidden, filters);


    // Do the Render
    if (isHidden) {
        return <React.Fragment />;
    }
    return <Container>
        <AgentTotals />
        <AgentCharts />
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
AgentContent.propTypes = {
    isHidden : PropTypes.bool.isRequired,
};

export default AgentContent;
