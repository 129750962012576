import React                from "react";
import Action               from "Dashboard/Core/Action";
import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";
import { Order }            from "Utils/API";

// Components
import OrderFilter          from "./Dialogs/OrderFilter";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import Html                 from "Dashboard/Components/Common/Html";



/**
 * The Order List User
 * @returns {React.ReactElement}
 */
function OrderListUser() {
    const { load, loadTab, loadFilter } = useList("order", "USER", false);

    const { orderTab } = Navigate.useParams();
    const navigate     = Navigate.useGoto();

    const { isAgent, multiSubsidiary } = Store.useState("auth");

    const data = Store.useState("order");
    const { loading, canEdit, list, total, filters, sort } = data;


    // The Current State
    const [ action, updateAction ] = Action.useAction();


    // Handle the Update
    React.useEffect(() => {
        if (Number.isInteger(orderTab)) {
            navigate("ORDERS", "ALL", orderTab);
        } else if (orderTab) {
            const newTab = NLS.urlToKey(orderTab, "EFFECTIVE", "VALIDATION", "PENDING", "REJECTED", "CANCELED");
            if (!newTab) {
                navigate("ORDERS", "EFFECTIVE");
            } else {
                fetch(newTab);
            }
        } else {
            navigate("ORDERS", "EFFECTIVE");
        }
    }, [ orderTab ]);

    // Loads the data and sets an auto-update
    const fetch = (newTab) => {
        if (newTab !== sort.filter) {
            loadTab(newTab, 50, false);
        } else {
            load(sort, false);
        }
    };


    // Handles the Action
    const handleAction = (action, elemID) => {
        if (action.isTab) {
            updateAction();
            navigate("ORDERS", elemID);
        } else if (action.isView) {
            updateAction();
            navigate("ORDERS", orderTab, elemID);
        } else if (action.isExport) {
            updateAction();
            const source = Order.export({ ...filters, filter : sort.filter });
            Utils.download(source, NLS.get("ORDERS_FILE_NAME"));
        } else {
            updateAction(action, elemID);
        }
    };

    // Handles the Filter Submit
    const handleFilter = (filters) => {
        updateAction();
        loadFilter(filters);
    };


    // Variables
    const isCanceled = sort.filter === "CANCELED";


    // Do the Render
    return <Main>
        <Header message="ORDERS_NAME" icon="order">
            <ActionList data={data} onAction={handleAction} />
        </Header>
        <Content>
            <TabList selected={sort.filter} onAction={handleAction}>
                <TabItem message="ORDERS_EFFECTIVE"  value="EFFECTIVE"  />
                <TabItem message="ORDERS_VALIDATION" value="VALIDATION" isHidden={!isAgent} />
                <TabItem message="ORDERS_PENDING"    value="PENDING"    />
                <TabItem message="ORDERS_REJECTED"   value="REJECTED"   />
                <TabItem message="ORDERS_CANCELED"   value="CANCELED"   />
            </TabList>
            <Table
                fetch={load}
                sort={sort}
                none="ORDERS_NONE_AVAILABLE"
                isLoading={loading}
            >
                <TableHead>
                    <TableHeader field="createdTime"        message="GENERAL_CREATED_AT_FEM"    minWidth="160" maxWidth="160" />
                    <TableHeader field="orderNumber"        message="ORDERS_SINGULAR"           maxWidth="80" />
                    <TableHeader field="subsidiaryFullName" message="SUBSIDIARIES_SINGULAR"     isHidden={!multiSubsidiary} />
                    <TableHeader field="clientFirstName"    message="CLIENTS_SINGULAR"          />
                    <TableHeader field="retirementTypeName" message="RETIREMENT_TYPES_SINGULAR" />
                    <TableHeader field="orderStateName"     message="GENERAL_STATUS"            isHidden={isCanceled} />
                    <TableHeader field="totalQuantity"      message="GENERAL_QUANTITY"          align="center" maxWidth="80" />
                    <TableHeader field="rejectReasonName"   message="REJECT_REASONS_SINGULAR"   isHidden={!isCanceled} />
                    <TableHeader field="rejectReasonReason" message="REJECT_REASONS_MESSAGE"    isHidden={!isCanceled} />
                    <TableHeader field="cancelReasonName"   message="CANCEL_REASONS_SINGULAR"   isHidden={!isCanceled} />
                    <TableHeader field="cancelReasonReason" message="CANCEL_REASONS_MESSAGE"    isHidden={!isCanceled} />
                    <TableHeader field="total"              message="GENERAL_TOTAL"             align="right" maxWidth="80" />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.orderID} elemID={elem.orderID}>
                        <TableCell message={elem.createdTimeDate}    />
                        <TableCell message={elem.numberText}         />
                        <TableCell message={elem.subsidiaryFullName} />
                        <TableCell message={elem.clientName}         />
                        <TableCell message={elem.retirementTypeName} />
                        <TableCell message={elem.orderStateName}     />
                        <TableCell message={elem.totalQuantity}      />
                        <TableCell message={elem.rejectReasonName}   />
                        <TableCell message={elem.rejectReasonReason} />
                        <TableCell message={elem.cancelReasonName}   />
                        <TableCell message={elem.cancelReasonReason} />
                        <TableCell><Html>{elem.netTotalText}</Html></TableCell>
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={handleAction} canEdit={canEdit}>
                    <TableAction action="VIEW" message="ORDERS_VIEW_TITLE" />
                </TableActionList>
            </Table>
        </Content>

        <OrderFilter
            open={action.isFilter}
            filters={filters}
            forCanceled={isCanceled}
            onSubmit={handleFilter}
            onClose={updateAction}
        />
    </Main>;
}

export default OrderListUser;
