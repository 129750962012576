import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";
import Columns              from "Dashboard/Components/Form/Columns";



/**
 * The Objective Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ObjectiveEdit(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const { editElem } = Store.useAction("objective");


    // The Initial Data
    const initialData = {
        objectiveID : 0,
        month       : new Date().getMonth(),
        year        : new Date().getFullYear(),
        grossTotal  : "",
        salesTotal  : "",
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("objective", initialData, editElem, onSubmit);

    // Load the Data
    const { loading } = useDialog("objective", open, elemID, null, setElem);



    // Do the Render
    return <EditDialog
        open={open}
        icon="objective"
        title={elemID ? "OBJECTIVES_EDIT_TITLE" : "OBJECTIVES_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        isNarrow
    >
        <Columns>
            <InputField
                type="select"
                name="month"
                label="GENERAL_MONTH"
                options="DATE_MONTH_NAMES_ONE"
                value={data.month}
                error={errors.month}
                onChange={handleChange}
                isRequired
            />
            <InputField
                type="number"
                name="year"
                label="GENERAL_YEAR"
                value={data.year}
                error={errors.year}
                onChange={handleChange}
                isRequired
            />
        </Columns>

        <InputField
            type="number"
            name="grossTotal"
            label="OBJECTIVES_GROSS_TOTAL"
            icon="price"
            value={data.grossTotal}
            error={errors.grossTotal}
            onChange={handleChange}
        />
        <InputField
            type="number"
            name="salesTotal"
            label="OBJECTIVES_SALES_TOTAL"
            value={data.salesTotal}
            error={errors.salesTotal}
            onChange={handleChange}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ObjectiveEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default ObjectiveEdit;
