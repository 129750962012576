import React                from "react";
import PropTypes            from "prop-types";
import { Bar }              from "react-chartjs-2";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Charts               from "Utils/Charts";

// Components
import DashboardReport      from "../../Utils/Components/DashboardReport";



/**
 * The Commercial Chart Bar
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function CommercialChartBar(props) {
    const { title, tooltip, data : {
        labels, grossTotals, netTotals,
    }, isDaily } = props;

    const { holidays, events } = Store.useState("dashboardCommercial");


    // Do the Render
    return <DashboardReport
        message={title}
        tooltip={tooltip}
        height="400"
    >
        <Bar
            options={{
                maintainAspectRatio : false,
                interaction : {
                    mode      : "index",
                    intersect : false,
                },
                scales : {
                    x : Charts.getDateScale(holidays, events, isDaily),
                    y : Charts.getSimpleScale(true, false, false),
                },
                plugins : {
                    legend  : Charts.getLegendOptions("top"),
                    tooltip : Charts.getDateTooltip(holidays, events, isDaily),
                },
            }}
            data={{
                labels   : labels,
                datasets : [
                    {
                        // @ts-ignore
                        prefix           : "$ ",
                        order            : 0,
                        yAxisID          : "y",
                        label            : NLS.get("DASHBOARD_COMMERCIAL_GROSS_BILLING"),
                        data             : grossTotals,
                        borderColor      : "rgb(59, 199, 181)",
                        backgroundColor  : "rgb(59, 199, 181)",
                        pointBorderWidth : 4,
                        borderRadius     : 6,
                    },
                    {
                        // @ts-ignore
                        prefix           : "$ ",
                        order            : 1,
                        yAxisID          : "y",
                        label            : NLS.get("DASHBOARD_COMMERCIAL_NET_BILLING"),
                        data             : netTotals,
                        borderColor      : "rgb(2, 131, 200)",
                        backgroundColor  : "rgb(2, 131, 200)",
                        pointBorderWidth : 4,
                        borderRadius     : 6,
                    },
                ],
            }}
        />
    </DashboardReport>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
CommercialChartBar.propTypes = {
    title   : PropTypes.string.isRequired,
    tooltip : PropTypes.string.isRequired,
    data    : PropTypes.object.isRequired,
    isDaily : PropTypes.bool,
};

/**
 * The Default Properties
 * @type {Object} defaultProps
 */
CommercialChartBar.defaultProps = {
    isDaily : false,
};

export default CommercialChartBar;
