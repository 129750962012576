import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import DialogMessage        from "Dashboard/Components/Dialog/DialogMessage";
import InputField           from "Dashboard/Components/Form/InputField";
import Columns              from "Dashboard/Components/Form/Columns";



/**
 * The Holiday Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function HolidayEdit(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const { editElem } = Store.useAction("holiday");


    // The Initial Data
    const initialData = {
        holidayID : 0,
        name      : "",
        day       : "",
        month     : "",
        year      : "",
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("holiday", initialData, editElem, onSubmit);

    // Load the Data
    const { loading } = useDialog("holiday", open, elemID, null, setElem);



    // Do the Render
    return <EditDialog
        open={open}
        icon="holiday"
        title={elemID ? "HOLIDAYS_EDIT_TITLE" : "HOLIDAYS_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        isNarrow
    >
        <InputField
            name="name"
            label="GENERAL_NAME"
            value={data.name}
            error={errors.name}
            onChange={handleChange}
            isRequired
        />
        <Columns amount="3">
            <InputField
                type="number"
                name="day"
                label="GENERAL_DAY"
                value={data.day}
                error={errors.day}
                onChange={handleChange}
                isRequired
            />
            <InputField
                type="select"
                name="month"
                label="GENERAL_MONTH"
                options="DATE_MONTH_NAMES_ONE"
                value={data.month}
                error={errors.month}
                onChange={handleChange}
                hideClear
                isRequired
            />
            <InputField
                type="number"
                name="year"
                label="GENERAL_YEAR"
                value={data.year}
                error={errors.year}
                onChange={handleChange}
            />
        </Columns>
        <DialogMessage message="HOLIDAYS_YEAR_NOTE" />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
HolidayEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default HolidayEdit;
