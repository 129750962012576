import React                from "react";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";

// Components
import MedicineGeneral      from "./View/MedicineGeneral";
import MedicineDrugs        from "./View/MedicineDrugs";
import MedicineEdit         from "./Dialogs/MedicineEdit";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import ActionList           from "Dashboard/Components/Header/ActionList";
import ActionItem           from "Dashboard/Components/Header/ActionItem";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";



// Styles
const Container = Styled(Content)`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;



/**
 * The Medicine Page
 * @returns {React.ReactElement}
 */
function MedicinePage() {
    const { medicineID } = Navigate.useParams();
    const parent         = Navigate.useParent(1);
    const navigate       = Navigate.useGoto();

    const { charging, canEdit, elem, drugs, productID } = Store.useState("medicine");
    const { fetchElem } = Store.useAction("medicine");

    // The Current State
    const [ showEdit, setShowEdit ] = React.useState(false);


    // Load the Data
    React.useEffect(() => {
        fetchElem(medicineID);
    }, [ medicineID ]);

    // Handles the Goto Product
    const handleGotoProduct = () => {
        navigate("CATALOG", "PRODUCTS", productID);
    };

    // Handles the Edit Submit
    const handleEdit = () => {
        setShowEdit(false);
        fetchElem(medicineID);
    };


    // Do the Render
    return <Main>
        <Header
            message={elem.name || "MEDICINES_SINGULAR"}
            icon="medicine"
            href={parent}
        >
            <ActionList>
                <ActionItem
                    isHidden={!productID}
                    icon="product"
                    message="PRODUCTS_VIEW_TITLE"
                    onClick={() => handleGotoProduct()}
                />
                <ActionItem
                    isHidden={!canEdit}
                    action="EDIT"
                    onClick={() => setShowEdit(true)}
                />
            </ActionList>
        </Header>

        <Container isLoading={charging} className="tables">
            <MedicineGeneral elem={elem} />
            <MedicineDrugs list={drugs} />
        </Container>

        <MedicineEdit
            open={showEdit}
            elemID={medicineID}
            onSubmit={handleEdit}
            onClose={() => setShowEdit(false)}
        />
    </Main>;
}

export default MedicinePage;
