import React                from "react";
import Styled               from "styled-components";
import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import ActionItem           from "Dashboard/Components/Header/ActionItem";
import PageContainer        from "Dashboard/Components/Page/PageContainer";
import PageColumns          from "Dashboard/Components/Page/PageColumns";
import PageFooter           from "Dashboard/Components/Page/PageFooter";
import InputField           from "Dashboard/Components/Form/InputField";
import Button               from "Dashboard/Components/Form/Button";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



// Styles
const Title = Styled.h3`
    margin: 24px 0 0 0;
    font-family: var(--title-font);
    font-weight: var(--title-font-weight);
    font-size: 18px;
`;



/**
 * The Subsidiary Page
 * @returns {React.ReactElement}
 */
function SubsidiaryPage() {
    const { subsidiaryID } = Navigate.useParams();

    const navigate = Navigate.useGoto();
    const parent   = Navigate.useParent(2);

    const { charging, edition, elem, pharmacies } = Store.useState("subsidiary");
    const { fetchElem, editElem, deleteElem } = Store.useAction("subsidiary");


    // The Current State
    const [ showDelete, setShowDelete ] = React.useState(false);

    // The Initial Data
    const initialData = {
        subsidiaryID       : 0,
        pharmacyID         : 0,
        subsidiaryCode     : "",
        name               : "",
        socialReason       : "",
        cuit               : "",
        email              : "",
        phone              : "",
        city               : "",
        commune            : "",
        location           : "",
        image              : "",
        latitude           : "",
        longitude          : "",
        color              : "",
        retireDistance     : "",
        enableAgent        : 1,
        allowsAfterHours   : 1,
        hideInPharmacyList : 0,
    };

    // Handles the Edit
    const handleEdit = async (data) => {
        const result = await editElem(data);
        if (result.success) {
            fetchElem(subsidiaryID);
        }
    };

    // Handles the Delete
    const handleDelete = async () => {
        await deleteElem(elem.id);
        navigate(parent);
    };

    // The Form State
    const {
        loading, data, errors, setElem, handleChange, handleSubmit,
    } = useForm("subsidiary", initialData, handleEdit);


    // Load the Data
    React.useEffect(() => {
        if (subsidiaryID) {
            fetchElem(subsidiaryID);
        }
    }, [ subsidiaryID ]);

    // Store the Data
    React.useEffect(() => {
        setElem({ ...elem });
    }, [ edition ]);


    // Shows the Google Map
    const handleMap = () => {
        window.open(`https://maps.google.com?q=${data.latitude},${data.longitude}`);
    };

    // Goes to the Pharmacy
    const handlePharmacy = () => {
        navigate("BUSINESS", "PHARMACIES", data.pharmacyID);
    };



    // Do the Render
    return <Main>
        <Header message="GENERAL_INFO" icon="subsidiary">
            <ActionList>
                <ActionItem
                    action="VIEW"
                    message="SUBSIDIARIES_VIEW_MAP"
                    onClick={handleMap}
                />
                <ActionItem
                    action="PHARMACY"
                    message="PHARMACIES_VIEW_TITLE"
                    onClick={handlePharmacy}
                />
            </ActionList>
        </Header>

        <Content isLoading={charging || loading}>
            <PageContainer error={errors.form}>
                <PageColumns>
                    <InputField
                        type="select"
                        name="pharmacyID"
                        label="PHARMACIES_SINGULAR"
                        options={pharmacies}
                        value={data.pharmacyID}
                        error={errors.pharmacyID}
                        onChange={handleChange}
                        isRequired
                    />

                    <InputField
                        name="name"
                        label="GENERAL_NAME"
                        value={data.name}
                        error={errors.name}
                        onChange={handleChange}
                        isRequired
                    />
                    <InputField
                        name="subsidiaryCode"
                        label="GENERAL_CODE"
                        value={data.subsidiaryCode}
                        error={errors.subsidiaryCode}
                        onChange={handleChange}
                        isRequired
                    />

                    <InputField
                        name="socialReason"
                        label="GENERAL_SOCIAL_REASON"
                        value={data.socialReason}
                        error={errors.socialReason}
                        onChange={handleChange}
                    />
                    <InputField
                        name="cuit"
                        label="SUBSIDIARIES_CUIT"
                        value={data.cuit}
                        error={errors.cuit}
                        onChange={handleChange}
                    />

                    <InputField
                        type="email"
                        name="email"
                        label="GENERAL_EMAIL"
                        value={data.email}
                        error={errors.email}
                        onChange={handleChange}
                    />
                    <InputField
                        name="phone"
                        label="GENERAL_PHONE"
                        value={data.phone}
                        error={errors.phone}
                        onChange={handleChange}
                    />

                    <InputField
                        name="city"
                        label="GENERAL_CITY"
                        value={data.city}
                        error={errors.city}
                        onChange={handleChange}
                    />
                    <InputField
                        name="commune"
                        label="GENERAL_COMMUNE"
                        value={data.commune}
                        error={errors.commune}
                        onChange={handleChange}
                    />
                    <InputField
                        name="location"
                        label="GENERAL_LOCATION"
                        value={data.location}
                        error={errors.location}
                        onChange={handleChange}
                    />

                    <InputField
                        name="latitude"
                        label="GENERAL_LATITUDE"
                        value={data.latitude}
                        error={errors.latitude}
                        onChange={handleChange}
                    />
                    <InputField
                        name="longitude"
                        label="GENERAL_LONGITUDE"
                        value={data.longitude}
                        error={errors.longitude}
                        onChange={handleChange}
                    />

                    <InputField
                        name="image"
                        label="GENERAL_IMAGE_URL"
                        value={data.image}
                        error={errors.image}
                        onChange={handleChange}
                    />
                    <InputField
                        type="color"
                        name="color"
                        label="GENERAL_COLOR"
                        value={data.color}
                        error={errors.color}
                        onChange={handleChange}
                    />

                    <Title className="columns-double">
                        {NLS.get("GENERAL_OPTIONS")}
                    </Title>

                    <InputField
                        type="number"
                        name="retireDistance"
                        label="PHARMACIES_RETIRE_DISTANCE"
                        helperText="PHARMACIES_RETIRE_DISTANCE_HELP"
                        value={data.retireDistance}
                        error={errors.retireDistance}
                        onChange={handleChange}
                    />
                    <InputField
                        type="toggle"
                        name="enableAgent"
                        label="PHARMACIES_ENABLE_AGENT"
                        value={data.enableAgent}
                        error={errors.enableAgent}
                        onChange={handleChange}
                        withBorder
                    />
                    <InputField
                        type="toggle"
                        name="allowsAfterHours"
                        label="SUBSIDIARIES_ALLOWS_AFTER_HOURS"
                        value={data.allowsAfterHours}
                        error={errors.allowsAfterHours}
                        onChange={handleChange}
                        withBorder
                    />
                    <InputField
                        type="toggle"
                        name="hideInPharmacyList"
                        label="PHARMACIES_HIDE_IN_LIST"
                        value={data.hideInPharmacyList}
                        error={errors.hideInPharmacyList}
                        onChange={handleChange}
                        withBorder
                    />
                </PageColumns>

                <PageFooter>
                    <Button
                        variant="primary"
                        message="GENERAL_SAVE"
                        onClick={() => handleSubmit()}
                        isDisabled={loading}
                    />
                    <Button
                        variant="primary"
                        message="GENERAL_DELETE"
                        onClick={() => setShowDelete(true)}
                    />
                </PageFooter>
            </PageContainer>
        </Content>

        <DeleteDialog
            open={showDelete}
            title="SUBSIDIARIES_DELETE_TITLE"
            message="SUBSIDIARIES_DELETE_TEXT"
            content={data.name}
            onSubmit={handleDelete}
            onClose={() => setShowDelete(false)}
        />
    </Main>;
}

export default SubsidiaryPage;
