import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";
import InputItem            from "Dashboard/Components/Form/InputItem";
import Columns              from "Dashboard/Components/Form/Columns";

// APIs
import {
    Laboratory, MonoDrug, PharmaAction,
} from "Utils/API";



/**
 * The Product Multi Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ProductEdit(props) {
    const { open, elemIDs, onClose, onSubmit } = props;

    const {
        macroCategories, categoriesMacro, subCategoriesCategory,
        saleTypes, shapeTypes, potencyTypes,
    } = Store.useState("product");

    const { fetchEditData, editMultiElem } = Store.useAction("product");


    // The Initial Data
    const initialData = {
        macroCategoryID  : 0,
        categoryID       : 0,
        subCategoryID    : 0,
        laboratoryID     : 0,
        laboratoryName   : "",
        monoDrugID       : 0,
        monoDrugName     : "",
        saleTypeID       : 0,
        shapeTypeID      : 0,
        presentation     : "",
        potency          : "",
        potencyTypeID    : 0,
        units            : "",
        pharmaActionID   : 0,
        pharmaActionName : "",
        forCeliac        : 0,
        reqRefrigeration : 0,
        reqRecipe        : "",
        isActive         : 0,
    };

    // Handles the Set
    const handleSet = () => {
        resetData();
    };

    // Handles the Edit
    const handleEdit = (data) => {
        return editMultiElem({
            ...data,
            productIDs : JSON.stringify(elemIDs),
        });
    };

    // Handles the Category
    const handleCategory = (name, value) => {
        if (name === "macroCategoryID") {
            setData({ macroCategoryID : value, categoryID : 0, subCategoryID : 0 });
        } else if (name === "categoryID") {
            setData({ categoryID : value, subCategoryID : 0 });
        } else {
            handleChange(name, value);
        }
    };

    // The Form State
    const {
        data, errors, setData, resetData, handleChange, handleSubmit,
    } = useForm("product", initialData, handleEdit, onSubmit);

    // Load the Data
    const { loading } = useDialog("product", open, elemIDs, null, handleSet, fetchEditData);



    // Do the Render
    return <EditDialog
        open={open}
        icon="product"
        title="PRODUCTS_MULTI_EDIT_TITLE"
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        noAutoFocus
    >
        <Columns lastDouble>
            <InputField
                type="select"
                name="macroCategoryID"
                label="MACRO_CATEGORIES_SINGULAR"
                options={macroCategories}
                value={data.macroCategoryID}
                error={errors.macroCategoryID}
                onChange={handleCategory}
            />
            <InputField
                type="select"
                name="categoryID"
                label="CATEGORIES_SINGULAR"
                options={data.macroCategoryID ? categoriesMacro[data.macroCategoryID] : []}
                value={data.categoryID}
                error={errors.categoryID}
                onChange={handleCategory}
            />
            <InputField
                type="select"
                name="subCategoryID"
                label="SUB_CATEGORIES_SINGULAR"
                options={data.categoryID ? subCategoriesCategory[data.categoryID] : []}
                value={data.subCategoryID}
                error={errors.subCategoryID}
                onChange={handleChange}
            />

            <InputField
                type="suggest"
                name="laboratoryName"
                label="LABORATORIES_SINGULAR"
                value={data.laboratoryName}
                error={errors.laboratoryID}
                suggestID="laboratoryID"
                suggestFetch={Laboratory.search}
                onChange={handleChange}
            />
            <InputField
                type="suggest"
                name="monoDrugName"
                label="MONO_DRUGS_SINGULAR"
                value={data.monoDrugName}
                error={errors.monoDrugID}
                suggestID="monoDrugID"
                suggestFetch={MonoDrug.search}
                onChange={handleChange}
            />

            <InputField
                type="select"
                name="saleTypeID"
                label="SALE_TYPES_SINGULAR"
                options={saleTypes}
                value={data.saleTypeID}
                error={errors.saleTypeID}
                onChange={handleChange}
            />
            <InputField
                type="select"
                name="shapeTypeID"
                label="SHAPE_TYPES_SINGULAR"
                options={shapeTypes}
                value={data.shapeTypeID}
                error={errors.shapeTypeID}
                onChange={handleChange}
            />
            <InputField
                name="presentation"
                label="GENERAL_PRESENTATION"
                value={data.presentation}
                error={errors.presentation}
                onChange={handleChange}
            />

            <InputField
                type="double"
                label="GENERAL_POTENCY"
                error={errors.potency}
                onChange={handleChange}
            >
                <InputItem
                    name="potency"
                    value={data.potency}
                />
                <InputItem
                    type="select"
                    name="potencyTypeID"
                    placeholder="POTENCY_TYPES_SINGULAR"
                    options={potencyTypes}
                    value={data.potencyTypeID}
                />
            </InputField>
            <InputField
                name="units"
                label="PRODUCTS_QUANTITY_PER_CONTAINER"
                value={data.units}
                error={errors.units}
                onChange={handleChange}
            />

            <InputField
                type="suggest"
                name="pharmaActionName"
                label="PHARMA_ACTIONS_SINGULAR"
                value={data.pharmaActionName}
                error={errors.pharmaActionID}
                suggestID="pharmaActionID"
                suggestFetch={PharmaAction.search}
                onChange={handleChange}
            />
            <InputField
                type="select"
                name="forCeliac"
                label="GENERAL_FOR_CELIAC"
                options="GENERAL_SET_YES_NO"
                value={data.forCeliac}
                error={errors.forCeliac}
                onChange={handleChange}
            />
            <InputField
                type="select"
                name="reqRefrigeration"
                label="GENERAL_REQ_REFRIGERATION"
                options="GENERAL_SET_YES_NO"
                value={data.reqRefrigeration}
                error={errors.reqRefrigeration}
                onChange={handleChange}
            />
            <InputField
                type="select"
                name="reqRecipe"
                label="GENERAL_REQ_RECIPE"
                options="SELECT_REQ_RECIPES"
                value={data.reqRecipe}
                error={errors.reqRecipe}
                onChange={handleChange}
            />
            <InputField
                type="select"
                name="isActive"
                label="GENERAL_IS_ACTIVE"
                options="GENERAL_SET_YES_NO"
                value={data.isActive}
                error={errors.isActive}
                onChange={handleChange}
            />
        </Columns>
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ProductEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemIDs  : PropTypes.arrayOf(PropTypes.number),
};

export default ProductEdit;
