import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";

// The API
import {
    SubsidiaryClosed,
} from "Utils/API";



// The initial State
const initialState = {
    loading       : true,
    error         : false,
    edition       : 0,
    canCreate     : false,
    canEdit       : false,
    canFilter     : false,
    canImport     : false,
    canExport     : false,
    list          : [],
    total         : 0,
    elem          : {},
    subsidiaries  : [],
    closedReasons : [],
    statuses      : [],
    sort          : {
        orderBy  : "closedReasonName",
        orderAsc : 1,
        page     : 0,
        amount   : 50,
    },
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "SUBSIDIARY_CLOSED_LOADING" });
    },

    /**
     * Fetches the Subsidiary Closed List
     * @param {Function} dispatch
     * @param {String=}  type
     * @param {Number=}  elemID
     * @param {Object=}  params
     * @returns {Promise}
     */
    async fetchList(dispatch, type = "", elemID = 0, params = {}) {
        Navigate.unsetParams(params);
        if (type === "PHARMACY") {
            params.pharmacyID = elemID;
        } else if (type === "SUBSIDIARY") {
            params.subsidiaryID = elemID;
        }
        const data = await SubsidiaryClosed.getAll(params);
        data.sort  = params;
        dispatch({ type : "SUBSIDIARY_CLOSED_LIST", data });
    },

    /**
     * Fetches a single Subsidiary Closed
     * @param {Function} dispatch
     * @param {Number}   subsidiaryClosedID
     * @returns {Promise}
     */
    async fetchElem(dispatch, subsidiaryClosedID) {
        const data = await SubsidiaryClosed.getOne({ subsidiaryClosedID });
        dispatch({ type : "SUBSIDIARY_CLOSED_ELEM", data });
    },

    /**
     * Fetches the Subsidiary Closed Edit data
     * @param {Function} dispatch
     * @param {Number}   pharmacyID
     * @returns {Promise}
     */
    async fetchEditData(dispatch, pharmacyID) {
        const data = await SubsidiaryClosed.getEditData({ pharmacyID });
        dispatch({ type : "SUBSIDIARY_CLOSED_EDIT", data });
    },

    /**
     * Creates a Subsidiary Closed
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    createElem(dispatch, data) {
        return SubsidiaryClosed.create(data);
    },

    /**
     * Edits the given Subsidiary Closed
     * @param {Function} dispatch
     * @param {Object}   data
     * @returns {Promise}
     */
    editElem(dispatch, data) {
        return SubsidiaryClosed.edit(data);
    },

    /**
     * Deletes a Subsidiary Closed
     * @param {Function} dispatch
     * @param {Number}   subsidiaryClosedID
     * @returns {Promise}
     */
    deleteElem(dispatch, subsidiaryClosedID) {
        return SubsidiaryClosed.delete({ subsidiaryClosedID });
    },
};



/**
 * Parses a single Element
 * @param {Object} elem
 * @returns {Object}
 */
function parseElem(elem) {
    elem.subsidiaryIDs  = elem.subsidiaries.map(({ subsidiaryID }) => subsidiaryID);
    elem.subsidiaryText = elem.subsidiaries.map(({ subsidiaryFullName }) => subsidiaryFullName).join(", ");

    elem.fromTimeText   = DateTime.formatDate(elem.fromTime, "dashes");
    elem.fromDate       = DateTime.formatDate(elem.fromTime, "dashesReverse");
    elem.toTimeText     = DateTime.formatDate(elem.toTime, "dashes");
    elem.toDate         = DateTime.formatDate(elem.toTime, "dashesReverse");
    return elem;
}

/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "SUBSIDIARY_CLOSED_LIST", "SUBSIDIARY_CLOSED_ELEM", "SUBSIDIARY_CLOSED_EDIT")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "SUBSIDIARY_CLOSED_LOADING":
        return {
            ...state,
            loading       : true,
        };

    case "SUBSIDIARY_CLOSED_LIST":
        return {
            ...state,
            loading       : false,
            error         : false,
            canCreate     : action.data.canCreate,
            canEdit       : action.data.canEdit,
            canFilter     : action.data.canFilter,
            canImport     : action.data.canImport,
            canExport     : action.data.canExport,
            list          : Utils.parseList(action.data.list, parseElem),
            total         : action.data.total,
            sort          : action.data.sort,
        };

    case "SUBSIDIARY_CLOSED_ELEM":
        return {
            ...state,
            error         : false,
            edition       : state.edition + 1,
            elem          : parseElem(action.data.elem),
            subsidiaries  : action.data.subsidiaries,
            closedReasons : action.data.closedReasons,
            statuses      : action.data.statuses,
        };

    case "SUBSIDIARY_CLOSED_EDIT":
        return {
            ...state,
            error         : false,
            edition       : state.edition + 1,
            subsidiaries  : action.data.subsidiaries,
            closedReasons : action.data.closedReasons,
            statuses      : action.data.statuses,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
