import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";

// Components
import DashboardTitle       from "./DashboardTitle";



// Styles
const Container = Styled.section`
    box-sizing: border-box;
    padding: var(--main-gap);
    border: 1px solid var(--border-color-light);
    border-radius: var(--border-radius);
`;

const Chart = Styled.div.attrs(({ height }) => ({ height }))`
    ${(props) => props.height ? `height: ${props.height}px;` : `
        height: calc(var(--dashboard-list) - 32px - 2 * var(--main-gap));
    `}
    margin-top: 8px;
`;



/**
 * The Dashboard Report
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function DashboardReport(props) {
    const { message, tooltip, height, children } = props;


    // Do the Render
    return <Container>
        <DashboardTitle message={message} tooltip={tooltip} />
        <Chart height={height}>
            {children}
        </Chart>
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
DashboardReport.propTypes = {
    message  : PropTypes.string.isRequired,
    tooltip  : PropTypes.string,
    height   : PropTypes.string,
    children : PropTypes.any,
};

export default DashboardReport;
