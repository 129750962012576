import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import DialogMessage        from "Dashboard/Components/Dialog/DialogMessage";
import InputField           from "Dashboard/Components/Form/InputField";
import InputItem            from "Dashboard/Components/Form/InputItem";



/**
 * The Order Delivered Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function OrderDelivered(props) {
    const { open, elem, onClose, onSubmit } = props;

    const { deliveredElem } = Store.useAction("order");


    // The Initial Data
    const initialData = {
        transitDate   : "",
        transitTime   : "",
        deliveredDate : "",
        deliveredTime : "",
    };

    // Handle the Edit
    const handleEdit = (data) => {
        return deliveredElem({ ...data, orderID : elem.id });
    };

    // The Form State
    const {
        loading, data, errors, handleChange, handleSubmit,
    } = useForm("order", initialData, handleEdit, onSubmit);


    // Do the Render
    return <EditDialog
        open={open}
        icon="check"
        title="ORDERS_DELIVERED_TITLE"
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        isNarrow
    >
        <DialogMessage
            variant="h3"
            message="ORDERS_DELIVERED_TEXT"
            noSpace
        />

        <InputField
            isHidden={elem.transitTime > 0}
            type="double"
            error={errors.transitDate}
            onChange={handleChange}
        >
            <InputItem
                type="date"
                name="transitDate"
                label="ORDERS_TRANSIT_DATE"
                maxValue="2999-12-31"
                value={data.transitDate}
            />
            <InputItem
                type="time"
                name="transitTime"
                label="ORDERS_TRANSIT_TIME"
                value={data.transitTime}
            />
        </InputField>

        <InputField
            isHidden={elem.retiredTime > 0}
            type="double"
            helperText="ORDERS_EMPTY_DATE_TIP"
            error={errors.deliveredDate}
            onChange={handleChange}
        >
            <InputItem
                type="date"
                name="deliveredDate"
                label="ORDERS_DELIVERED_DATE"
                value={data.deliveredDate}
                maxValue="2999-12-31"
            />
            <InputItem
                type="time"
                name="deliveredTime"
                label="ORDERS_DELIVERED_TIME"
                value={data.deliveredTime}
            />
        </InputField>
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
OrderDelivered.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elem     : PropTypes.object,
};

export default OrderDelivered;
