import React                from "react";
import Action               from "Dashboard/Core/Action";
import Navigate             from "Dashboard/Core/Navigate";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";

// Components
import ChangeProductEdit    from "./ChangeProductEdit";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";
import Filter               from "Dashboard/Components/Filter/Filter";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";



/**
 * The Change Product List
 * @returns {React.ReactElement}
 */
function ChangeProductList() {
    const { load, loadFilter, loadTab } = useList("changeProduct", "", false);

    const { changeProductTab, productID } = Navigate.useParams();
    const navigate = Navigate.useGoto();

    const data = Store.useState("changeProduct");
    const { loading, canEdit, canValidate, list, total, filters, sort } = data;

    const { startUsingElem, stopUsingElem } = Store.useAction("changeProduct");


    // The References
    const timerRef = React.useRef(0);

    // The Current State
    const [ update, setUpdate ] = React.useState(0);

    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Handles the Log change
    React.useEffect(() => {
        if (productID) {
            startAction("EDIT", productID);
        }
    }, [ productID ]);

    // Handle the Auto-update and Unload
    React.useEffect(() => {
        Utils.setUpdateTimeout(timerRef, setUpdate, update);

        if (elemID) {
            window.addEventListener("beforeunload", () => stopUsingElem(elemID));
        }
        return () => {
            Utils.clearTimeout(timerRef);
            window.removeEventListener("beforeunload", () => stopUsingElem(elemID));
        };
    }, [ elemID ]);

    // Handle the Update
    React.useEffect(() => {
        if (changeProductTab) {
            fetch(changeProductTab);
        } else {
            navigate("CATALOG", "CHANGE_PRODUCTS", "PROCESS");
        }
    }, [ changeProductTab, update ]);

    // Loads the data and sets an auto-update
    const fetch = (changeProductTab) => {
        const newTab = NLS.urlToKey(changeProductTab, "PROCESS", "VALIDATE", "COMPLETE");
        if (newTab !== sort.filter) {
            loadTab(newTab, 50, false);
        } else {
            load(sort, false);
        }

        Utils.setUpdateTimeout(timerRef, setUpdate, update);
    };


    // Handles the Action
    const handleAction = async (action, elemID) => {
        if (action.isTab) {
            endAction();
            navigate("CATALOG", "CHANGE_PRODUCTS", elemID);
            return;
        }

        if (action.isEdit) {
            const isProcessed = Utils.getValue(list, "changeProductID", elemID, "isProcessed");
            if (isProcessed) {
                startAction(action, elemID);
                return;
            }
            const result = await startUsingElem(elemID);
            if (!result.error) {
                startAction(action, elemID);
            }
            return;
        }

        startAction(action, elemID);
    };

    // Handles the Close
    const handleClose = () => {
        stopUsingElem(elemID);
        endAction();
        navigate("CATALOG", "CHANGE_PRODUCTS", changeProductTab);
    };

    // Handles the Edit Submit
    const handleEdit = () => {
        handleClose();
        fetch(changeProductTab);
    };


    // Variables
    const isProcess    = sort.filter === "PROCESS";
    const showValidate = canValidate && !isProcess;


    // Do the Render
    return <Main>
        <Header message="CHANGE_PRODUCTS_NAME" icon="change-product" />
        <Content>
            <TabList
                isHidden={!canValidate}
                selected={sort.filter}
                onAction={handleAction}
            >
                <TabItem message="CHANGE_PRODUCTS_PROCESS"  value="PROCESS"  />
                <TabItem message="CHANGE_PRODUCTS_VALIDATE" value="VALIDATE" />
            </TabList>
            <Filter
                onFilter={loadFilter}
                values={filters}
                hasSearch
            />
            <Table
                fetch={load}
                sort={sort}
                none="CHANGE_PRODUCTS_NONE_AVAILABLE"
                isLoading={loading}
                noSorting={!canValidate}
            >
                <TableHead>
                    <TableHeader field="createdTime"                message="GENERAL_CREATED_AT"           maxWidth="180" hideCircle={!isProcess} />
                    <TableHeader field="modificationType"           message="GENERAL_TYPE"                 maxWidth="80" />
                    <TableHeader field="alfabetaID"                 message="GENERAL_ALFABETA"             maxWidth="80" />
                    <TableHeader field="benvidaID"                  message="GENERAL_BENVIDA"              maxWidth="80" />
                    <TableHeader field="name"                       message="GENERAL_NAME"                 isTitle />
                    <TableHeader field="changeAmount"               message="GENERAL_CHANGES"              maxWidth="80" align="center" />
                    <TableHeader field="processTime"                message="CHANGE_PRODUCTS_PROCESSED_AT" maxWidth="140" isHidden={!showValidate} />
                    <TableHeader field="processCredentialFirstName" message="CHANGE_PRODUCTS_PROCESSED_BY" isHidden={!showValidate} />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.changeProductID} elemID={elem.changeProductID}>
                        <TableCell message={elem.createdDate}           circle={elem.usingCircle} />
                        <TableCell message={elem.modificationTypeText}  />
                        <TableCell message={elem.alfabetaID}            />
                        <TableCell message={elem.benvidaID}             />
                        <TableCell message={elem.name}                  />
                        <TableCell message={elem.changeAmount}          />
                        <TableCell message={elem.processDate}           />
                        <TableCell message={elem.processCredentialName} />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={handleAction} canEdit={canEdit}>
                    <TableAction action="EDIT" message="CHANGE_PRODUCTS_EDIT_TITLE" navigate />
                </TableActionList>
            </Table>
        </Content>

        <ChangeProductEdit
            open={action.isEdit}
            elemID={elemID}
            onClose={handleClose}
            onSubmit={handleEdit}
        />
    </Main>;
}

export default ChangeProductList;
