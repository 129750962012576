import React                from "react";
import PropTypes            from "prop-types";
import { Bar }              from "react-chartjs-2";
import Charts               from "Utils/Charts";

// Components
import DashboardContainer   from "../Components/DashboardContainer";
import DashboardReport      from "../Components/DashboardReport";
import DashboardTable       from "../Components/DashboardTable";



/**
 * The Bar Line Chart
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function BarLineChart(props) {
    const {
        title, tooltip, data, fieldBar, fieldLine,
        barPrefix, linePrefix, isPrice, children,
    } = props;


    // Create the Datasets
    const datasets = React.useMemo(() => {
        const datasets = [];
        const lines    = {};
        const bars     = {};
        const colors   = {};
        let   colorIdx = 0;

        for (const item of data) {
            for (const group of Object.values(item.groups)) {
                if (lines[group.name] !== undefined) {
                    continue;
                }
                lines[group.name]  = datasets.length;
                colors[group.name] = Charts.getColor(colorIdx++, group.color);

                datasets.push({
                    type            : "line",
                    yAxisID         : "y1",
                    label           : `${linePrefix}: ${group.name}`,
                    borderColor     : colors[group.name],
                    backgroundColor : colors[group.name],
                    borderRadius    : 6,
                    borderDash      : [ 10, 5 ],
                    data            : [],
                });
            }
        }

        for (const item of data) {
            for (const group of Object.values(item.groups)) {
                if (bars[group.name] !== undefined) {
                    continue;
                }
                bars[group.name] = datasets.length;

                datasets.push({
                    type            : "bar",
                    yAxisID         : "y",
                    label           : `${barPrefix}: ${group.name}`,
                    backgroundColor : colors[group.name],
                    borderRadius    : 6,
                    data            : [],
                    prefix          : isPrice ? "$ " : "",
                });
            }
        }

        for (const [ group, idx ] of Object.entries(lines)) {
            for (const [ index, item ] of data.entries()) {
                if (!datasets[idx].data[index]) {
                    datasets[idx].data[index] = 0;
                }
                const valueLine = item.groups[group]?.[fieldLine] || 0;
                datasets[idx].data[index] += valueLine;
            }
        }

        for (const [ group, idx ] of Object.entries(bars)) {
            for (const [ index, item ] of data.entries()) {
                if (!datasets[idx].data[index]) {
                    datasets[idx].data[index] = 0;
                }
                const valueBar = item.groups[group]?.[fieldBar] || 0;
                datasets[idx].data[index] += valueBar;
            }
        }

        return datasets;
    }, [ JSON.stringify(data) ]);


    // Do the Render
    return <DashboardContainer>
        <DashboardReport message={title} tooltip={tooltip}>
            <Bar
                options={{
                    maintainAspectRatio : false,
                    interaction : {
                        mode      : "index",
                        intersect : false,
                    },
                    scales  : {
                        x  : { stacked : true },
                        y  : Charts.getSimpleScale(isPrice, false, true),
                        y1 : {
                            display  : true,
                            type     : "linear",
                            position : "right",
                            ticks    : { precision : 0 },
                            grid     : { drawOnChartArea : false },
                        },
                    },
                    plugins : {
                        legend  : Charts.getLegendOptions("top"),
                        tooltip : Charts.getTooltipOptions(isPrice, {}, {
                            position : "nearest",
                            yAlign   : "center",
                            itemSort(a, b) {
                                return Number(b.raw) - Number(a.raw);
                            },
                        }),
                    },
                }}
                data={{
                    // @ts-ignore
                    datasets : datasets,
                    labels   : Charts.getLabelList(data, "", 20),
                }}
            />
        </DashboardReport>

        <DashboardTable>
            {children}
        </DashboardTable>
    </DashboardContainer>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
BarLineChart.propTypes = {
    title      : PropTypes.string.isRequired,
    tooltip    : PropTypes.string,
    data       : PropTypes.array.isRequired,
    fieldBar   : PropTypes.string.isRequired,
    fieldLine  : PropTypes.string.isRequired,
    barPrefix  : PropTypes.string.isRequired,
    linePrefix : PropTypes.string.isRequired,
    isPrice    : PropTypes.bool,
    children   : PropTypes.any,
};

export default BarLineChart;
