import React                from "react";
import Styled               from "styled-components";
import Action               from "Dashboard/Core/Action";
import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";

// Components
import ProcessView          from "./Process/ProcessView";
import ProcessColumn        from "./Process/ProcessColumn";
import ProcessAlert         from "./Process/ProcessAlert";
import ProcessFile          from "./Process/ProcessFile";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import ActionItem           from "Dashboard/Components/Header/ActionItem";
import AlertDialog          from "Dashboard/Components/Dialogs/AlertDialog";



// Styles
const Container = Styled(Content)`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
`;



/**
 * The Dashboard Process Page
 * @returns {React.ReactElement}
 */
function DashboardProcessPage() {
    const { orderID } = Navigate.useParams();
    const navigate    = Navigate.useGoto();

    const { isAgent } = Store.useState("auth");
    const { tooltips } = Store.useState("dashboardProcess");
    const { fetchList, startUsingElem, stopUsingElem } = Store.useAction("dashboardProcess");


    // The References
    const updateTimerRef = React.useRef(0);
    const dateTimerRef   = React.useRef(0);

    // The Current State
    const [ action, elemID, startAction, endAction ] = Action.useActionID();

    const [ initialized, setInitialized ] = React.useState(false);
    const [ update,      setUpdate      ] = React.useState(0);
    const [ seconds,     setSeconds     ] = React.useState(0);
    const [ message,     setMessage     ] = React.useState("");
    const [ tooltipUrl,  setTooltipUrl  ] = React.useState("");
    const [ tooltipText, setTooltipText ] = React.useState("");
    const [ tooltipType, setTooltipType ] = React.useState("");


    // Initial Fetch
    React.useEffect(() => {
        fetchList();
        setInitialized(true);
        Utils.setUpdateTimeout(updateTimerRef, setUpdate, update);
        Utils.setUpdateTimeout(dateTimerRef, setSeconds, seconds, 1);

        return () => {
            Utils.clearTimeout(updateTimerRef);
            Utils.clearTimeout(dateTimerRef);
        };
    }, []);

    // Handle the Unload
    React.useEffect(() => {
        if (elemID) {
            window.addEventListener("beforeunload", () => stopUsingElem(elemID));
        }
        return () => {
            window.removeEventListener("beforeunload", () => stopUsingElem(elemID));
        };
    }, [ elemID ]);

    // Handle the Update
    React.useEffect(() => {
        if (initialized) {
            fetchList();
            Utils.setUpdateTimeout(updateTimerRef, setUpdate, update);
        }
    }, [ update ]);

    // Handle the Seconds to update the Live Date
    React.useEffect(() => {
        if (initialized) {
            Utils.setUpdateTimeout(dateTimerRef, setSeconds, seconds, 1);
        }
    }, [ seconds ]);

    // Handle the Order ID
    React.useEffect(() => {
        if (orderID) {
            startAction("VIEW", orderID);
        }
    }, [ orderID ]);


    // Handles the Open
    const handleOpen = async (elemID) => {
        const result = await startUsingElem(elemID);
        if (!result.error) {
            navigate("DASHBOARD", elemID);
        }
    };

    // Handles the Close
    const handleClose = () => {
        stopUsingElem(elemID);
        navigate("DASHBOARD");
        endAction();
    };

    // Handles the Update
    const handleUpdate = (message) => {
        if (message) {
            setMessage(message);
        }
        handleClose();
        fetchList();
    };

    // Handles the Tooltip
    const handleTooltip = (url = "", text = "", type = "") => {
        setTooltipUrl(url);
        setTooltipText(text);
        setTooltipType(type);
    };


    // Variables
    const tooltipKey  = isAgent ? "ValidationHelp" : "MainHelp";
    const helpPDFUrl  = tooltips[tooltipKey]?.pdfUrl  || "";
    const helpContent = tooltips[tooltipKey]?.content || "";


    // Do the Render
    return <Main>
        <Header message="DASHBOARD_NAME" icon="home">
            <ActionList>
                <ActionItem
                    isHidden={!helpPDFUrl}
                    icon="help"
                    message="GENERAL_HELP"
                    onClick={() => handleTooltip(helpPDFUrl, helpContent, "pdf")}
                />
            </ActionList>
        </Header>

        {!isAgent && <Container>
            <ProcessColumn
                type="Pending"
                message="DASHBOARD_PROCESS_COLUMN_PENDING"
                onClick={handleOpen}
                setTooltip={handleTooltip}
            />
            <ProcessColumn
                type="Approved"
                message="DASHBOARD_PROCESS_COLUMN_APPROVED"
                onClick={handleOpen}
                setTooltip={handleTooltip}
            />
            <ProcessColumn
                type="Ready"
                message="DASHBOARD_PROCESS_COLUMN_READY"
                onClick={handleOpen}
                setTooltip={handleTooltip}
            />
        </Container>}

        {isAgent && <Container>
            <ProcessColumn
                type="ValidationQueue"
                message="DASHBOARD_PROCESS_VALIDATION_QUEUE"
                onClick={handleOpen}
                setTooltip={handleTooltip}
            />
            <ProcessColumn
                type="ValidationAssigned"
                message="DASHBOARD_PROCESS_VALIDATION_ASSIGNED"
                onClick={handleOpen}
                setTooltip={handleTooltip}
            />
            <ProcessColumn
                type="ValidationWaiting"
                message="DASHBOARD_PROCESS_VALIDATION_WAITING"
                onClick={handleOpen}
                setTooltip={handleTooltip}
            />
        </Container>}

        <ProcessView
            open={action.isView}
            elemID={elemID}
            onSubmit={handleUpdate}
            onClose={handleClose}
        />
        <ProcessFile
            open={!!tooltipUrl}
            fileUrl={tooltipUrl}
            text={tooltipText}
            onClose={() => handleTooltip()}
            isVideo={tooltipType === "video"}
            isPDF={tooltipType === "pdf"}
        />
        <AlertDialog
            open={!!message}
            icon="order"
            title="DASHBOARD_PROCESS_UPDATED_TITLE"
            message={message}
            onClose={() => setMessage("")}
        />
        <ProcessAlert
            onClick={handleOpen}
        />
    </Main>;
}

export default DashboardProcessPage;
