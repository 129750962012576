import React                from "react";
import PropTypes            from "prop-types";
import { Bar }              from "react-chartjs-2";
import Charts               from "Utils/Charts";

// Components
import DashboardContainer   from "../Components/DashboardContainer";
import DashboardReport      from "../Components/DashboardReport";
import DashboardTable       from "../Components/DashboardTable";



/**
 * The Bar Chart
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function BarChart(props) {
    const { title, tooltip, data, field, isPrice, children } = props;


    // Create the Datasets
    const datasets = React.useMemo(() => {
        const datasets = {};
        const totals   = [];
        let   color    = 0;

        // Create the Groups
        for (const item of data) {
            for (const group of Object.values(item.groups)) {
                if (!datasets[group.name]) {
                    datasets[group.name] = {
                        label           : group.name,
                        backgroundColor : Charts.getColor(color++, group.color),
                        borderRadius    : 6,
                        data            : [],
                        suffix          : "%",
                    };
                }
            }
        }

        // Add the Totals
        for (const [ group, dataset ] of Object.entries(datasets)) {
            for (const [ index, item ] of data.entries()) {
                if (!dataset.data[index]) {
                    dataset.data[index] = 0;
                }
                if (!totals[index]) {
                    totals[index] = 0;
                }
                const value = item.groups[group]?.[field] || 0;
                dataset.data[index] += value;
                totals[index]       += value;
            }
        }

        // Convert to a Percent
        for (const dataset of Object.values(datasets)) {
            for (const [ index, value ] of dataset.data.entries()) {
                dataset.data[index] = Math.floor(((value * 100) / totals[index]) * 100) / 100;
            }
        }

        return Object.values(datasets);
    }, [ JSON.stringify(data), field ]);


    // Do the Render
    return <DashboardContainer>
        <DashboardReport message={title} tooltip={tooltip}>
            <Bar
                options={{
                    maintainAspectRatio : false,
                    interaction : {
                        mode      : "index",
                        intersect : false,
                    },
                    scales  : {
                        y : Charts.getSimpleScale(false, true, true),
                        x : { stacked : true },
                    },
                    plugins : {
                        legend  : Charts.getLegendOptions("top"),
                        tooltip : Charts.getTooltipOptions(isPrice, {}, {
                            position : "nearest",
                            yAlign   : "center",
                            itemSort(a, b) {
                                return Number(b.raw) - Number(a.raw);
                            },
                        }),
                    },
                }}
                data={{
                    labels   : Charts.getLabelList(data, "", 20),
                    datasets : datasets,
                }}
            />
        </DashboardReport>

        <DashboardTable>
            {children}
        </DashboardTable>
    </DashboardContainer>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
BarChart.propTypes = {
    title    : PropTypes.string.isRequired,
    tooltip  : PropTypes.string,
    data     : PropTypes.array.isRequired,
    field    : PropTypes.string.isRequired,
    isPrice  : PropTypes.bool,
    children : PropTypes.any,
};

export default BarChart;
