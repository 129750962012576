import React                from "react";
import Store                from "Dashboard/Core/Store";
import Action               from "Dashboard/Core/Action";
import useList              from "Dashboard/Hooks/List";

// Components
import RetirementTypeEdit   from "./RetirementTypeEdit";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import ColorCircle          from "Dashboard/Components/Common/ColorCircle";



/**
 * The Retirement Type List
 * @returns {React.ReactElement}
 */
function RetirementTypeList() {
    const { fetch, load } = useList("retirementType");

    const data = Store.useState("retirementType");
    const { loading, canEdit, list, total, sort } = data;


    // The Current State
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Handles the Edit Submit
    const handleEdit = () => {
        endAction();
        fetch();
    };


    // Do the Render
    return <Main>
        <Header message="RETIREMENT_TYPES_NAME" icon="retirement-type">
            <ActionList data={data} onAction={startAction} />
        </Header>
        <Content>
            <Table
                fetch={load}
                sort={sort}
                none="RETIREMENT_TYPES_NONE_AVAILABLE"
                isLoading={loading}
            >
                <TableHead>
                    <TableHeader field="name"           message="GENERAL_NAME"     isTitle isFlex smallSpace />
                    <TableHeader field="retirementCode" message="GENERAL_CODE"     />
                    <TableHeader field="position"       message="GENERAL_POSITION" maxWidth="80" align="center" hideMobile />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.retirementTypeID} elemID={elem.retirementTypeID}>
                        <TableCell>
                            <ColorCircle color={elem.color} />
                            {elem.name}
                        </TableCell>
                        <TableCell message={elem.retirementCode} />
                        <TableCell message={elem.position}       />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={startAction} canEdit={canEdit}>
                    <TableAction action="EDIT" message="RETIREMENT_TYPES_EDIT_TITLE" />
                </TableActionList>
            </Table>
        </Content>

        <RetirementTypeEdit
            open={action.isVCE}
            elemID={elemID}
            onSubmit={handleEdit}
            onClose={endAction}
        />
    </Main>;
}

export default RetirementTypeList;
