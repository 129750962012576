import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useFilter            from "Dashboard/Hooks/Filter";
import Utils                from "Dashboard/Utils/Utils";

// Dashboard
import FilterDialog         from "Dashboard/Components/Dialogs/FilterDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Gateway Filter Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function GatewayFilter(props) {
    const { open, filters, pharmacyID, onClose, onSubmit } = props;

    const { gateways, pharmacies, subsidiaries, subsidiariesPerPharmacy } = Store.useState("subsidiaryGateway");


    // The Initial Data
    const initialData = {
        exclude      : 0,
        pharmacyID   : [],
        subsidiaryID : [],
        gatewayCode  : [],
        providerID   : "",
        isActive     : 0,
        noProvider   : 0,
        isExpired    : 0,
    };

    // The Filter State
    const {
        loading, data, handleChange, handleSubmit,
    } = useFilter("subsidiaryGateway", open, initialData, filters, onSubmit);

    // Returns a select of subsidiaries based on the pharmacies
    const pharmacyIDs     = pharmacyID ? [ pharmacyID ] : data.pharmacyID;
    const getSubsidiaries = Utils.useSelectList(loading, pharmacyIDs, subsidiaries, subsidiariesPerPharmacy);


    // Do the Render
    return <FilterDialog
        open={open}
        title="GATEWAYS_FILTER_TITLE"
        initialData={Utils.clone(initialData)}
        data={data}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <InputField
            type="toggle"
            name="exclude"
            label="GENERAL_EXCLUDE_FILTER"
            value={data.exclude}
            onChange={handleChange}
            withBorder
        />
        <InputField
            isHidden={!!pharmacyID}
            type="chooser"
            name="pharmacyID"
            label="PHARMACIES_NAME"
            options={pharmacies}
            value={data.pharmacyID}
            onChange={handleChange}
        />
        <InputField
            type="chooser"
            name="subsidiaryID"
            label="SUBSIDIARIES_NAME"
            options={getSubsidiaries()}
            value={data.subsidiaryID}
            onChange={handleChange}
        />

        <InputField
            type="chooser"
            name="gatewayCode"
            label="GATEWAYS_NAME"
            options={gateways}
            value={data.gatewayCode}
            onChange={handleChange}
        />
        <InputField
            type="number"
            name="providerID"
            label="GATEWAYS_USER_ID"
            value={data.providerID}
            onChange={handleChange}
        />
        <InputField
            type="select"
            name="isActive"
            label="GENERAL_IS_ACTIVE_FEM"
            options="GENERAL_SET_YES_NO"
            value={data.isActive}
            onChange={handleChange}
        />
        <InputField
            type="toggle"
            name="noProvider"
            label="GATEWAYS_NO_PROVIDER"
            value={data.noProvider}
            onChange={handleChange}
            withBorder
        />
        <InputField
            type="toggle"
            name="isExpired"
            label="GENERAL_IS_EXPIRED_FEM"
            value={data.isExpired}
            onChange={handleChange}
            withBorder
        />
    </FilterDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
GatewayFilter.propTypes = {
    open       : PropTypes.bool.isRequired,
    onClose    : PropTypes.func.isRequired,
    onSubmit   : PropTypes.func.isRequired,
    filters    : PropTypes.object,
    pharmacyID : PropTypes.number,
};

export default GatewayFilter;
