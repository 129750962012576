import React                from "react";
import PropTypes            from "prop-types";
import MediaType            from "Dashboard/Core/MediaType";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Components
import MediaField           from "Components/Utils/Inputs/MediaField";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";
import InputItem            from "Dashboard/Components/Form/InputItem";



/**
 * The Banner Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function BannerEdit(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const { statuses } = Store.useState("webBanner");
    const { editElem } = Store.useAction("webBanner");


    // The Initial Data
    const initialData = {
        webBannerID : 0,
        name        : "",
        image       : "",
        url         : "",
        fromDate    : "",
        fromHour    : "",
        toDate      : "",
        toHour      : "",
        status      : "Active",
        position    : "",
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("webBanner", initialData, editElem, onSubmit);

    // Load the Data
    const { loading } = useDialog("webBanner", open, elemID, null, setElem);


    // Do the Render
    return <EditDialog
        open={open}
        icon="banner"
        title={elemID ? "BANNERS_EDIT_TITLE" : "BANNERS_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        isNarrow
    >
        <InputField
            name="name"
            label="GENERAL_NAME"
            value={data.name}
            error={errors.name}
            onChange={handleChange}
            isRequired
        />
        <MediaField
            name="image"
            label="GENERAL_IMAGE"
            mediaType={MediaType.IMAGE}
            value={data.image}
            error={errors.image}
            onChange={handleChange}
            isRequired
        />
        <InputField
            name="url"
            label="GENERAL_URL"
            value={data.url}
            error={errors.url}
            onChange={handleChange}
        />

        <InputField
            type="double"
            error={errors.fromDate}
            onChange={handleChange}
        >
            <InputItem
                type="date"
                name="fromDate"
                label="GENERAL_FROM_DATE"
                maxValue="2999-12-31"
                value={data.fromDate}
                isRequired
            />
            <InputItem
                type="time"
                name="fromHour"
                label="GENERAL_FROM_HOUR"
                value={data.fromHour}
                isRequired
            />
        </InputField>
        <InputField
            type="double"
            error={errors.toDate}
            onChange={handleChange}
        >
            <InputItem
                type="date"
                name="toDate"
                label="GENERAL_TO_DATE"
                maxValue="2999-12-31"
                value={data.toDate}
                isRequired
            />
            <InputItem
                type="time"
                name="toHour"
                label="GENERAL_TO_HOUR"
                value={data.toHour}
                isRequired
            />
        </InputField>

        <InputField
            type="select"
            name="status"
            label="GENERAL_STATUS"
            options={statuses}
            value={data.status}
            error={errors.status}
            onChange={handleChange}
            hideClear
            isRequired
        />
        <InputField
            type="number"
            name="position"
            label="GENERAL_POSITION"
            value={data.position}
            error={errors.position}
            onChange={handleChange}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
BannerEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default BannerEdit;
