import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import Commons              from "Utils/Commons";

// Components
import OrderProducts        from "./OrderProducts";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import DialogMessage        from "Dashboard/Components/Dialog/DialogMessage";
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";
import InputItem            from "Dashboard/Components/Form/InputItem";



/**
 * The Order Retired Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function OrderRetired(props) {
    const { open, elem, products, onClose, onSubmit } = props;

    const { tooltips, saleTypes } = Store.useState("order");
    const { retiredElem } = Store.useAction("order");


    // The References
    const unitPrices = React.useRef([]);
    const pamiPrices = React.useRef([]);
    const prices     = React.useRef([]);
    const subTotals  = React.useRef([]);

    // The Current State
    const [ tab, setTab ] = React.useState("info");

    // The Initial Data
    const initialData = {
        approvedDate : "",
        approvedTime : "",
        retireDate   : "",
        retireTime   : "",
        retiredDate  : "",
        retiredTime  : "",
        products     : "[{}]",
    };

    // Handle the Edit
    const handleEdit = (data) => {
        return retiredElem({ ...data, orderID : elem.id });
    };

    // The Form State
    const {
        loading, data, errors, setElem, handleChange, handleSubmit,
    } = useForm("order", initialData, handleEdit, onSubmit);


    // Handles the Initial Open
    React.useEffect(() => {
        if (!open) {
            return;
        }
        prices.current    = [];
        subTotals.current = [];

        Commons.initializeOrderTotals(products, unitPrices, prices, subTotals);

        setTab("info");
        setElem({ products : Commons.encodeOrderProducts(products) });
    }, [ open ]);

    // Returns the Error Count in the Tab
    const getErrorCount = (tab) => {
        let result = 0;
        for (const [ key, error ] of Object.entries(errors)) {
            if (!error) {
                continue;
            }
            if (tab === "info" && !key.startsWith("products")) {
                result += 1;
            } else if (tab === "products" && key.startsWith(tab)) {
                result += 1;
            }
        }
        return result;
    };


    // Do the Render
    return <EditDialog
        open={open}
        icon="check"
        title="ORDERS_RETIRED_TITLE"
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        width={700}
    >
        <TabList selected={tab} onClick={setTab} inDialog>
            <TabItem
                message="ORDERS_RETIRED_INFO"
                value="info"
                badge={getErrorCount("info")}
            />
            <TabItem
                message="PRODUCTS_NAME"
                value="products"
                badge={getErrorCount("products")}
            />
        </TabList>

        <DialogMessage
            variant="h3"
            message="ORDERS_RETIRED_TEXT"
            noSpace
        />

        <Columns isHidden={tab !== "info"} amount="1">
            <InputField
                isHidden={elem.approvedTime > 0}
                type="double"
                helperText="ORDERS_EMPTY_DATE_TIP"
                error={errors.approvedDate}
                onChange={handleChange}
            >
                <InputItem
                    type="date"
                    name="approvedDate"
                    label="ORDERS_APPROVED_DATE"
                    value={data.approvedDate}
                    maxValue="2999-12-31"
                />
                <InputItem
                    type="time"
                    name="approvedTime"
                    label="ORDERS_APPROVED_TIME"
                    value={data.approvedTime}
                />
            </InputField>

            <InputField
                isHidden={elem.retireTime > 0}
                type="double"
                helperText="ORDERS_EMPTY_DATE_TIP"
                error={errors.retireDate}
                onChange={handleChange}
            >
                <InputItem
                    type="date"
                    name="retireDate"
                    label="ORDERS_RETIRE_DATE"
                    value={data.retireDate}
                    maxValue="2999-12-31"
                />
                <InputItem
                    type="time"
                    name="retireTime"
                    label="ORDERS_RETIRE_TIME"
                    value={data.retireTime}
                />
            </InputField>

            <InputField
                isHidden={elem.retiredTime > 0}
                type="double"
                helperText="ORDERS_EMPTY_DATE_TIP"
                error={errors.retiredDate}
                onChange={handleChange}
            >
                <InputItem
                    type="date"
                    name="retiredDate"
                    label="ORDERS_RETIRED_DATE"
                    value={data.retiredDate}
                    maxValue="2999-12-31"
                />
                <InputItem
                    type="time"
                    name="retiredTime"
                    label="ORDERS_RETIRED_TIME"
                    value={data.retiredTime}
                />
            </InputField>
        </Columns>

        <OrderProducts
            isHidden={tab !== "products"}
            tooltips={tooltips}
            saleTypes={saleTypes}
            unitPrices={unitPrices}
            pamiPrices={pamiPrices}
            prices={prices}
            subTotals={subTotals}
            data={data}
            errors={errors}
            onChange={handleChange}
            canEditProducts
            canEditPrices
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
OrderRetired.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elem     : PropTypes.object,
    products : PropTypes.array,
};

export default OrderRetired;
