import React                from "react";
import PropTypes            from "prop-types";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";

// Dashboard
import Button               from "Dashboard/Components/Form/Button";

// Audios
// @ts-ignore
import Alert                from "Styles/Audio/alert.mp3";
// @ts-ignore
import Wait                 from "Styles/Audio/wait.mp3";
// @ts-ignore
import Message              from "Styles/Audio/message.mp3";

// Styled
import Styled, {
    keyframes,
} from "styled-components";



// Animations
const animation = keyframes`
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
`;

// Styles
const Container = Styled.div`
    position: absolute;
    inset: 0;
    overflow: hidden;

    &::after {
        content: "";
        position: absolute;
        inset: 0;
        background-color: rgba(255, 0, 51, 0.2);
        box-shadow: inset 0 0 100px 50px rgb(255, 0, 51);
        animation-name: ${animation};
        animation-duration: 3s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
    }
`;

const Content = Styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 32px 64px;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: calc(var(--border-radius) * 2);
    z-index: 1;
`;

const Title = Styled.h2`
    margin: 0;
    color: white;
    text-align: center;
`;



/**
 * The Process Alert
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ProcessAlert(props) {
    const { onClick } = props;

    const { isAgent } = Store.useState("auth");
    const { list } = Store.useState("dashboardProcess");


    // The References
    const alertRef   = React.useRef(null);
    const waitRef    = React.useRef(null);
    const messageRef = React.useRef(null);
    const timerRef   = React.useRef(0);
    const moveRef    = React.useRef(0);
    const ordersRef  = React.useRef([]);

    // The Current State
    const [ showAlert,  setShowAlert  ] = React.useState(false);
    const [ lastUnread, setLastUnread ] = React.useState(0);


    // Initial Setup
    React.useEffect(() => {
        alertRef.current   = new Audio(Alert);
        waitRef.current    = new Audio(Wait);
        messageRef.current = new Audio(Message);

        alertRef.current.loop = true;
        waitRef.current.loop  = true;

        moveRef.current = Utils.getCurrentTime();
        window.addEventListener("mousemove", updateMouse);

        return () => {
            window.removeEventListener("mousemove", updateMouse);
            stopAlert();
        };
    }, []);

    // Update the Mouse
    const updateMouse = () => {
        moveRef.current = Utils.getCurrentTime();
    };


    // Calculate the Orders to Alert
    const [ orderID, count, unread ] = React.useMemo(() => {
        let orderID = 0;
        let count   = 0;
        let unread  = 0;
        for (const elem of list) {
            if (elem.unreadMessages > 0) {
                orderID = elem.orderID;
                if (isAgent) {
                    unread += elem.unreadMessages;
                } else {
                    count += 1;
                }
                continue;
            }

            if (ordersRef.current.includes(elem.orderID)) {
                continue;
            }
            if (elem.isColumnPending && !elem.credentialID) {
                ordersRef.current.push(elem.orderID);
                orderID = elem.orderID;
                count  += 1;
            }
        }
        return [ orderID, count, unread ];
    }, [ isAgent, JSON.stringify(list) ]);


    // Handle the Order to Alert update
    React.useEffect(() => {
        if (count > 0 && !showAlert) {
            startAlert();
        } else if (!count && showAlert) {
            stopAlert();
        }
    }, [ count ]);

    // Handle the Message Sound
    React.useEffect(() => {
        if (isAgent) {
            const diff = Utils.getCurrentTime() - moveRef.current;
            if (unread > lastUnread && diff > 60) {
                Commons.playAudio(messageRef);
            }
            setLastUnread(unread);
        }
    }, [ isAgent, unread ]);


    // Handles the Click
    const handleClick = () => {
        stopAlert();
        onClick(orderID);
    };

    // Starts the Alert
    const startAlert = () => {
        setShowAlert(true);

        Commons.playAudio(alertRef);
        timerRef.current = window.setTimeout(() => {
            Commons.stopAudio(alertRef);

            // Wait 5 minutes
            timerRef.current = window.setTimeout(() => {
                Commons.playAudio(waitRef);
            }, 5 * 60 * 1000);
        }, 10 * 1000);
    };

    // Stops the Alert
    const stopAlert = () => {
        setShowAlert(false);
        Utils.clearTimeout(timerRef);
        Commons.stopAudio(alertRef);
        Commons.stopAudio(waitRef);
    };


    // Do the Render
    if (!showAlert) {
        return <React.Fragment />;
    }
    return <Container>
        <Content>
            <Title>{NLS.pluralize("DASHBOARD_PROCESS_ALERT", count)}</Title>
            <Button
                variant="success"
                message="ORDERS_VIEW_TITLE"
                onClick={handleClick}
            />
        </Content>
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ProcessAlert.propTypes = {
    onClick : PropTypes.func.isRequired,
};

export default ProcessAlert;
