import React                from "react";
import { Bar }              from "react-chartjs-2";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import NLS                  from "Dashboard/Core/NLS";
import DateTime             from "Dashboard/Utils/DateTime";
import Charts               from "Utils/Charts";



// Styles
const Container = Styled.div`
    flex-grow: 2;
    box-sizing: border-box;
    height: var(--dashboard-report);
    padding: var(--main-gap);
    border: 1px solid var(--border-color-light);
    border-radius: var(--border-radius);
`;



/**
 * The Daily Chart Bar
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function DailyChartBar(props) {
    const { currentDate } = props;

    const {
        dayLabels, holidays, events,
        thisBilling, thisNet, lastBilling, lastNet,
    } = Store.useState("dashboardDaily");


    // Variables
    const date      = DateTime.create(currentDate.time);
    const thisMonth = date.getMonthName();
    const lastMonth = date.toMonthStart().moveMonth(-1).getMonthName();


    // Do the Render
    return <Container>
        <Bar
            options={{
                maintainAspectRatio : false,
                interaction : {
                    mode      : "index",
                    intersect : false,
                },
                animation : {
                    duration : 0,
                },
                scales : {
                    x : Charts.getDateScale(holidays, events, true),
                    y : Charts.getSimpleScale(true, false, false),
                },
                plugins : {
                    legend  : Charts.getLegendOptions("top"),
                    tooltip : Charts.getDateTooltip(holidays, events, true),
                },
            }}
            data={{
                labels   : dayLabels,
                datasets : [
                    {
                        // @ts-ignore
                        prefix           : "$ ",
                        order            : 0,
                        yAxisID          : "y",
                        label            : NLS.format("DASHBOARD_DAILY_GROSS_BILLING_CHART", lastMonth),
                        data             : lastBilling,
                        borderRadius     : 6,
                        borderColor      : "rgba(59, 199, 181, 0.2)",
                        backgroundColor  : "rgba(59, 199, 181, 0.2)",
                    },
                    {
                        // @ts-ignore
                        prefix           : "$ ",
                        order            : 1,
                        yAxisID          : "y",
                        label            : NLS.format("DASHBOARD_DAILY_NET_BILLING_CHART", lastMonth),
                        data             : lastNet,
                        borderRadius     : 6,
                        borderColor      : "rgba(2, 131, 200, 0.2)",
                        backgroundColor  : "rgba(2, 131, 200, 0.2)",
                    },

                    {
                        // @ts-ignore
                        prefix           : "$ ",
                        order            : 2,
                        yAxisID          : "y",
                        label            : NLS.format("DASHBOARD_DAILY_GROSS_BILLING_CHART", thisMonth),
                        data             : thisBilling,
                        borderRadius     : 6,
                        borderColor      : "rgb(59,  199, 181)",
                        backgroundColor  : "rgb(59,  199, 181)",
                    },
                    {
                        // @ts-ignore
                        prefix           : "$ ",
                        order            : 3,
                        yAxisID          : "y",
                        label            : NLS.format("DASHBOARD_DAILY_NET_BILLING_CHART", thisMonth),
                        data             : thisNet,
                        borderRadius     : 6,
                        borderColor      : "rgb(2, 131, 200)",
                        backgroundColor  : "rgb(2, 131, 200)",
                    },
                ],
            }}
        />
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
DailyChartBar.propTypes = {
    currentDate : PropTypes.object.isRequired,
};

export default DailyChartBar;
