import React                from "react";

// Components
import AdminList            from "Components/App/Setup/Admin/AdminList";
import ObjectiveList        from "Components/App/Setup/Objective/ObjectiveList";
import HolidayList          from "Components/App/Setup/Holiday/HolidayList";
import EventList            from "Components/App/Setup/Event/EventList";
import BannerList           from "Components/App/Setup/Banner/BannerList";
import StateList            from "Components/App/Setup/State/StateList";
import StateGroupList       from "Components/App/Setup/StateGroup/StateGroupList";
import RejectReasonList     from "Components/App/Setup/RejectReason/RejectReasonList";
import CancelReasonList     from "Components/App/Setup/CancelReason/CancelReasonList";
import ClosedReasonList     from "Components/App/Setup/ClosedReason/ClosedReasonList";
import QuickReplyList       from "Components/App/Setup/QuickReply/QuickReplyList";
import TooltipList          from "Components/App/Setup/Tooltip/TooltipList";
import PromptList           from "Components/App/Setup/Prompt/PromptList";
import CoverageTypeList     from "Components/App/Coverage/CoverageType/CoverageTypeList";
import CoverageList         from "Components/App/Coverage/Coverage/CoverageList";
import RetirementTypeList   from "Components/App/Logistic/RetirementType/RetirementTypeList";
import LogisticList         from "Components/App/Logistic/Logistic/LogisticList";
import LogisticReasonList   from "Components/App/Logistic/LogisticReason/LogisticReasonList";
import TransactionTypeList  from "Components/App/Transaction/TransactionType/TransactionTypeList";
import GatewayList          from "Components/App/Transaction/Gateway/GatewayList";
import PaymentTypeList      from "Components/App/Transaction/PaymentType/PaymentTypeList";
import PaymentMethodList    from "Components/App/Transaction/PaymentMethod/PaymentMethodList";
import CardTypeList         from "Components/App/Transaction/CardType/CardTypeList";
import BankList             from "Components/App/Transaction/Bank/BankList";
import CommissionList       from "Components/App/Transaction/Commission/CommissionList";
import MediaPage            from "Components/App/Setup/Media/MediaPage";
import EmailTemplateList    from "Components/App/Email/EmailTemplate/EmailTemplateList";
import EmailQueueList       from "Components/App/Email/EmailQueue/EmailQueueList";
import EmailWhiteList       from "Components/App/Email/EmailWhiteList/EmailWhiteList";
import NotificationLogList  from "Components/App/Log/NotificationLog/NotificationLogList";
import ProviderLogList      from "Components/App/Log/ProviderLog/ProviderLogList";
import ActionLogList        from "Components/App/Log/ActionLog/ActionLogList";
import ErrorLogList         from "Components/App/Log/ErrorLog/ErrorLogList";

// Dashboard
import Router               from "Dashboard/Components/Route/Router";
import AdminRoute           from "Dashboard/Components/Route/AdminRoute";
import SecondaryNav         from "Dashboard/Components/Navigation/SecondaryNav";
import NavigationList       from "Dashboard/Components/Navigation/NavigationList";
import NavigationItem       from "Dashboard/Components/Navigation/NavigationItem";



/**
 * The Setup Container
 * @returns {React.ReactElement}
 */
function SetupContainer() {
    return <>
        <SecondaryNav message="GENERAL_SETTINGS" icon="settings">
            <NavigationList>
                <NavigationItem
                    message="ADMINS_NAME"
                    url="ADMINS"
                    icon="admin"
                />
                <NavigationItem
                    message="OBJECTIVES_NAME"
                    url="OBJECTIVES"
                    icon="objective"
                />
                <NavigationItem
                    message="HOLIDAYS_NAME"
                    url="HOLIDAYS"
                    icon="holiday"
                />
                <NavigationItem
                    message="EVENTS_NAME"
                    url="EVENTS"
                    icon="event"
                />
                <NavigationItem
                    message="BANNERS_NAME"
                    url="BANNERS"
                    icon="banner"
                />
            </NavigationList>

            <NavigationList>
                <NavigationItem
                    message="STATES_NAME"
                    url="STATES"
                    icon="state"
                />
                <NavigationItem
                    message="STATE_GROUPS_NAME"
                    url="STATE_GROUPS"
                    icon="state-group"
                />
                <NavigationItem
                    message="REJECT_REASONS_NAME"
                    url="REJECT_REASONS"
                    icon="reject-reason"
                />
                <NavigationItem
                    message="CANCEL_REASONS_NAME"
                    url="CANCEL_REASONS"
                    icon="cancel-reason"
                />
                <NavigationItem
                    message="CLOSED_REASONS_NAME"
                    url="CLOSED_REASONS"
                    icon="closed-reason"
                />
                <NavigationItem
                    message="QUICK_REPLIES_NAME"
                    url="QUICK_REPLIES"
                    icon="quick-reply"
                />
                <NavigationItem
                    message="TOOLTIPS_NAME"
                    url="TOOLTIPS"
                    icon="tooltip"
                />
                <NavigationItem
                    message="PROMPTS_NAME"
                    url="PROMPTS"
                    icon="prompt"
                />
            </NavigationList>

            <NavigationList>
                <NavigationItem
                    message="COVERAGE_TYPES_NAME"
                    url="COVERAGE_TYPES"
                    icon="coverage-type"
                />
                <NavigationItem
                    message="COVERAGES_NAME"
                    url="COVERAGES"
                    icon="coverage"
                />
            </NavigationList>

            <NavigationList>
                <NavigationItem
                    message="RETIREMENT_TYPES_NAME"
                    url="RETIREMENT_TYPES"
                    icon="retirement-type"
                />
                <NavigationItem
                    message="LOGISTICS_NAME"
                    url="LOGISTICS"
                    icon="logistic"
                />
                <NavigationItem
                    message="LOGISTIC_REASONS_NAME"
                    url="LOGISTICS_REASONS"
                    icon="logistic-reason"
                />
            </NavigationList>

            <NavigationList>
                <NavigationItem
                    message="TRANSACTION_TYPES_NAME"
                    url="TRANSACTION_TYPES"
                    icon="transaction-type"
                />
                <NavigationItem
                    message="GATEWAYS_NAME"
                    url="GATEWAYS"
                    icon="gateway"
                />
                <NavigationItem
                    message="PAYMENT_TYPES_NAME"
                    url="PAYMENT_TYPES"
                    icon="payment-type"
                />
                <NavigationItem
                    message="PAYMENT_METHODS_NAME"
                    url="PAYMENT_METHODS"
                    icon="payment-method"
                />
                <NavigationItem
                    message="CARD_TYPES_NAME"
                    url="CARD_TYPES"
                    icon="card-type"
                />
                <NavigationItem
                    message="BANKS_NAME"
                    url="BANKS"
                    icon="bank"
                />
                <NavigationItem
                    message="COMMISSIONS_NAME"
                    url="COMMISSIONS"
                    icon="commission"
                />
            </NavigationList>

            <NavigationList>
                <NavigationItem
                    message="MEDIA_NAME"
                    url="MEDIA"
                    icon="media"
                />
                <NavigationItem
                    message="EMAIL_TEMPLATES_NAME"
                    url="EMAIL_TEMPLATES"
                    icon="email"
                />
                <NavigationItem
                    message="EMAIL_QUEUE_NAME"
                    url="EMAIL_QUEUE"
                    icon="email-queue"
                />
                <NavigationItem
                    message="EMAIL_WHITE_LIST_NAME"
                    url="EMAIL_WHITE_LIST"
                    icon="white-email"
                />
            </NavigationList>

            <NavigationList>
                <NavigationItem
                    message="NOTIFICATIONS_NAME"
                    url="LOG_NOTIFICATIONS"
                    icon="notification"
                />
                <NavigationItem
                    message="PROVIDERS_LOG_NAME"
                    url="LOG_PROVIDERS"
                    icon="provider-log"
                />
                <NavigationItem
                    message="ACTIONS_LOG_NAME"
                    url="LOG_ACTIONS"
                    icon="action-log"
                />
                <NavigationItem
                    message="ERRORS_LOG_NAME"
                    url="LOG_ERRORS"
                    icon="error-log"
                />
            </NavigationList>
        </SecondaryNav>

        <Router type="ADMIN">
            <AdminRoute url="ADMINS"            component={AdminList}           />
            <AdminRoute url="OBJECTIVES"        component={ObjectiveList}       />
            <AdminRoute url="HOLIDAYS"          component={HolidayList}         />
            <AdminRoute url="EVENTS"            component={EventList}           />
            <AdminRoute url="BANNERS"           component={BannerList}          />
            <AdminRoute url="STATES"            component={StateList}           />
            <AdminRoute url="STATE_GROUPS"      component={StateGroupList}      />
            <AdminRoute url="REJECT_REASONS"    component={RejectReasonList}    />
            <AdminRoute url="CANCEL_REASONS"    component={CancelReasonList}    />
            <AdminRoute url="CLOSED_REASONS"    component={ClosedReasonList}    />
            <AdminRoute url="QUICK_REPLIES"     component={QuickReplyList}      />
            <AdminRoute url="TOOLTIPS"          component={TooltipList}         />
            <AdminRoute url="PROMPTS"           component={PromptList}          />
            <AdminRoute url="COVERAGE_TYPES"    component={CoverageTypeList}    />
            <AdminRoute url="COVERAGES"         component={CoverageList}        />
            <AdminRoute url="RETIREMENT_TYPES"  component={RetirementTypeList}  />
            <AdminRoute url="LOGISTICS"         component={LogisticList}        />
            <AdminRoute url="LOGISTICS_REASONS" component={LogisticReasonList}  />
            <AdminRoute url="TRANSACTION_TYPES" component={TransactionTypeList} />
            <AdminRoute url="GATEWAYS"          component={GatewayList}         />
            <AdminRoute url="PAYMENT_TYPES"     component={PaymentTypeList}     />
            <AdminRoute url="PAYMENT_METHODS"   component={PaymentMethodList}   />
            <AdminRoute url="CARD_TYPES"        component={CardTypeList}        />
            <AdminRoute url="BANKS"             component={BankList}            />
            <AdminRoute url="COMMISSIONS"       component={CommissionList}      />
            <AdminRoute url="MEDIA"             component={MediaPage}           />
            <AdminRoute url="EMAIL_TEMPLATES"   component={EmailTemplateList}   />
            <AdminRoute url="EMAIL_QUEUE"       component={EmailQueueList}      />
            <AdminRoute url="EMAIL_WHITE_LIST"  component={EmailWhiteList}      />
            <AdminRoute url="LOG_NOTIFICATIONS" component={NotificationLogList} />
            <AdminRoute url="LOG_PROVIDERS"     component={ProviderLogList}     />
            <AdminRoute url="LOG_PROVIDER"      component={ProviderLogList}     />
            <AdminRoute url="LOG_ACTIONS"       component={ActionLogList}       />
            <AdminRoute url="LOG_ERRORS"        component={ErrorLogList}        />
            <AdminRoute url="LOG_ERROR"         component={ErrorLogList}        />
        </Router>
    </>;
}

export default SetupContainer;
