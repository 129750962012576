import React                from "react";
import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";

// Components
import ViewTable            from "Components/Utils/View/ViewTable";

// Dashboard
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import Html                 from "Dashboard/Components/Common/Html";



/**
 * The Order Products
 * @returns {React.ReactElement}
 */
function OrderProducts() {
    const { isAnyAdmin } = Store.useState("auth");
    const { products } = Store.useState("order");

    const navigate = Navigate.useGoto();


    // Handles the Action
    const handleAction = (action, elemID) => {
        const productID = Utils.getValue(products, "orderProductID", elemID, "productID");
        navigate("CATALOG", "PRODUCTS", productID);
    };


    // Do the Render
    return <ViewTable message="PRODUCTS_NAME">
        <Table none="PRODUCTS_NONE_AVAILABLE" noSorting notFixed noClick>
            <TableHead>
                <TableHeader field="name"          message="PRODUCTS_SINGULAR"      isFlex isMultiline grow="2" />
                <TableHeader field="productType"   message="GENERAL_TYPE"           isFlex isMultiline />
                <TableHeader field="saleType"      message="SALE_TYPES_SINGULAR"    isFlex isMultiline />
                <TableHeader field="origin"        message="ORDERS_DISCOUNT_ORIGIN" isFlex maxWidth="120" />
                <TableHeader field="voucherTime"   message="ORDERS_VOUCHER_DATE"    isFlex maxWidth="120" />
                <TableHeader field="quantity"      message="GENERAL_QUANTITY"       isFlex align="center" maxWidth="80"  />
                <TableHeader field="price"         message="GENERAL_PRICE"          isFlex align="right"  maxWidth="80"  />
                <TableHeader field="subTotal"      message="GENERAL_SUBTOTAL"       isFlex align="right"  maxWidth="100" />
                <TableHeader field="discountTotal" message="ORDERS_DISCOUNT"        isFlex align="right"  maxWidth="100" />
                <TableHeader field="total"         message="GENERAL_TOTAL"          isFlex align="right"  maxWidth="100" />
                <TableHeader field="benvidaFee"    message="ORDERS_COMMISSION"      isFlex align="right"  maxWidth="140" />
            </TableHead>
            <TableBody>
                {products.map((elem) => <TableRow key={elem.orderProductID} elemID={elem.orderProductID}>
                    <TableCell message={elem.name}            />
                    <TableCell message={elem.productTypeName} />
                    <TableCell message={elem.saleTypeName}    />
                    <TableCell message={elem.originText}      />
                    <TableCell message={elem.voucherTimeDate} />
                    <TableCell message={elem.quantity}        />
                    <TableCell><Html>{elem.priceText}</Html></TableCell>
                    <TableCell><Html>{elem.subTotalText}</Html></TableCell>
                    <TableCell><Html>{elem.discountTotalText}</Html></TableCell>
                    <TableCell><Html>{elem.totalText}</Html></TableCell>
                    <TableCell><Html>{elem.benvidaFeeText}</Html></TableCell>
                </TableRow>)}
            </TableBody>
            <TableActionList onAction={handleAction}>
                <TableAction action="VIEW" message="PRODUCTS_VIEW_TITLE" isHidden={!isAnyAdmin} />
            </TableActionList>
        </Table>
    </ViewTable>;
}

export default OrderProducts;
