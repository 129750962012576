import React                from "react";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";
import Action               from "Dashboard/Core/Action";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";

// Components
import DocumentHeader       from "./View/DocumentHeader";
import DocumentAside        from "./View/DocumentAside";
import DocumentEmpty        from "./View/DocumentEmpty";
import DocumentView         from "./View/DocumentView";
import DocumentEdit         from "./Edit/DocumentEdit";
import DocumentCreate       from "./Edit/DocumentCreate";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import ConfirmDialog        from "Dashboard/Components/Dialogs/ConfirmDialog";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";



// Styles
const Container = Styled.main`
    display: grid;
    grid-template-columns: var(--document-list-width) 2fr;
    gap: var(--document-gap);
    height: 100%;

    @media (max-width: 700px) {
        grid-template-columns: 1fr;
    }
`;

const Document = Styled.main`
    border: var(--border-width) solid var(--border-color-light);
    border-radius: var(--border-radius);
`;



/**
 * The Document Page
 * @returns {React.ReactElement}
 */
function DocumentPage() {
    const { fetch, load } = useList("document", "", false);

    const navigate = Navigate.useGoto();
    const { documentSlug } = Navigate.useParams();

    const data = Store.useState("document");
    const { loading, list, roots, elem } = data;

    const { fetchDocument, deleteDocument } = Store.useAction("document");


    // The Current State
    const [ action,      updateAction   ] = Action.useAction();
    const [ hasChanges,  setHasChanges  ] = React.useState(false);
    const [ openDelete,  setOpenDelete  ] = React.useState(false);
    const [ openWarning, setOpenWarning ] = React.useState(false);
    const [ url,         setUrl         ] = React.useState([]);


    // Load the Documents
    React.useEffect(() => {
        load();
    }, []);

    // Show the Document
    React.useEffect(() => {
        if (documentSlug) {
            fetchDocument(documentSlug);
            updateAction(action.isCreate ? "EDIT" : "VIEW");
        } else {
            updateAction("NONE");
        }
    }, [ documentSlug ]);


    // Handles the Document Click
    const handleDocument = (elemID) => {
        const document = Utils.getValue(list, "documentID", elemID);
        if (document.slug !== documentSlug) {
            const url = [ "DOCUMENTS", document.slug ];
            if (hasChanges) {
                setOpenWarning(true);
                setUrl(url);
            } else {
                navigate(...url);
            }
        }
    };

    // Handles the Create Submit
    const handleCreate = (response) => {
        navigate("DOCUMENTS", response.slug);
        fetch();
    };

    // Handles the Edit Submit
    const handleEdit = (response) => {
        updateAction("VIEW");
        if (documentSlug !== response.slug) {
            navigate("DOCUMENTS", response.slug);
        } else {
            fetchDocument(response.slug);
        }
        fetch();
    };

    // Handles the Edit Close
    const handleClose = () => {
        setHasChanges(false);
        if (elem.documentID) {
            updateAction("VIEW");
        } else {
            updateAction();
        }
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        updateAction();
        setOpenDelete(false);
        const result = await deleteDocument(elem.documentID);
        if (result.success) {
            navigate("DOCUMENTS");
            fetch();
        }
    };

    // Handles the Discard
    const handleDiscard = () => {
        setHasChanges(false);
        setOpenWarning(false);
        navigate(...url);
        setUrl([]);
    };


    // Variables
    const isMobile     = Utils.isMobile();
    const showAside    = Boolean(!isMobile || (isMobile && !action.isVCE));
    const showDocument = Boolean(!isMobile || (isMobile && action.isVCE));
    const showEmpty    = Boolean(action.name === "NONE");
    const showView     = Boolean(action.isVC);
    const showEdit     = Boolean(action.isEdit);
    const elemName     = Utils.getValue(list, "documentID", elem.documentID, "name");



    // Do the Render
    return <Main>
        <DocumentHeader
            action={action}
            updateAction={updateAction}
            onDelete={() => setOpenDelete(true)}
        />
        <Content loading={loading}>
            <Container>
                {showAside && <DocumentAside
                    roots={roots}
                    onClick={handleDocument}
                />}
                {showDocument && <Document>
                    {showEmpty && <DocumentEmpty />}
                    {showView && <DocumentView />}
                    {showEdit && <DocumentEdit
                        elemID={action.isCreate ? 0 : elem.documentID}
                        setHasChanges={setHasChanges}
                        onSubmit={handleEdit}
                        onDelete={() => setOpenDelete(true)}
                        onClose={handleClose}
                    />}
                </Document>}
            </Container>
        </Content>

        <DocumentCreate
            open={action.isCreate}
            onSubmit={handleCreate}
            onClose={handleClose}
        />
        <ConfirmDialog
            open={openWarning}
            icon="status"
            title="DOCUMENTS_WARNING_TITLE"
            message="DOCUMENTS_WARNING_TEXT"
            primary="GENERAL_DISCARD"
            onSubmit={handleDiscard}
            onClose={() => setOpenWarning(false)}
        />
        <DeleteDialog
            open={openDelete}
            title="DOCUMENTS_DELETE_TITLE"
            message="DOCUMENTS_DELETE_TEXT"
            content={elemName}
            onSubmit={handleDelete}
            onClose={() => setOpenDelete(false)}
        />
    </Main>;
}

export default DocumentPage;
