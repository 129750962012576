const actions = {

    // Auth
    Session : {
        name    : "Sesión",
        actions : {
            Login      : "Inicio de sesión",
            LoginAs    : "Inicio como usuario",
            Logout     : "Cierre de sesión",
            LogoutAs   : "Cierre como usuario",
            Recover    : "Recupero de contraseña",
            ResetPass  : "Contraseña modificada",
            ChangePass : "Contraseña cambiada",
            Disconnect : "Desconectar dispositivo",
        },
    },
    Profile : {
        name    : "Perfil",
        actions : {
            Edit   : "Cuenta editada",
            Upload : "Avatar subido",
            Delete : "Cuenta eliminada",
        },
    },



    // Business
    Pharmacy : {
        name    : "Farmacia",
        actions : {
            Create : "Farmacia creada",
            Edit   : "Farmacia editada",
            Delete : "Farmacia eliminada",
        },
    },
    User : {
        name    : "Usuario",
        actions : {
            Create : "Usuario creado",
            Edit   : "Usuario editado",
            Delete : "Usuario eliminado",
        },
    },
    Contact : {
        name    : "Contacto",
        actions : {
            Create : "Contacto creado",
            Edit   : "Contacto editado",
            Delete : "Contacto eliminado",
        },
    },



    // Subsidiary
    Subsidiary : {
        name    : "Sucursal",
        actions : {
            Create : "Sucursal creada",
            Edit   : "Sucursal editada",
            Delete : "Sucursal eliminada",
        },
    },
    SubsidiarySchedule : {
        name    : "Horario de sucursal",
        actions : {
            Create : "Horario de sucursal creado",
            Edit   : "Horario de sucursal editado",
            Delete : "Horario de sucursal eliminado",
        },
    },
    SubsidiaryCoverage : {
        name    : "Cobertura de sucursal",
        actions : {
            Create : "Cobertura de sucursal creada",
            Edit   : "Cobertura de sucursal editada",
            Delete : "Cobertura de sucursal eliminada",
        },
    },
    SubsidiaryGateway : {
        name    : "Pasarela de pago",
        actions : {
            Edit       : "Pasarela de pago editada",
            Link       : "Pasarela de pago vinculada",
            Activate   : "Pasarela de pago activada",
            Deactivate : "Pasarela de pago desactivada",
            EditAll    : "Pasarelas de pago editadas",
        },
    },
    SubsidiaryLogistic : {
        name    : "Logística",
        actions : {
            EditAll : "Logísticas editadas",
        },
    },



    // Orders
    Order : {
        name    : "Órdenes",
        actions : {
            Export       : "Órdenes exportadas",
            Chat         : "Chat iniciado",
            Edit         : "Orden editada",
            EditData     : "Datos de orden editados",
            EditRecipe   : "Receta de orden editada",
            EditCoverage : "Cobertura de orden editada",
            Validate     : "Orden validada",
            Activate     : "Orden activada",
            Approve      : "Orden aprobada",
            Reject       : "Orden rechazada",
            Retire       : "Orden marcada lista para retiro",
            Retired      : "Orden marcada como retirada",
            Delivered    : "Orden marcada como entregada",
            Cancel       : "Orden cancelada",
            Nullify      : "Orden anulada",
        },
    },
    Client : {
        name    : "Clientes",
        actions : {
            Create : "Cliente creado",
            Edit   : "Cliente editado",
            Sync   : "Cliente sincronizado",
            Delete : "Cliente eliminado",
        },
    },



    // Store
    StoreSpace : {
        name    : "Espacios de catálogo",
        actions : {
            Create : "Espacio creado",
            Edit   : "Espacio editado",
            Delete : "Espacio eliminado",
            Export : "Productos del espacio exportados",
            Import : "Productos del espacio importados",
        },
    },
    StoreBanner : {
        name    : "Banners de catálogo",
        actions : {
            Create : "Banner creado",
            Edit   : "Banner editado",
            Delete : "Banner eliminado",
        },
    },



    // Catalog
    Product : {
        name    : "Productos",
        actions : {
            Export       : "Productos exportados",
            Create       : "Producto creado",
            Edit         : "Producto editado",
            EditMulti    : "Productos editados",
            ImportImages : "Imágenes de productos importadas",
            Delete       : "Producto eliminado",
        },
    },
    MacroCategory : {
        name    : "Macro categorías",
        actions : {
            Create : "Macro categoría creada",
            Edit   : "Macro categoría editada",
            Delete : "Macro categoría eliminada",
        },
    },
    Category : {
        name    : "Categorías",
        actions : {
            Create : "Categoría creada",
            Edit   : "Categoría editada",
            Delete : "Categoría eliminada",
        },
    },
    SubCategory : {
        name    : "Sub categorías",
        actions : {
            Create : "Sub categoría creada",
            Edit   : "Sub categoría editada",
            Delete : "Sub categoría eliminada",
        },
    },



    // Medicines
    Medicine : {
        name    : "Medicamentos",
        actions : {
            Export : "Medicamentos exportados",
            Create : "Medicamento creado",
            Edit   : "Medicamento editado",
            Delete : "Medicamento eliminado",
        },
    },
    Laboratory : {
        name    : "Laboratorios",
        actions : {
            Create : "Laboratorio creado",
            Edit   : "Laboratorio editado",
            Delete : "Laboratorio eliminado",
        },
    },
    MonoDrug : {
        name    : "Mono drogas",
        actions : {
            Create : "Mono droga creada",
            Edit   : "Mono droga editada",
            Delete : "Mono droga eliminada",
        },
    },
    DrugType : {
        name    : "Tipos de droga",
        actions : {
            Create : "Tipo de droga creado",
            Edit   : "Tipo de droga editado",
            Delete : "Tipo de droga eliminado",
        },
    },
    SaleType : {
        name    : "Tipos de venta",
        actions : {
            Create : "Tipo de venta creado",
            Edit   : "Tipo de venta editado",
            Delete : "Tipo de venta eliminado",
        },
    },
    HealthType : {
        name    : "Tipos de salud",
        actions : {
            Create : "Tipo de salud creado",
            Edit   : "Tipo de salud editado",
            Delete : "Tipo de salud eliminado",
        },
    },
    SizeType : {
        name    : "Tipos de tamaño",
        actions : {
            Create : "Tipo de tamaño creado",
            Edit   : "Tipo de tamaño editado",
            Delete : "Tipo de tamaño eliminado",
        },
    },
    ShapeType : {
        name    : "Tipos de forma",
        actions : {
            Create : "Tipo de forma creado",
            Edit   : "Tipo de forma editado",
            Delete : "Tipo de forma eliminado",
        },
    },
    PotencyType : {
        name    : "Tipos de potencia",
        actions : {
            Create : "Tipo de potencia creado",
            Edit   : "Tipo de potencia editado",
            Delete : "Tipo de potencia eliminado",
        },
    },
    UnitType : {
        name    : "Tipos de unidad",
        actions : {
            Create : "Tipo de unidad creado",
            Edit   : "Tipo de unidad editado",
            Delete : "Tipo de unidad eliminado",
        },
    },
    AdministrationRoute : {
        name    : "Vías de administración",
        actions : {
            Create : "Vía de administración creada",
            Edit   : "Vía de administración editada",
            Delete : "Vía de administración eliminada",
        },
    },
    PharmaAction : {
        name    : "Acciones farmacoterapéuticas",
        actions : {
            Create : "Acción farmacoterapéutica creada",
            Edit   : "Acción farmacoterapéutica editada",
            Delete : "Acción farmacoterapéutica eliminada",
        },
    },



    // Change
    ChangeProduct : {
        name    : "Cambios de productos",
        actions : {
            Process  : "Producto procesado",
            Validate : "Producto validado",
        },
    },
    ChangeCatalog : {
        name    : "Cambios de catálogo",
        actions : {
            Process       : "Cambio procesado",
            Validate      : "Cambio validado",
            MultiValidate : "Múltiples cambios validados",
        },
    },



    // Coverage
    CoverageType : {
        name    : "Tipos de coberturas",
        actions : {
            Create : "Tipo de cobertura creado",
            Edit   : "Tipo de cobertura editado",
            Delete : "Tipo de cobertura eliminado",
        },
    },
    Coverage : {
        name    : "Obras sociales",
        actions : {
            Create : "Obra social creada",
            Edit   : "Obra social editada",
            Delete : "Obra social eliminada",
        },
    },



    // Logistic
    RetirementType : {
        name    : "Tipos de entregas",
        actions : {
            Edit : "Tipo de entrega editado",
        },
    },
    Logistic : {
        name    : "Logísticas",
        actions : {
            Edit : "Logística editada",
        },
    },
    LogisticReason : {
        name    : "Motivo de error logísticas",
        actions : {
            Edit : "Motivo de error editado",
        },
    },



    // Transaction
    Transaction : {
        name    : "Transacciones",
        actions : {
            Export : "Transacciones exportadas",
        },
    },
    TransactionType : {
        name    : "Tipos de transacciones",
        actions : {
            Create : "Tipo de transacción creado",
            Edit   : "Tipo de transacción editado",
            Delete : "Tipo de transacción eliminado",
        },
    },
    Gateway : {
        name    : "Pasarelas de pago",
        actions : {
            Edit : "Pasarela de pago editada",
        },
    },
    PaymentType : {
        name    : "Tipos de pagos",
        actions : {
            Create : "Tipo de pago creado",
            Edit   : "Tipo de pago editado",
            Delete : "Tipo de pago eliminado",
        },
    },
    PaymentMethod : {
        name    : "Métodos de pagos",
        actions : {
            Create : "Método de pago creado",
            Edit   : "Método de pago editado",
            Delete : "Método de pago eliminado",
        },
    },
    CardType : {
        name    : "Tipos de tarjetas",
        actions : {
            Create : "Tipo de tarjeta creado",
            Edit   : "Tipo de tarjeta editado",
            Delete : "Tipo de tarjeta eliminado",
        },
    },
    Bank : {
        name    : "Bancos",
        actions : {
            Create : "Banco creado",
            Edit   : "Banco editado",
            Delete : "Banco eliminado",
        },
    },
    Commission : {
        name    : "Comisiones",
        actions : {
            Create : "Comisión creado",
            Edit   : "Comisión editado",
            Delete : "Comisión eliminado",
        },
    },



    // Setup
    Admin : {
        name    : "Administradores",
        actions : {
            Create : "Administrador creado",
            Edit   : "Administrador editado",
            Delete : "Administrador eliminado",
        },
    },
    Objective : {
        name    : "Objetivos",
        actions : {
            Create : "Objetivo creado",
            Edit   : "Objetivo editado",
            Delete : "Objetivo eliminado",
        },
    },
    Holiday : {
        name    : "Feriados",
        actions : {
            Create : "Feriado creado",
            Edit   : "Feriado editado",
            Delete : "Feriado eliminado",
        },
    },
    Event : {
        name    : "Eventos",
        actions : {
            Create : "Evento creado",
            Edit   : "Evento editado",
            Delete : "Evento eliminado",
        },
    },
    State : {
        name    : "Estados",
        actions : {
            Edit : "Estado editado",
        },
    },
    StateGroup : {
        name    : "Grupo de estados",
        actions : {
            Create : "Grupo de estado creado",
            Edit   : "Grupo de estado editado",
            Delete : "Grupo de estado eliminado",
        },
    },
    RejectReason : {
        name    : "Motivos de rechazo",
        actions : {
            Create : "Motivo de rechazo creado",
            Edit   : "Motivo de rechazo editado",
            Delete : "Motivo de rechazo eliminado",
        },
    },
    CancelReason : {
        name    : "Motivos de cancelado",
        actions : {
            Create : "Motivo de cancelado creado",
            Edit   : "Motivo de cancelado editado",
            Delete : "Motivo de cancelado eliminado",
        },
    },
    ClosedReason : {
        name    : "Motivos de cierre",
        actions : {
            Create : "Motivo de cierre creado",
            Edit   : "Motivo de cierre editado",
            Delete : "Motivo de cierre eliminado",
        },
    },
    Tooltip : {
        name    : "Tooltips",
        actions : {
            Edit : "Tooltip editado",
        },
    },
    Prompt : {
        name    : "Prompts",
        actions : {
            Edit : "Prompt editado",
        },
    },
    Media : {
        name    : "Archivo",
        actions : {
            Create : "Directorio creado",
            Upload : "Archivo subido",
            Rename : "Archivo renombrado",
            Move   : "Archivo movido",
            Delete : "Archivo eliminado",
        },
    },



    // Document
    Document : {
        name    : "Documento",
        actions : {
            Create : "Documento creado",
            Edit   : "Documento editado",
            Delete : "Documento eliminado",
        },
    },



    // Email
    EmailWhiteList : {
        name    : "Lista blanca de email",
        actions : {
            Add    : "Email agregado",
            Edit   : "Email editado",
            Remove : "Email quitado",
        },
    },
    EmailQueue : {
        name    : "Cola de email",
        actions : {
            Resend : "Email reenviado",
        },
    },



    // Log
    ErrorLog : {
        name    : "Log de error",
        actions : {
            Resolved : "Error resuelto",
            Delete   : "Error eliminado",
        },
    },
};

export default actions;
