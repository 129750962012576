import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";

// Dashboard
import IconLink             from "Dashboard/Components/Link/IconLink";
import Html                 from "Dashboard/Components/Common/Html";
import Icon                 from "Dashboard/Components/Common/Icon";



// Styles
const Container = Styled.section`
    box-sizing: border-box;
    height: var(--dashboard-process);
    border: 1px solid var(--border-color-light);
    border-radius: var(--border-radius);
    overflow: hidden;
`;

const Title = Styled.h3`
    position: relative;
    margin: 0;
    padding: calc(var(--main-gap) / 2) var(--main-gap);
    color: var(--alternative-color);
    font-family: var(--title-font);
    font-weight: var(--title-font-weight);
    font-size: 20px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: 1px solid var(--border-color-light);
`;

const Help = Styled(IconLink)`
    position: absolute;
    top: 50%;
    right: 2px;
    font-size: 20px;
    color: var(--primary-color);
    transform: translateY(-50%);
`;

const Content = Styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    height: calc(var(--dashboard-process) - 37px);
    overflow: auto;
`;

const Item = Styled.li.attrs(({ color }) => ({ color }))`
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-bottom: 1px solid var(--border-color-light);
    padding: var(--main-gap);
    transition: all 0.2s;
    cursor: pointer;

    ${(props) => props.color && `border-left: 10px solid ${props.color};`}

    &:hover {
        background-color: var(--lighter-gray);
    }
`;

const Part = Styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
`;

const Code = Styled.div`
    font-size: 16px;
`;

const State = Styled.div.attrs(({ color }) => ({ color }))`
    background-color: ${(props) => props.color};
    padding: 4px 8px;
    margin-top: -2px;
    color: white;
    font-size: 12px;
    text-align: center;
    border-radius: var(--border-radius);
    white-space: nowrap;
`;

const Date = Styled.div`
    display: flex;
    gap: 8px;
    text-align: right;
`;

const Unread = Styled.div`
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin-top: -6px;
    margin-bottom: -6px;
    background-color: var(--error-color);
    color: white;
    border-radius: 1000px;
`;

const Products = Styled.div`
    display: flex;
    align-items: flex-end;
    gap: 16px;

    span {
        font-style: italic;
    }
`;

const Pill = Styled.div`
    background-color: var(--light-gray);
    font-size: 12px;
    padding: 4px 8px;
    margin-top: -2px;
    border-radius: var(--border-radius);

    :empty {
        opacity: 0;
    }
`;

const Icons = Styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

    .icon {
        font-size: 22px;
    }
`;



/**
 * The Process Column
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ProcessColumn(props) {
    const { type, message, onClick, setTooltip } = props;

    const { list, tooltips } = Store.useState("dashboardProcess");


    // Variables
    const orders   = list.filter((elem) => elem[`isColumn${type}`]);
    const videoUrl = tooltips[`${type}Video`]?.videoUrl || "";
    const content  = tooltips[`${type}Video`]?.content  || "";


    // Do the Render
    return <Container>
        <Title>
            {NLS.get(message)}
            <Help
                isHidden={!videoUrl}
                icon="help"
                onClick={() => setTooltip(videoUrl, content, "video")}
            />
        </Title>

        <Content>
            {orders.map((elem) => <Item
                key={elem.id}
                color={elem.color}
                onClick={() => onClick(elem.id)}
            >
                <Part>
                    <Code>{elem.numberText}</Code>
                    <State color={elem.orderStateColor}>
                        {elem.orderStateName}
                    </State>
                    <Date>
                        {DateTime.formatLive(elem.orderStateCreatedTime)}
                        {elem.unreadMessages > 0 && <Unread>{elem.unreadMessages}</Unread>}
                    </Date>
                </Part>
                <Part>
                    <Products>
                        <b>{elem.productText}</b>
                        {!!elem.netTotal && <Html
                            variant="span"
                            content={Utils.formatPrice(elem.netTotal)}
                        />}
                    </Products>
                    <div>{elem.subsidiaryFullName}</div>
                </Part>
                <Part>
                    <Pill>{elem.coverageTypeName}</Pill>
                    <Icons>
                        <Icon
                            isHidden={!elem.recipeFile}
                            icon="sale-type"
                            tooltip="ORDERS_RECIPE"
                        />
                        <Icon
                            isHidden={!elem.gatewayIcon}
                            icon={elem.gatewayIcon}
                            tooltip={elem.gatewayName}
                        />
                        <Icon
                            icon={elem.retirementCode.toLowerCase()}
                            color={elem.retirementTypeColor}
                            tooltip={elem.retirementTypeName}
                        />
                    </Icons>
                </Part>
            </Item>)}
        </Content>
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ProcessColumn.propTypes = {
    type     : PropTypes.string.isRequired,
    message  : PropTypes.string.isRequired,
    onClick  : PropTypes.func.isRequired,
    setTooltip : PropTypes.func.isRequired,
};

export default ProcessColumn;
