import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Sale Type Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function SaleTypeEdit(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const { editElem } = Store.useAction("saleType");


    // The Initial Data
    const initialData = {
        saleTypeID       : 0,
        alfabetaID       : "",
        alfabetaName     : "",
        benvidaName      : "",
        reqRecipe        : "none",
        showToUser       : 0,
        showGridPrice    : 0,
        showProductPrice : 0,
        showCartPrice    : 0,
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("saleType", initialData, editElem, onSubmit);

    // Load the Data
    const { loading } = useDialog("saleType", open, elemID, null, setElem);



    // Do the Render
    return <EditDialog
        open={open}
        icon="sale-type"
        title={elemID ? "SALE_TYPES_EDIT_TITLE" : "SALE_TYPES_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <Columns>
            <InputField
                type="number"
                name="alfabetaID"
                label="GENERAL_CODE_ALFABETA"
                value={data.alfabetaID}
                error={errors.alfabetaID}
                onChange={handleChange}
                isDisabled={!!elemID}
            />
            <InputField
                name="alfabetaName"
                label="GENERAL_NAME_ALFABETA"
                value={data.alfabetaName}
                error={errors.alfabetaName}
                onChange={handleChange}
                isDisabled={!!elemID}
            />
            <InputField
                name="benvidaName"
                label="GENERAL_NAME_BENVIDA"
                value={data.benvidaName}
                error={errors.benvidaName}
                onChange={handleChange}
            />
            <InputField
                type="select"
                name="reqRecipe"
                label="GENERAL_REQ_RECIPE"
                options="SELECT_REQ_RECIPES"
                value={data.reqRecipe}
                error={errors.reqRecipe}
                onChange={handleChange}
                isRequired
            />
            <InputField
                type="toggle"
                name="showToUser"
                label="GENERAL_SHOW_TO_USER"
                value={data.showToUser}
                error={errors.showToUser}
                onChange={handleChange}
                withBorder
            />
            <InputField
                type="toggle"
                name="showGridPrice"
                label="SALE_TYPES_SHOW_GRID_PRICE"
                value={data.showGridPrice}
                error={errors.showGridPrice}
                onChange={handleChange}
                withBorder
            />
            <InputField
                type="toggle"
                name="showProductPrice"
                label="SALE_TYPES_SHOW_PRODUCT_PRICE"
                value={data.showProductPrice}
                error={errors.showProductPrice}
                onChange={handleChange}
                withBorder
            />
            <InputField
                type="toggle"
                name="showCartPrice"
                label="SALE_TYPES_SHOW_CART_PRICE"
                value={data.showCartPrice}
                error={errors.showCartPrice}
                onChange={handleChange}
                withBorder
            />
        </Columns>
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
SaleTypeEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default SaleTypeEdit;
