import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Closed Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ClosedEdit(props) {
    const { open, isCopy, elemID, pharmacyID, onClose, onSubmit } = props;

    const { subsidiaries, closedReasons, statuses } = Store.useState("subsidiaryClosed");
    const { editElem } = Store.useAction("subsidiaryClosed");


    // The Initial Data
    const initialData = {
        subsidiaryClosedID : 0,
        pharmacyID         : 0,
        closedReasonID     : 0,
        subsidiaryIDs      : "[]",
        reason             : "",
        fromDate           : "",
        toDate             : "",
        status             : "Active",
    };

    // Handles the Set
    const handleSet = (elem) => {
        setElem({
            ...elem,
            subsidiaryClosedID : !isCopy && elemID ? elemID : 0,
        });
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("subsidiaryClosed", initialData, editElem, onSubmit);

    // Load the Data
    const { loading } = useDialog("subsidiaryClosed", open, elemID, { pharmacyID }, handleSet);


    // Returns the Dialog Title
    const getTitle = React.useCallback(() => {
        if (isCopy) {
            return "CLOSED_DAYS_COPY_TITLE";
        }
        if (elemID) {
            return "CLOSED_DAYS_EDIT_TITLE";
        }
        return "CLOSED_DAYS_CREATE_TITLE";
    }, [ isCopy, elemID ]);



    // Do the Render
    return <EditDialog
        open={open}
        icon="closed-day"
        title={getTitle()}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        noAutoFocus
    >
        <InputField
            type="chooser"
            name="subsidiaryIDs"
            label="SUBSIDIARIES_NAME"
            options={subsidiaries}
            value={data.subsidiaryIDs}
            error={errors.subsidiaryIDs}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="select"
            name="closedReasonID"
            label="CLOSED_REASONS_SINGULAR"
            options={closedReasons}
            value={data.closedReasonID}
            error={errors.closedReasonID}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="textarea"
            name="reason"
            label="GENERAL_REASON"
            value={data.reason}
            error={errors.reason}
            onChange={handleChange}
            maxLength={250}
        />
        <Columns>
            <InputField
                type="date"
                name="fromDate"
                label="GENERAL_FROM"
                value={data.fromDate}
                error={errors.fromDate}
                maxValue="2999-12-31"
                onChange={handleChange}
                isRequired
            />
            <InputField
                type="date"
                name="toDate"
                label="GENERAL_TO"
                value={data.toDate}
                error={errors.toDate}
                maxValue="2999-12-31"
                onChange={handleChange}
                isRequired
            />
        </Columns>
        <InputField
            type="select"
            name="status"
            label="GENERAL_STATUS"
            options={statuses}
            value={data.status}
            error={errors.status}
            onChange={handleChange}
            hideClear
            isRequired
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ClosedEdit.propTypes = {
    open       : PropTypes.bool.isRequired,
    isCopy     : PropTypes.bool.isRequired,
    onClose    : PropTypes.func.isRequired,
    onSubmit   : PropTypes.func.isRequired,
    elemID     : PropTypes.number,
    pharmacyID : PropTypes.number,
};

export default ClosedEdit;
