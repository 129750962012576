import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";

// Dashboard
import DialogFooter         from "Dashboard/Components/Dialog/DialogFooter";



/**
 * The Process View Footer
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ProcessFooter(props) {
    const { isLoading, onSubmit, onCancel, onSecondary } = props;

    const { elem } = Store.useState("dashboardProcess");


    // Returns the primary button
    const primaryButton = React.useMemo(() => {
        if (elem.canValidate) {
            return "DASHBOARD_PROCESS_VALIDATE";
        }
        if (elem.canApprove) {
            return "DASHBOARD_PROCESS_APPROVE";
        }
        if (elem.canRetire) {
            return "DASHBOARD_PROCESS_MARK_RETIRE";
        }
        if (elem.canRetired) {
            return "DASHBOARD_PROCESS_MARK_RETIRED";
        }
        return "";
    }, [ elem.canValidate, elem.canApprove, elem.canRetire, elem.canRetired ]);

    // Returns the cancel button
    const cancelButton = React.useMemo(() => {
        if (elem.canReject) {
            return "DASHBOARD_PROCESS_REJECT";
        }
        if (elem.canCancel) {
            return "GENERAL_CANCEL";
        }
        if (elem.canNullify) {
            return "DASHBOARD_PROCESS_NULLIFY";
        }
        if (!primaryButton) {
            return "GENERAL_CLOSE";
        }
        return "";
    }, [ primaryButton, elem.canReject, elem.canCancel, elem.canNullify ]);

    // Returns the cancel variant
    const cancelVariant = React.useMemo(() => {
        if (elem.canReject || elem.canCancel || elem.canNullify) {
            return "error";
        }
        return "cancel";
    }, [ elem.canReject, elem.canCancel, elem.canNullify ]);

    // Returns the secondary button
    const secondaryButton = React.useMemo(() => {
        if (elem.canEditOrder || elem.canEditData) {
            return "GENERAL_SAVE";
        }
        return "";
    }, [ elem.canEditOrder, elem.canEditData ]);


    // Do the Render
    return <DialogFooter
        isDisabled={isLoading}
        primary={primaryButton}
        primaryVariant="success"
        onSubmit={onSubmit}
        cancel={cancelButton}
        cancelVariant={cancelVariant}
        onCancel={onCancel}
        dontClose={!cancelButton}
        secondary={secondaryButton}
        onSecondary={onSecondary}
    />;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ProcessFooter.propTypes = {
    isLoading   : PropTypes.bool.isRequired,
    onSubmit    : PropTypes.func.isRequired,
    onCancel    : PropTypes.func.isRequired,
    onSecondary : PropTypes.func.isRequired,
};

export default ProcessFooter;
