import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";

// Components
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableCell            from "Dashboard/Components/Table/TableCell";



/**
 * The Catalog Content
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function CatalogContent(props) {
    const { isHidden } = props;

    const { filters, list } = Store.useState("dashboardCatalog");
    const { fetchList } = Store.useAction("dashboardCatalog");


    // Auto Update
    Utils.useAutoUpdate(fetchList, isHidden, filters);


    // Do the Render
    if (isHidden) {
        return <React.Fragment />;
    }
    return <Table none="USERS_NONE_AVAILABLE" hasTabs noSorting>
        <TableHead>
            <TableHeader field="credentialName"    message="USERS_SINGULAR"                       isTitle />
            <TableHeader field="productsCreated"   message="DASHBOARD_CATALOG_PRODUCTS_CREATED"   align="center" />
            <TableHeader field="productsEdited"    message="DASHBOARD_CATALOG_PRODUCTS_EDITED"    align="center" />
            <TableHeader field="fieldsUpdated"     message="DASHBOARD_CATALOG_FIELDS_UPDATED"     align="center" />
            <TableHeader field="catalogsProcessed" message="DASHBOARD_CATALOG_CATALOGS_PROCESSED" align="center" />
        </TableHead>
        <TableBody>
            {list.map((elem) => <TableRow key={elem.credentialID} elemID={elem.credentialID}>
                <TableCell message={elem.credentialName}    />
                <TableCell message={elem.productsCreated}   />
                <TableCell message={elem.productsEdited}    />
                <TableCell message={elem.fieldsUpdated}     />
                <TableCell message={elem.catalogsProcessed} />
            </TableRow>)}
        </TableBody>
    </Table>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
CatalogContent.propTypes = {
    isHidden : PropTypes.bool,
};

export default CatalogContent;
