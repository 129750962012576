import React                from "react";
import OneSignal            from "react-onesignal";
import Store                from "Dashboard/Core/Store";
import NLS                  from "Dashboard/Core/NLS";

// Components
import SettingsTitle        from "../Settings/SettingsTitle";

// Dashboard
import Button               from "Dashboard/Components/Form/Button";



/**
 * The Notifications Button
 * @returns {React.ReactElement}
 */
function NotificationsButton() {
    const { notificationsSupported, notificationsGranted, notificationsDenied } = Store.useState("notification");


    // Handles the Click
    const handleClick = () => {
        OneSignal.Notifications.requestPermission();
    };


    // Do the Render
    const isDefault = !notificationsGranted && !notificationsDenied;

    if (!notificationsSupported) {
        return <React.Fragment />;
    }
    return <section>
        <SettingsTitle
            icon="notification"
            title="NOTIFICATIONS_TITLE"
        />

        {isDefault && <>
            <p>{NLS.get("NOTIFICATIONS_INFO")}</p>
            <Button
                variant="primary"
                message="NOTIFICATIONS_ACTIVATE"
                onClick={handleClick}
            />
        </>}
        {notificationsGranted && <p>{NLS.get("NOTIFICATIONS_GRANTED")}</p>}
        {notificationsDenied && <p>{NLS.get("NOTIFICATIONS_DENIED")}</p>}
    </section>;
}

export default NotificationsButton;
