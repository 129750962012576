import React                from "react";
import Store                from "Dashboard/Core/Store";

// Components
import DashboardTotals      from "../../Utils/Components/DashboardTotals";
import DashboardTotal       from "../../Utils/Components/DashboardTotal";



/**
 * The Operations Totals
 * @returns {React.ReactElement}
 */
function OperationsTotals() {
    const { totals } = Store.useState("dashboardOperations");


    // Do the Render
    return <DashboardTotals>
        <DashboardTotal
            message="DASHBOARD_OPERATIONS_PENDING_ORDERS"
            value={totals.pendingOrders}
        />
        <DashboardTotal
            message="DASHBOARD_OPERATIONS_TOTAL_ORDERS"
            value={totals.totalOrders}
        />
        <DashboardTotal
            message="DASHBOARD_OPERATIONS_EFFECTIVE_ORDERS"
            value={totals.effectiveOrders}
        />

        <DashboardTotal
            message="DASHBOARD_OPERATIONS_PENDING_UNITS"
            value={totals.pendingUnits}
        />
        <DashboardTotal
            message="DASHBOARD_OPERATIONS_TOTAL_UNITS"
            value={totals.totalUnits}
        />
        <DashboardTotal
            message="DASHBOARD_OPERATIONS_EFFECTIVE_UNITS"
            value={totals.effectiveUnits}
        />
    </DashboardTotals>;
}

export default OperationsTotals;
