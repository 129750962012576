import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";
import InputItem            from "Dashboard/Components/Form/InputItem";
import Columns              from "Dashboard/Components/Form/Columns";

// APIs
import {
    Laboratory, MonoDrug, UnitType, DrugType, PharmaAction,
} from "Utils/API";



/**
 * The Medicine Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function MedicineEdit(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const {
        drugs, saleTypes, healthTypes, sizeTypes, shapeTypes,
        potencyTypes, administrationRoutes,
    } = Store.useState("medicine");

    const { fetchEditData, editElem } = Store.useAction("medicine");


    // The Initial Data
    const initialData = {
        medicineID            : 0,
        name                  : "",
        alfabetaID            : "",
        barCodes              : '[""]',
        gtinCodes             : '[""]',
        troquel               : "",
        snomed                : "",
        price                 : "",
        validity              : "",
        laboratoryID          : 0,
        laboratoryName        : "",
        monoDrugID            : 0,
        monoDrugName          : "",
        saleTypeID            : 0,
        healthTypeID          : 0,
        sizeTypeID            : 0,
        shapeTypeID           : 0,
        presentation          : "",
        potency               : "",
        potencyTypeID         : 0,
        units                 : "",
        unitTypeID            : 0,
        unitTypeName          : "",
        administrationRouteID : 0,
        pharmaActionID        : 0,
        pharmaActionName      : "",
        prospecto             : "",
        pamiPercent           : "",
        pamiPrice             : "",
        iomaPrice             : "",
        iomaNormatizado       : 0,
        forSIFAR              : 0,
        forCeliac             : 0,
        reqRefrigeration      : 0,
        hasTax                : 0,
        hasGravamen           : 0,
        isImported            : 0,
        isActive              : 1,
        drugs                 : "[{}]",
    };

    // Handles the Set
    const handleSet = (elem) => {
        setElem({
            ...elem,
            barCodes  : elem?.barCodes?.length  ? JSON.stringify(elem.barCodes)  : '[""]',
            gtinCodes : elem?.gtinCodes?.length ? JSON.stringify(elem.gtinCodes) : '[""]',
            drugs     : elemID &&drugs.length   ? JSON.stringify(drugs)          : "[{}]",
        });
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("medicine", initialData, editElem, onSubmit);

    // Load the Data
    const { loading } = useDialog("medicine", open, elemID, null, handleSet, fetchEditData);



    // Do the Render
    const isEdit = Boolean(elemID);

    return <EditDialog
        open={open}
        icon="medicine"
        title={isEdit ? "MEDICINES_EDIT_TITLE" : "MEDICINES_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <InputField
            name="name"
            label="GENERAL_NAME"
            value={data.name}
            error={errors.name}
            onChange={handleChange}
            isRequired
        />
        <InputField
            type="number"
            name="alfabetaID"
            label="GENERAL_CODE_ALFABETA"
            value={data.alfabetaID}
            error={errors.alfabetaID}
            onChange={handleChange}
            isRequired
        />

        <Columns>
            <InputField
                type="list"
                inputType="number"
                name="barCodes"
                label="GENERAL_BAR_CODES"
                addButton="GENERAL_ADD_BAR_CODE"
                value={data.barCodes}
                error={errors.barCodes}
                onChange={handleChange}
            />
            <InputField
                type="list"
                inputType="number"
                name="gtinCodes"
                label="MEDICINES_GTIN_CODES"
                addButton="MEDICINES_ADD_GTIN_CODE"
                value={data.gtinCodes}
                error={errors.gtinCodes}
                onChange={handleChange}
            />

            <InputField
                type="number"
                name="troquel"
                label="MEDICINES_TROQUEL"
                value={data.troquel}
                error={errors.troquel}
                onChange={handleChange}
            />
            <InputField
                type="number"
                name="snomed"
                label="MEDICINES_SNOMED"
                value={data.snomed}
                error={errors.snomed}
                onChange={handleChange}
            />

            <InputField
                type="number"
                name="price"
                icon="price"
                label="GENERAL_PRICE"
                value={data.price}
                error={errors.price}
                onChange={handleChange}
                isRequired
            />
            <InputField
                type="date"
                name="validity"
                icon="money"
                label="GENERAL_PRICE_VALIDITY"
                value={data.validity}
                error={errors.validity}
                maxValue="2999-12-31"
                onChange={handleChange}
                isRequired
            />

            <InputField
                type="suggest"
                name="laboratoryName"
                label="LABORATORIES_SINGULAR"
                value={data.laboratoryName}
                error={errors.laboratoryID}
                suggestID="laboratoryID"
                suggestFetch={Laboratory.search}
                onChange={handleChange}
            />
            <InputField
                type="suggest"
                name="monoDrugName"
                label="MONO_DRUGS_SINGULAR"
                value={data.monoDrugName}
                error={errors.monoDrugID}
                suggestID="monoDrugID"
                suggestFetch={MonoDrug.search}
                onChange={handleChange}
            />

            <InputField
                type="select"
                name="saleTypeID"
                label="SALE_TYPES_SINGULAR"
                options={saleTypes}
                value={data.saleTypeID}
                error={errors.saleTypeID}
                onChange={handleChange}
            />
            <InputField
                type="select"
                name="healthTypeID"
                label="HEALTH_TYPES_SINGULAR"
                options={healthTypes}
                value={data.healthTypeID}
                error={errors.healthTypeID}
                onChange={handleChange}
            />
            <InputField
                type="select"
                name="sizeTypeID"
                label="SIZE_TYPES_SINGULAR"
                options={sizeTypes}
                value={data.sizeTypeID}
                error={errors.sizeTypeID}
                onChange={handleChange}
            />
            <InputField
                type="select"
                name="shapeTypeID"
                label="SHAPE_TYPES_SINGULAR"
                options={shapeTypes}
                value={data.shapeTypeID}
                error={errors.shapeTypeID}
                onChange={handleChange}
            />
            <InputField
                className="columns-double"
                name="presentation"
                label="GENERAL_PRESENTATION"
                value={data.presentation}
                error={errors.presentation}
                onChange={handleChange}
            />

            <InputField
                type="double"
                label="GENERAL_POTENCY"
                error={errors.potency}
                onChange={handleChange}
            >
                <InputItem
                    name="potency"
                    value={data.potency}
                />
                <InputItem
                    type="select"
                    name="potencyTypeID"
                    placeholder="POTENCY_TYPES_SINGULAR"
                    options={potencyTypes}
                    value={data.potencyTypeID}
                />
            </InputField>

            <InputField
                type="double"
                label="MEDICINES_UNITS"
                error={errors.units}
                onChange={handleChange}
            >
                <InputItem
                    name="units"
                    value={data.units}
                />
                <InputItem
                    type="suggest"
                    name="unitTypeName"
                    value={data.unitTypeName}
                    placeholder="UNIT_TYPES_SINGULAR"
                    suggestID="unitTypeID"
                    suggestFetch={UnitType.search}
                    onChange={handleChange}
                    hideClear
                />
            </InputField>

            <InputField
                type="select"
                name="administrationRouteID"
                label="ADMIN_ROUTES_SINGULAR"
                options={administrationRoutes}
                value={data.administrationRouteID}
                error={errors.administrationRouteID}
                onChange={handleChange}
            />
            <InputField
                type="suggest"
                name="pharmaActionName"
                label="PHARMA_ACTIONS_SINGULAR"
                value={data.pharmaActionName}
                error={errors.pharmaActionID}
                suggestID="pharmaActionID"
                suggestFetch={PharmaAction.search}
                onChange={handleChange}
            />

            <InputField
                className="columns-double"
                type="fields"
                name="drugs"
                title="MEDICINES_DRUGS_TITLE"
                addButton="MEDICINES_ADD_DRUG"
                columns="3"
                value={data.drugs}
                errors={errors}
                onChange={handleChange}
            >
                <InputItem
                    type="suggest"
                    label="DRUG_TYPES_SINGULAR"
                    name="drugTypeName"
                    suggestID="drugTypeID"
                    suggestFetch={DrugType.search}
                />
                <InputItem
                    type="number"
                    label="GENERAL_POTENCY"
                    name="potency"
                />
                <InputItem
                    type="select"
                    label="POTENCY_TYPES_SINGULAR"
                    name="potencyTypeID"
                    options={potencyTypes}
                />
            </InputField>

            <InputField
                type="number"
                name="prospecto"
                label="GENERAL_PROSPECTO"
                value={data.prospecto}
                error={errors.prospecto}
                onChange={handleChange}
            />
            <InputField
                type="number"
                name="pamiPercent"
                label="MEDICINES_PAMI_PERCENT"
                value={data.pamiPercent}
                error={errors.pamiPercent}
                onChange={handleChange}
            />
            <InputField
                type="number"
                icon="price"
                name="pamiPrice"
                label="MEDICINES_PAMI_PRICE"
                value={data.pamiPrice}
                error={errors.pamiPrice}
                onChange={handleChange}
            />
            <InputField
                type="number"
                name="iomaPrice"
                icon="price"
                label="MEDICINES_IOMA_PRICE"
                value={data.iomaPrice}
                error={errors.iomaPrice}
                onChange={handleChange}
            />
            <InputField
                type="toggle"
                name="iomaNormatizado"
                label="MEDICINES_IOMA_NORMATIZADO"
                value={data.iomaNormatizado}
                error={errors.iomaNormatizado}
                onChange={handleChange}
                withBorder
            />
            <InputField
                type="toggle"
                name="forSIFAR"
                label="MEDICINES_FOR_SIFAR"
                value={data.forSIFAR}
                error={errors.forSIFAR}
                onChange={handleChange}
                withBorder
            />

            <InputField
                type="toggle"
                name="forCeliac"
                label="GENERAL_FOR_CELIAC"
                value={data.forCeliac}
                error={errors.forCeliac}
                onChange={handleChange}
                withBorder
            />
            <InputField
                type="toggle"
                name="reqRefrigeration"
                label="GENERAL_REQ_REFRIGERATION"
                value={data.reqRefrigeration}
                error={errors.reqRefrigeration}
                onChange={handleChange}
                withBorder
            />
            <InputField
                type="toggle"
                name="hasTax"
                label="MEDICINES_HAS_TAX"
                value={data.hasTax}
                error={errors.hasTax}
                onChange={handleChange}
                withBorder
            />
            <InputField
                type="toggle"
                name="hasGravamen"
                label="MEDICINES_HAS_GRAVAMEN"
                value={data.hasGravamen}
                error={errors.hasGravamen}
                onChange={handleChange}
                withBorder
            />
            <InputField
                type="toggle"
                name="isImported"
                label="MEDICINES_IS_IMPORTED"
                value={data.isImported}
                error={errors.isImported}
                onChange={handleChange}
                withBorder
            />
            <InputField
                type="toggle"
                name="isActive"
                label="GENERAL_IS_ACTIVE"
                value={data.isActive}
                error={errors.isActive}
                onChange={handleChange}
                withBorder
            />
        </Columns>
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
MedicineEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default MedicineEdit;
