import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useFilter            from "Dashboard/Hooks/Filter";
import Utils                from "Dashboard/Utils/Utils";

// Dashboard
import FilterDialog         from "Dashboard/Components/Dialogs/FilterDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The User Filter Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function UserFilter(props) {
    const { open, filters, pharmacyID, forSubsidiary, onClose, onSubmit } = props;

    const { pharmacies, subsidiaries, subsidiariesPerPharmacy, accesses } = Store.useState("user");


    // The Initial Data
    const initialData = {
        access        : [],
        exclude       : 0,
        pharmacyIDs   : [],
        subsidiaryIDs : [],
    };

    // The Filter State
    const {
        loading, data, handleChange, handleSubmit,
    } = useFilter("user", open, initialData, filters, onSubmit);

    // Returns a select of subsidiaries based on the pharmacies
    const pharmacyIDs     = pharmacyID ? [ pharmacyID ] : data.pharmacyIDs;
    const getSubsidiaries = Utils.useSelectList(loading, pharmacyIDs, subsidiaries, subsidiariesPerPharmacy);


    // Do the Render
    const showPharmacy = !forSubsidiary && !pharmacyID;

    return <FilterDialog
        open={open}
        title="USERS_FILTER_TITLE"
        initialData={Utils.clone(initialData)}
        data={data}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        noAutoFocus
    >
        <InputField
            type="chooser"
            name="access"
            label="GENERAL_ACCESS"
            options={accesses}
            value={data.access}
            onChange={handleChange}
        />

        <InputField
            isHidden={forSubsidiary}
            type="toggle"
            name="exclude"
            label="GENERAL_EXCLUDE_FILTER"
            value={data.exclude}
            onChange={handleChange}
            withBorder
        />
        <InputField
            isHidden={!showPharmacy}
            type="chooser"
            name="pharmacyID"
            label="PHARMACIES_NAME"
            options={pharmacies}
            value={data.pharmacyIDs}
            onChange={handleChange}
        />
        <InputField
            type="chooser"
            name="subsidiaryID"
            label="SUBSIDIARIES_NAME"
            options={getSubsidiaries()}
            value={data.subsidiaryIDs}
            onChange={handleChange}
        />
    </FilterDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
UserFilter.propTypes = {
    open          : PropTypes.bool.isRequired,
    onClose       : PropTypes.func.isRequired,
    onSubmit      : PropTypes.func.isRequired,
    filters       : PropTypes.object,
    pharmacyID    : PropTypes.number,
    forSubsidiary : PropTypes.bool,
};

export default UserFilter;
