import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import Period               from "Dashboard/Utils/Period";
import useFilter            from "Dashboard/Hooks/Filter";
import Utils                from "Dashboard/Utils/Utils";

// Dashboard
import FilterDialog         from "Dashboard/Components/Dialogs/FilterDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Order Filter Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function OrderFilter(props) {
    const { open, filters, pharmacyID, subsidiaryID, forCanceled, onClose, onSubmit } = props;

    const {
        stateGroups, stateTypes, stateTypesPerGroup, retirementTypes,
        pharmacies, subsidiaries, subsidiariesPerPharmacy,
        rejectReasons, cancelReasons,
    } = Store.useState("order");

    const { isAnyAdmin, isSuperAdmin } = Store.useState("auth");


    // The Initial Data
    const initialData = {
        period           : 0,
        fromDate         : "",
        toDate           : "",
        stateGroupID     : [],
        stateID          : [],
        useInterStates   : 0,
        retirementTypeID : [],
        exclude          : 0,
        pharmacyID       : [],
        subsidiaryID     : [],
        orderNumber      : "",
        productName      : "",
        rejectReasonID   : [],
        cancelReasonID   : [],
        withChat         : 0,
        withNPS          : "",
        useCreatedTime   : 0,
    };

    // The Filter State
    const {
        loading, data, handleChange, handlePeriod, handleSubmit,
    } = useFilter("order", open, initialData, filters, onSubmit);


    // Returns a select of subsidiaries based on the pharmacies
    const pharmacyIDs     = pharmacyID ? [ pharmacyID ] : (pharmacies.length === 1 ? pharmacies[0].key : data.pharmacyID);
    const getStateTypes   = Utils.useSelectList(loading, data.stateGroupID, stateTypes, stateTypesPerGroup);
    const getSubsidiaries = Utils.useSelectList(loading, pharmacyIDs, subsidiaries, subsidiariesPerPharmacy);


    // Variables
    const showExclusion  = isAnyAdmin && !pharmacyID && !subsidiaryID;
    const showPharmacy   = !pharmacyID && pharmacies.length > 1;
    const showSubsidiary = !subsidiaryID && subsidiaries.length > 1;


    // Do the Render
    return <FilterDialog
        open={open}
        title="ORDERS_FILTER_TITLE"
        initialData={Utils.clone(initialData)}
        data={data}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        noAutoFocus
    >
        <Columns amount="3">
            <InputField
                type="select"
                name="period"
                label="PERIOD_NAME"
                value={data.period}
                onChange={handlePeriod}
                options={Period.getSelect()}
            />
            <InputField
                type="date"
                name="fromDate"
                label="GENERAL_FROM"
                value={data.fromDate}
                maxValue="2999-12-31"
                onChange={handleChange}
            />
            <InputField
                type="date"
                name="toDate"
                label="GENERAL_TO"
                value={data.toDate}
                maxValue="2999-12-31"
                onChange={handleChange}
            />
        </Columns>

        <InputField
            isHidden={!isAnyAdmin}
            type="chooser"
            name="stateGroupID"
            label="STATE_GROUPS_NAME"
            options={stateGroups}
            value={data.stateGroupID}
            onChange={handleChange}
        />
        <InputField
            isHidden={!isAnyAdmin}
            type="chooser"
            name="stateID"
            label="STATES_NAME"
            options={getStateTypes()}
            value={data.stateID}
            onChange={handleChange}
        />
        <InputField
            isHidden={!isAnyAdmin}
            type="toggle"
            name="useInterStates"
            label="ORDERS_USE_INTER_STATES"
            options={getStateTypes()}
            value={data.useInterStates}
            onChange={handleChange}
            withBorder
        />

        <InputField
            type="chooser"
            name="retirementTypeID"
            label="RETIREMENT_TYPES_SINGULAR"
            options={retirementTypes}
            value={data.retirementTypeID}
            onChange={handleChange}
        />

        <InputField
            isHidden={!showExclusion}
            type="toggle"
            name="exclude"
            label="GENERAL_EXCLUDE_FILTER"
            value={data.exclude}
            onChange={handleChange}
            withBorder
        />
        <InputField
            isHidden={!showPharmacy}
            type="chooser"
            name="pharmacyID"
            label="PHARMACIES_NAME"
            options={pharmacies}
            value={data.pharmacyID}
            onChange={handleChange}
        />
        <InputField
            isHidden={!showSubsidiary}
            type="chooser"
            name="subsidiaryID"
            label="SUBSIDIARIES_NAME"
            options={getSubsidiaries()}
            value={data.subsidiaryID}
            onChange={handleChange}
        />
        <InputField
            name="orderNumber"
            label="ORDERS_NUMBER"
            value={data.orderNumber}
            onChange={handleChange}
        />
        <InputField
            name="productName"
            label="PRODUCTS_SINGULAR"
            value={data.productName}
            onChange={handleChange}
        />
        <InputField
            isHidden={!forCanceled}
            type="chooser"
            name="rejectReasonID"
            label="REJECT_REASONS_NAME"
            options={rejectReasons}
            value={data.rejectReasonID}
            onChange={handleChange}
        />
        <InputField
            isHidden={!forCanceled}
            type="chooser"
            name="cancelReasonID"
            label="CANCEL_REASONS_NAME"
            options={cancelReasons}
            value={data.cancelReasonID}
            onChange={handleChange}
        />

        <InputField
            type="select"
            name="withChat"
            label="ORDERS_WITH_CHAT"
            options="GENERAL_SET_YES_NO"
            value={data.withChat}
            onChange={handleChange}
        />
        <InputField
            isHidden={!isAnyAdmin}
            type="select"
            name="withNPS"
            label="ORDERS_WITH_NPS"
            options="SELECT_NPS_FILTERS"
            value={data.withNPS}
            onChange={handleChange}
        />
        <InputField
            isHidden={!isSuperAdmin}
            type="toggle"
            name="useCreatedTime"
            label="ORDERS_USE_CREATED_TIME"
            value={data.useCreatedTime}
            onChange={handleChange}
            withBorder
        />
    </FilterDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
OrderFilter.propTypes = {
    open         : PropTypes.bool.isRequired,
    onClose      : PropTypes.func.isRequired,
    onSubmit     : PropTypes.func.isRequired,
    filters      : PropTypes.object,
    pharmacyID   : PropTypes.number,
    subsidiaryID : PropTypes.number,
    forCanceled  : PropTypes.bool,
};

export default OrderFilter;
