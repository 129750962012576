import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";
import Charts               from "Utils/Charts";

// Components
import DashboardTitle       from "../../Utils/Components/DashboardTitle";

// Dashboard
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableFoot            from "Dashboard/Components/Table/TableFoot";
import ColorCircle          from "Dashboard/Components/Common/ColorCircle";



// Styles
const Container = Styled.div`
    box-sizing: border-box;

    thead {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }
    tbody {
        height: calc(var(--dashboard-list) - 29px);
        overflow: auto;
    }

    @media (max-width: 700px) {
        tbody {
            border-radius: 0;
        }
    }
`;

const Header = Styled.header`
    padding: var(--main-gap);
    border: 1px solid var(--border-color-light);
    border-bottom: none;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
`;



/**
 * The Dashboard List
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function DashboardList(props) {
    const { title, name, column, list, total } = props;


    // Do the Render
    const hasTotal = total !== undefined;

    return <Container>
        <Header>
            <DashboardTitle message={NLS.format(title, name)} />
        </Header>
        <Table noSorting notFixed>
            <TableHead>
                <TableHeader field="name"  message={column}        isFlex isTitle grow="4" />
                <TableHeader field="total" message="GENERAL_TOTAL" isFlex maxWidth="120" align="right" />
            </TableHead>
            <TableBody>
                {list.map((elem, index) => <TableRow key={elem.name}>
                    <TableCell>
                        <ColorCircle color={Charts.getColor(index, elem.color)} />
                        {elem.name}
                    </TableCell>
                    <TableCell message={Charts.formatNumber(elem.total)} />
                </TableRow>)}
            </TableBody>
            {hasTotal && <TableFoot>
                <TableHeader message="GENERAL_TOTAL" />
                <TableHeader message={total || "0"} />
            </TableFoot>}
        </Table>
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
DashboardList.propTypes = {
    title  : PropTypes.string.isRequired,
    name   : PropTypes.string.isRequired,
    column : PropTypes.string.isRequired,
    list   : PropTypes.array.isRequired,
    total  : PropTypes.number,
};

export default DashboardList;
