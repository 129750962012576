import React                from "react";
import Store                from "Dashboard/Core/Store";

// Components
import DashboardTotals      from "../../Utils/Components/DashboardTotals";
import DashboardTotal       from "../../Utils/Components/DashboardTotal";



/**
 * The Commercial Totals
 * @returns {React.ReactElement}
 */
function CommercialTotals() {
    const { isAnyAdmin } = Store.useState("auth");
    const { totals, objectives } = Store.useState("dashboardCommercial");


    // Do the Render
    return <>
        <DashboardTotals>
            <DashboardTotal
                isHidden={!isAnyAdmin}
                message="DASHBOARD_COMMERCIAL_GROSS_OBJECTIVE"
                tooltip="DASHBOARD_COMMERCIAL_GROSS_OBJECTIVE_TP"
                value={objectives.grossTotal || 0}
                isPrice
            />
            <DashboardTotal
                isHidden={!isAnyAdmin}
                message="DASHBOARD_COMMERCIAL_OBJECTIVE_DIFF"
                tooltip="DASHBOARD_COMMERCIAL_GROSS_OBJ_DIFF_TP"
                value={totals.grossTotal - (objectives.grossTotal || 0)}
                isPrice
                isDiff
            />

            <DashboardTotal
                isHidden={!isAnyAdmin}
                message="DASHBOARD_COMMERCIAL_SALES_OBJECTIVE"
                tooltip="DASHBOARD_COMMERCIAL_SALES_OBJECTIVE_TP"
                value={objectives.salesTotal || 0}
            />
            <DashboardTotal
                isHidden={!isAnyAdmin}
                message="DASHBOARD_COMMERCIAL_OBJECTIVE_DIFF"
                tooltip="DASHBOARD_COMMERCIAL_SALES_OBJ_DIFF_TP"
                value={totals.effectiveOrders - (objectives.salesTotal || 0)}
                isDiff
            />
        </DashboardTotals>

        <DashboardTotals>
            <DashboardTotal
                message="DASHBOARD_COMMERCIAL_GROSS_BILLING"
                tooltip="DASHBOARD_COMMERCIAL_GROSS_BILLING_TP"
                value={totals.grossTotal}
                isPrice
            />
            <DashboardTotal
                message="DASHBOARD_COMMERCIAL_AVG_GROSS_TICKET"
                tooltip="DASHBOARD_COMMERCIAL_AVG_GROSS_TICKET_T"
                value={totals.averageGrossTicket}
                isPrice
            />
            <DashboardTotal
                message="DASHBOARD_COMMERCIAL_NET_BILLING"
                tooltip="DASHBOARD_COMMERCIAL_NET_BILLING_TP"
                value={totals.netTotal}
                isPrice
            />
            <DashboardTotal
                message="DASHBOARD_COMMERCIAL_AVG_NET_TICKET"
                tooltip="DASHBOARD_COMMERCIAL_AVG_NET_TICKET_TP"
                value={totals.averageNetTicket}
                isPrice
            />
        </DashboardTotals>

        <DashboardTotals>
            <DashboardTotal
                isHidden={!isAnyAdmin}
                message="DASHBOARD_COMMERCIAL_TOTAL_ORDERS"
                tooltip="DASHBOARD_COMMERCIAL_TOTAL_ORDERS_TP"
                value={totals.totalOrders}
            />
            <DashboardTotal
                isHidden={!isAnyAdmin}
                message="DASHBOARD_COMMERCIAL_PENDING_ORDERS"
                tooltip="DASHBOARD_COMMERCIAL_PENDING_ORDERS_TP"
                value={totals.pendingOrders}
            />
            <DashboardTotal
                message="DASHBOARD_COMMERCIAL_EFFECTIVE_ORDERS"
                tooltip="DASHBOARD_COMMERCIAL_EFFECTIVE_ORDERS_T"
                value={totals.effectiveOrders}
            />

            <DashboardTotal
                isHidden={!isAnyAdmin}
                message="DASHBOARD_COMMERCIAL_FEE_CHARGED"
                tooltip="DASHBOARD_COMMERCIAL_FEE_CHARGED_TP"
                value={totals.benvidaFeeCharged}
                isPrice
            />
            <DashboardTotal
                isHidden={!isAnyAdmin}
                message="DASHBOARD_COMMERCIAL_FEE_TO_CHARGE"
                tooltip="DASHBOARD_COMMERCIAL_FEE_TO_CHARGE_TP"
                value={totals.benvidaFeeToCharge}
                isPrice
            />
        </DashboardTotals>
    </>;
}

export default CommercialTotals;
