import React                from "react";
import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";

// Components
import PharmacyPage         from "./Pharmacy/PharmacyPage";
import PharmacyView         from "./Pharmacy/PharmacyView";
import SubsidiaryList       from "./Subsidiary/SubsidiaryList";
import ClosedList           from "./Closed/ClosedList";
import GatewayList          from "./Gateway/GatewayList";
import UserList             from "./User/UserList";
import ContactList          from "./Contact/ContactList";
import OrderList            from "Components/App/Order/Order/OrderList";
import OrderPage            from "Components/App/Order/Order/OrderPage";

// Dashboard
import Router               from "Dashboard/Components/Route/Router";
import AdminRoute           from "Dashboard/Components/Route/AdminRoute";
import SecondaryNav         from "Dashboard/Components/Navigation/SecondaryNav";
import NavigationList       from "Dashboard/Components/Navigation/NavigationList";
import NavigationItem       from "Dashboard/Components/Navigation/NavigationItem";



/**
 * The Pharmacy Container
 * @returns {React.ReactElement}
 */
function PharmacyContainer() {
    const { pharmacyID } = Navigate.useParams();

    const { isFinance } = Store.useState("auth");

    const { elem } = Store.useState("pharmacy");
    const { fetchElem } = Store.useAction("pharmacy");


    // Load the Data
    React.useEffect(() => {
        fetchElem(pharmacyID);
    }, [ pharmacyID ]);


    // Do the Render
    return <>
        <SecondaryNav
            message={elem.name}
            fallback="PHARMACIES_SINGULAR"
            href={Navigate.useFrom()}
        >
            <NavigationList>
                <NavigationItem
                    message="GENERAL_INFO"
                    url="INFORMATION"
                    icon="pharmacy"
                />
                <NavigationItem
                    message="SUBSIDIARIES_NAME"
                    url="SUBSIDIARIES"
                    icon="subsidiary"
                />
                <NavigationItem
                    message="CLOSED_DAYS_NAME"
                    url="CLOSED_DAYS"
                    icon="closed-day"
                />
                <NavigationItem
                    message="GATEWAYS_NAME"
                    url="GATEWAYS"
                    icon="gateway"
                />
            </NavigationList>

            <NavigationList>
                <NavigationItem
                    message="ORDERS_NAME"
                    url="ORDERS"
                    icon="order"
                />
                <NavigationItem
                    isHidden={isFinance}
                    message="USERS_NAME"
                    url="USERS"
                    icon="user"
                />
                <NavigationItem
                    message="CONTACTS_NAME"
                    url="CONTACTS"
                    icon="contact"
                />
            </NavigationList>
        </SecondaryNav>

        <Router type="PHARMACY">
            <AdminRoute url="INFORMATION"  component={PharmacyPage}   isHidden={isFinance} />
            <AdminRoute url="INFORMATION"  component={PharmacyView}   isHidden={!isFinance} />
            <AdminRoute url="SUBSIDIARIES" component={SubsidiaryList} />

            <AdminRoute url="CLOSED_DAYS"  component={ClosedList}     />
            <AdminRoute url="GATEWAYS"     component={GatewayList}    />
            <AdminRoute url="USERS"        component={UserList}       />
            <AdminRoute url="CONTACTS"     component={ContactList}    />

            <AdminRoute url="ORDERS"       component={OrderList}      />
            <AdminRoute url="ORDERS_TAB"   component={OrderList}      />
            <AdminRoute url="ORDER"        component={OrderPage}      />
        </Router>
    </>;
}

export default PharmacyContainer;
