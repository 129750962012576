import React                from "react";
import { Line }             from "react-chartjs-2";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import NLS                  from "Dashboard/Core/NLS";
import DateTime             from "Dashboard/Utils/DateTime";
import Charts               from "Utils/Charts";



// Styles
const Container = Styled.div`
    flex-grow: 2;
    box-sizing: border-box;
    height: var(--dashboard-report);
    padding: var(--main-gap);
    border: 1px solid var(--border-color-light);
    border-radius: var(--border-radius);
`;



/**
 * The Daily Chart Line
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function DailyChartLine(props) {
    const { currentDate } = props;

    const {
        dayLabels, holidays, events,
        thisOrders, lastOrders, thisEffective, lastEffective,
    } = Store.useState("dashboardDaily");


    // Variables
    const date      = DateTime.create(currentDate.time);
    const thisMonth = date.getMonthName();
    const lastMonth = date.toMonthStart().moveMonth(-1).getMonthName();


    // Do the Render
    return <Container>
        <Line
            options={{
                maintainAspectRatio : false,
                interaction : {
                    mode      : "index",
                    intersect : false,
                },
                animation : {
                    duration : 0,
                },
                scales : {
                    x : Charts.getDateScale(holidays, events, true),
                    y : Charts.getSimpleScale(false, false, false),
                },
                plugins : {
                    legend  : Charts.getLegendOptions("top"),
                    tooltip : Charts.getDateTooltip(holidays, events, true),
                },
            }}
            data={{
                labels   : dayLabels,
                datasets : [
                    {
                        // @ts-ignore
                        order            : 0,
                        yAxisID          : "y",
                        label            : NLS.format("DASHBOARD_DAILY_ORDERS_CHART", thisMonth),
                        data             : thisOrders,
                        borderColor      : "rgb(255, 134, 128)",
                        backgroundColor  : "rgb(255, 134, 128)",
                        pointBorderWidth : 4,
                        borderWidth      : 2,
                        tension          : 0.1,
                        borderDash       : [],
                    },
                    {
                        // @ts-ignore
                        order            : 1,
                        yAxisID          : "y",
                        label            : NLS.format("DASHBOARD_DAILY_EFFECTIVE_CHART", thisMonth),
                        data             : thisEffective,
                        borderColor      : "rgb(181, 158, 236)",
                        backgroundColor  : "rgb(181, 158, 236)",
                        pointBorderWidth : 4,
                        borderWidth      : 2,
                        tension          : 0.1,
                        borderDash       : [],
                    },

                    {
                        // @ts-ignore
                        order            : 2,
                        yAxisID          : "y",
                        label            : NLS.format("DASHBOARD_DAILY_ORDERS_CHART", lastMonth),
                        data             : lastOrders,
                        borderColor      : "rgba(255, 134, 128, 0.5)",
                        backgroundColor  : "rgba(255, 134, 128, 0.5)",
                        pointBorderWidth : 2,
                        borderWidth      : 1,
                        tension          : 0.1,
                        borderDash       : [ 10, 5 ],
                    },
                    {
                        // @ts-ignore
                        order            : 3,
                        yAxisID          : "y",
                        label            : NLS.format("DASHBOARD_DAILY_EFFECTIVE_CHART", lastMonth),
                        data             : lastEffective,
                        borderColor      : "rgb(181, 158, 236, 0.5)",
                        backgroundColor  : "rgb(181, 158, 236, 0.5)",
                        pointBorderWidth : 2,
                        borderWidth      : 1,
                        tension          : 0.1,
                        borderDash       : [ 10, 5 ],
                    },
                ],
            }}
        />
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
DailyChartLine.propTypes = {
    currentDate : PropTypes.object.isRequired,
};

export default DailyChartLine;
