import NLS                  from "Dashboard/Core/NLS";
import DateTime             from "Dashboard/Utils/DateTime";
import Utils                from "Dashboard/Utils/Utils";

// Constants
const COLORS = [
    "rgb(59,  199, 181)", "rgb(181, 158, 236)", "rgb(2, 131, 200)",
    "rgb(255, 134, 128)", "rgb(255, 196, 123)",
    "rgb(240, 150,   9)", "rgb(123,  79, 157)", "rgb( 51, 153,  51)",
    "rgb(229,  20,   0)", "rgb( 46, 139, 204)", "rgb(  0, 134,  65)",
    "rgb(255, 196,  13)", "rgb(230, 113, 184)", "rgb( 32,  96, 142)",
    "rgb( 78,   0,  56)", "rgb(184,  27, 108)", "rgb( 86, 156, 227)",
];



/**
 * Formats a Number
 * @param {Number}   value
 * @param {Boolean=} isPrice
 * @param {Boolean=} isPercent
 * @returns {String}
 */
function formatNumber(value, isPrice = false, isPercent = false) {
    const formatted = Utils.formatNumber(value);
    return `${isPrice ? "$" : ""} ${formatted}${isPercent ? "%" : ""}`;
}

/**
 * Returns a Color for the given Number
 * @param {Number}  number
 * @param {String=} defaultColor
 * @returns {String}
 */
function getColor(number, defaultColor = "") {
    if (defaultColor && defaultColor !== "#ffffff") {
        return defaultColor;
    }
    const index = number % COLORS.length;
    return COLORS[index];
}

/**
 * Returns a list of Colors for the Chart
 * @param {Object[]} list
 * @param {Number=}  amount
 * @returns {String[]}
 */
function getColorList(list, amount = 0) {
    const result = [];
    for (const [ index, elem ] of list.entries()) {
        result.push(getColor(index, elem.color));
        if (amount > 0 && result.length >= amount) {
            break;
        }
    }
    if (list.length > result.length) {
        result.push("rgb(220, 220, 220)");
    }
    return result;
}

/**
 * Returns a list of Labels for the Chart
 * @param {Object[]} list
 * @param {String=}  noneMessage
 * @param {Number=}  maxLength
 * @param {Number=}  amount
 * @returns {String[]}
 */
function getLabelList(list, noneMessage = "", maxLength = 0, amount = 0) {
    const result = [];
    for (const elem of list) {
        let name = String(elem.name || NLS.get(noneMessage));
        if (maxLength && name.length > maxLength) {
            name = name.substring(0, maxLength) + "...";
        }
        result.push(name);
        if (amount > 0 && result.length >= amount) {
            break;
        }
    }
    if (list.length > result.length) {
        result.push(NLS.get("GENERAL_OTHERS"));
    }
    return result;
}

/**
 * Returns a list of Values for the Chart
 * @param {Object[]} list
 * @param {String}   field
 * @param {Number=}  amount
 * @returns {String[]}
 */
function getValueList(list, field, amount = 0) {
    const result = [];
    let   others = 0;
    for (const elem of list) {
        if (amount > 0 && result.length >= amount) {
            others += elem[field];
        } else {
            result.push(elem[field]);
        }
    }
    if (list.length > result.length) {
        result.push(others);
    }
    return result;
}



/**
 * Creates a Simple Scale
 * @param {Boolean=} isPrice
 * @param {Boolean=} isPercent
 * @param {Boolean=} isStacked
 * @returns {Object}
 */
function getSimpleScale(isPrice = false, isPercent = false, isStacked = false) {
    return {
        stacked : isStacked,
        min     : 0,
        ticks   : {
            precision : 0,
            callback(label) {
                return formatNumber(Number(label), isPrice, isPercent);
            },
        },
    };
}

/**
 * Creates a Date Scale
 * @param {Object[]} holidays
 * @param {Object[]} events
 * @param {Boolean=} isDaily
 * @param {Boolean=} isStacked
 * @returns {Object}
 */
function getDateScale(holidays, events, isDaily = false, isStacked = false) {
    if (!isDaily) {
        return {
            stacked : isStacked,
        };
    }
    return {
        stacked : isStacked,
        ticks   : {
            callback(value) {
                const label = this.getLabelForValue(Number(value));
                const { date, text } = createDateFromLabel(label);

                if (isEvent(events, date.toString("dashes"))) {
                    return `E ${text}`;
                }
                if (isHoliday(holidays, date.day)) {
                    return `F ${text}`;
                }
                if (date.isWeekend) {
                    return `${date.getDayName(1)} ${text}`;
                }
                return text;
            },
            color(context) {
                const label = String(context.tick.label);
                if (label.startsWith("E")) {
                    return "rgb(240, 150, 9)";
                }
                if (label.startsWith("F")) {
                    return "rgb(1, 163, 28)";
                }
                if (label.startsWith("S") || label.startsWith("D")) {
                    return "rgb(255, 0, 51)";
                }
                return "black";
            },
        },
    };
}

/**
 * Returns the Chart Legend options
 * @param {String} position
 * @returns {Object}
 */
function getLegendOptions(position) {
    return {
        position : position,
        labels   : {
            usePointStyle : true,
        },
    };
}

/**
 * Returns the Chart Tooltip options
 * @param {Boolean=} isPrice
 * @param {Object=}  callbacks
 * @param {Object=}  options
 * @returns {Object}
 */
function getTooltipOptions(isPrice, callbacks = {}, options = {}) {
    return {
        cornerRadius  : 10,
        padding       : 8,
        boxPadding    : 4,
        usePointStyle : true,
        callbacks     : {
            label(context) {
                if (context.dataset.label) {
                    const { label, prefix, suffix } = context.dataset;
                    return `${label}: ${prefix || ""}${formatNumber(Number(context.raw))}${suffix || ""}`;
                }
                return formatNumber(Number(context.raw), isPrice);
            },
            ...callbacks,
        },
        ...options,
    };
}

/**
 * Returns the Chart Tooltip options for Dates
 * @param {Object[]} holidays
 * @param {Object[]} events
 * @param {Boolean=} isDaily
 * @returns {Object}
 */
function getDateTooltip(holidays, events, isDaily = true) {
    if (!isDaily) {
        return getTooltipOptions(false);
    }

    return getTooltipOptions(false, {
        title(context) {
            const { date, text } = createDateFromLabel(context[0].label);
            const holiday = Utils.getValue(holidays, "day", date.day, "name");
            if (holiday) {
                return `${date.getDayName()}, ${text} (${holiday})`;
            }
            return `${date.getDayName()}, ${text}`;
        },
        afterTitle(context) {
            const { date } = createDateFromLabel(context[0].label);
            return Utils.getValue(events, "timeDate", date.toString("dashes"), "description");
        },
    });
}



/**
 * Creates a Date and Text from the Label
 * @param {Object} label
 * @returns {Object}
 */
function createDateFromLabel(label) {
    const [ day, month, year ] = String(label).split("-");
    const date     = DateTime.create().changeYear(Number(year), Number(month), Number(day));
    const yearText = Number(year) !== DateTime.create().year ? ` ${year}` : "";
    const text     = `${date.day} ${date.getMonthName(3)}${yearText}`;
    return { date, text };
}

/**
 * Returns true if the given day is a holiday
 * @param {Object[]} holidays
 * @param {Number}   day
 * @returns {Boolean}
 */
function isHoliday(holidays, day) {
    return holidays.findIndex((elem) => elem.day === day) > -1;
}

/**
 * Returns true if the given day is an event
 * @param {Object[]} events
 * @param {String}   date
 * @returns {Boolean}
 */
function isEvent(events, date) {
    return events.findIndex((elem) => elem.timeDate === date) > -1;
}




// The public API
export default {
    formatNumber,
    getColor,
    getColorList,
    getLabelList,
    getValueList,

    getSimpleScale,
    getDateScale,
    getLegendOptions,
    getTooltipOptions,
    getDateTooltip,

    createDateFromLabel,
    isHoliday,
    isEvent,
};
