import React                from "react";
import Action               from "Dashboard/Core/Action";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";
import { StoreSpace }       from "Utils/API";

// Components
import SpaceEdit            from "./SpaceEdit";
import SpaceImport          from "./SpaceImport";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";
import ConfirmDialog        from "Dashboard/Components/Dialogs/ConfirmDialog";
import DeleteDialog         from "Dashboard/Components/Dialogs/DeleteDialog";
import Image                from "Dashboard/Components/Common/Image";



/**
 * The Space List
 * @returns {React.ReactElement}
 */
function SpaceList() {
    const { fetch, load } = useList("storeSpace");

    const data = Store.useState("storeSpace");
    const { loading, canEdit, list, total, sort } = data;

    const { deleteElem } = Store.useAction("storeSpace");


    // The Current State
    const [ exporting, setExporting ] = React.useState(false);
    const [ action, elemID, startAction, endAction ] = Action.useActionID();


    // Handles the Edit Submit
    const handleEdit = () => {
        endAction();
        fetch();
    };

    // Handles the Export Submit
    const handleExport = async () => {
        setExporting(true);
        const source = StoreSpace.exportProducts({ storeSpaceID : elemID });
        await Utils.download(source, NLS.get("SPACES_FILE_NAME"));
        endAction();
        setExporting(false);
    };

    // Handles the Delete Submit
    const handleDelete = async () => {
        endAction();
        const result = await deleteElem(elemID);
        if (result.success) {
            fetch();
        }
    };


    // Returns true if it can't Import or Export the Products
    const cantIO = (elemID) => {
        const elem = Utils.getValue(list, "storeSpaceID", elemID);
        return !!elem.allProducts;
    };


    // Do the Render
    const elemName = Utils.getValue(list, "storeSpaceID", elemID, "name");

    return <Main>
        <Header message="SPACES_NAME" icon="space">
            <ActionList data={data} onAction={startAction} />
        </Header>
        <Content>
            <Table
                fetch={load}
                sort={sort}
                none="SPACES_NONE_AVAILABLE"
                isLoading={loading}
            >
                <TableHead>
                    <TableHeader field="image"        message=""                 noSorting noSpace maxWidth="60" />
                    <TableHeader field="storeSpaceID" message="GENERAL_ID"       isFlex maxWidth="50" />
                    <TableHeader field="name"         message="SPACES_SINGULAR"  isFlex isTitle />
                    <TableHeader field="productCount" message="PRODUCTS_NAME"    isFlex align="center" maxWidth="100" />
                    <TableHeader field="status"       message="GENERAL_STATUS"   isFlex align="center" maxWidth="100" />
                    <TableHeader field="position"     message="GENERAL_POSITION" isFlex maxWidth="80" align="center" hideMobile />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.storeSpaceID} elemID={elem.storeSpaceID}>
                        <TableCell>
                            <Image
                                source={elem.imageUrl}
                                message={elem.name}
                                size="50"
                            />
                        </TableCell>
                        <TableCell message={elem.storeSpaceID}     />
                        <TableCell message={elem.name}             />
                        <TableCell message={elem.productCountText} />
                        <TableCell message={elem.statusName}       textColor={elem.statusColor} />
                        <TableCell message={elem.position}         />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={startAction} canEdit={canEdit}>
                    <TableAction action="EDIT"   message="SPACES_EDIT_TITLE"   />
                    <TableAction action="EXPORT" message="SPACES_EXPORT_TITLE" hide={cantIO} />
                    <TableAction action="IMPORT" message="SPACES_IMPORT_TITLE" hide={cantIO} />
                    <TableAction action="DELETE" message="SPACES_DELETE_TITLE" />
                </TableActionList>
            </Table>
        </Content>

        <SpaceEdit
            open={action.isVCE}
            elemID={elemID}
            onSubmit={handleEdit}
            onClose={endAction}
        />
        <SpaceImport
            open={action.isImport}
            elemID={elemID}
            elemName={elemName}
            onSubmit={handleEdit}
            onClose={endAction}
        />
        <ConfirmDialog
            open={action.isExport}
            isLoading={exporting}
            icon="export"
            title="SPACES_EXPORT_TITLE"
            message="SPACES_EXPORT_TEXT"
            content={elemName}
            onSubmit={handleExport}
            onClose={endAction}
        />
        <DeleteDialog
            open={action.isDelete}
            title="SPACES_DELETE_TITLE"
            message="SPACES_DELETE_TEXT"
            content={elemName}
            onSubmit={handleDelete}
            onClose={endAction}
        />
    </Main>;
}

export default SpaceList;
