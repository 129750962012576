import React                from "react";
import { Bar }              from "react-chartjs-2";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Charts               from "Utils/Charts";

// Dashboard
import TabList              from "Dashboard/Components/Tab/TabList";
import TabItem              from "Dashboard/Components/Tab/TabItem";



// Styles
const Container = Styled.section`
    box-sizing: border-box;
    padding: var(--main-gap);
    border: 1px solid var(--border-color-light);
    border-radius: var(--border-radius);
`;

const Chart = Styled.div`
    height: 300px;
`;




/**
 * The Agent Charts
 * @returns {React.ReactElement}
 */
function AgentCharts() {
    const { charts } = Store.useState("dashboardAgent");


    // The Current State
    const [ userIdx, setUserIdx ] = React.useState(0);


    // The Chart Names
    const names = {
        "Assigned-Success"  : "DASHBOARD_AGENT_ASSIGNED_ORDERS",
        "Assigned-Error"    : "DASHBOARD_AGENT_ASSIGNED_ORDERS",
        "Assigned-Pending"  : "DASHBOARD_AGENT_ASSIGNED_ORDERS",
        "Validated-Success" : "DASHBOARD_AGENT_VALIDATED_ORDERS",
        "Validated-Error"   : "DASHBOARD_AGENT_CANCELED_ORDERS",
        "Validated-Pending" : "DASHBOARD_AGENT_IN_PROGRESS_ORDERS",
        "Effective-Success" : "DASHBOARD_AGENT_EFFECTIVE_ORDERS",
        "Effective-Error"   : "DASHBOARD_AGENT_CANCELED_ORDERS",
        "Effective-Pending" : "DASHBOARD_AGENT_PENDING_ORDERS",
    };
    const labels = [
        "Assigned",
        "Validated",
        "Effective",
    ];

    // Generates the Datasets
    const datasets = React.useMemo(() => {
        const data   = charts[userIdx] || {};
        const result = [
            {
                label              : "Success",
                data               : [ data.totalAssigned, data.assignedValidated, data.validatedEffective ],
                backgroundColor    : [ "rgb(202, 223, 183)", "rgb(176, 207, 148)", "rgb(159, 206, 98)" ],
                borderColor        : "#ffffff00",
                hoverBorderColor   : "#ffffff00",
                borderWidth        : 2,
                hoverBorderWidth   : 2,
                barPercentage      : 1,
                categoryPercentage : 1,
            },
            {
                label              : "Error",
                data               : [ 0, data.assignedCanceled, data.validatedCanceled ],
                backgroundColor    : [ "rgb(202, 223, 183)", "rgb(240, 205, 176)", "rgb(233, 171, 121)" ],
                borderColor        : "#ffffff00",
                hoverBorderColor   : "#ffffff00",
                borderWidth        : 2,
                hoverBorderWidth   : 2,
                barPercentage      : 1,
                categoryPercentage : 1,
            },
            {
                label              : "Pending",
                data               : [ 0, data.assignedProgress, data.validatedPending ],
                backgroundColor    : [ "rgb(202, 223, 183)", "rgb(216, 216, 216)", "rgb(165, 165, 165)" ],
                borderColor        : "#ffffff00",
                hoverBorderColor   : "#ffffff00",
                borderWidth        : 2,
                hoverBorderWidth   : 2,
                barPercentage      : 1,
                categoryPercentage : 1,
            },
        ];
        return result;
    }, [ userIdx, JSON.stringify(charts) ]);


    // Do the Render
    if (!charts.length) {
        return <React.Fragment />;
    }
    return <Container>
        <TabList selected={userIdx} onClick={setUserIdx}>
            {charts.map(({ name }, index) => <TabItem
                key={index}
                message={name}
                value={index}
            />)}
        </TabList>

        <Chart>
            <Bar
                options={{
                    maintainAspectRatio : false,
                    indexAxis : "y",
                    scales : {
                        y : {
                            stacked : true,
                            grid    : { display : false },
                            ticks   : { display : false },
                        },
                        x : {
                            stacked : true,
                            ticks   : { stepSize : 1 },
                        },
                    },
                    plugins : {
                        legend  : { display : false },
                        tooltip : Charts.getTooltipOptions(false, {
                            title(context) {
                                const label = `${context[0].label}-${context[0].dataset.label}`;
                                return NLS.get(names[label]);
                            },
                            label(context) {
                                return context.formattedValue;
                            },
                        }, {
                            xAlign : "center",
                            yAlign : "center",
                        }),
                    },
                }}
                data={{
                    labels   : labels,
                    datasets : datasets,
                }}
            />
        </Chart>
    </Container>;
}

export default AgentCharts;
