import React                from "react";
import PropTypes            from "prop-types";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import InputField           from "Dashboard/Components/Form/InputField";



/**
 * The Card Type Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function CardTypeEdit(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const { banks } = Store.useState("cardType");
    const { editElem } = Store.useAction("cardType");


    // The Initial Data
    const initialData = {
        cardTypeID    : 0,
        bankID        : 0,
        cardTypeCode  : "",
        initialNumber : "",
        name          : "",
        category      : "",
    };

    // The Form State
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("cardType", initialData, editElem, onSubmit);

    // Load the Data
    const { loading } = useDialog("cardType", open, elemID, null, setElem);



    // Do the Render
    return <EditDialog
        open={open}
        icon="card-type"
        title={elemID ? "CARD_TYPES_EDIT_TITLE" : "CARD_TYPES_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
        isNarrow
    >
        <InputField
            name="initialNumber"
            label="CARD_TYPES_INITIAL_NUMBER"
            value={data.initialNumber}
            error={errors.initialNumber}
            onChange={handleChange}
            isRequired
        />
        <InputField
            name="cardTypeCode"
            label="GENERAL_CODE"
            value={data.cardTypeCode}
            error={errors.cardTypeCode}
            onChange={handleChange}
        />
        <InputField
            type="select"
            name="bankID"
            label="BANKS_SINGULAR"
            options={banks}
            value={data.bankID}
            error={errors.bankID}
            onChange={handleChange}
        />
        <InputField
            name="name"
            label="GENERAL_NAME"
            value={data.name}
            error={errors.name}
            onChange={handleChange}
        />
        <InputField
            name="category"
            label="CATEGORIES_SINGULAR"
            value={data.category}
            error={errors.category}
            onChange={handleChange}
        />
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
CardTypeEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default CardTypeEdit;
