import React                from "react";
import PropTypes            from "prop-types";
import Action               from "Dashboard/Core/Action";
import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import useList              from "Dashboard/Hooks/List";

// Components
import GatewayFilter        from "./GatewayFilter";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import ActionList           from "Dashboard/Components/Header/ActionList";
import Table                from "Dashboard/Components/Table/Table";
import TableHead            from "Dashboard/Components/Table/TableHead";
import TableBody            from "Dashboard/Components/Table/TableBody";
import TableRow             from "Dashboard/Components/Table/TableRow";
import TableHeader          from "Dashboard/Components/Table/TableHeader";
import TableCell            from "Dashboard/Components/Table/TableCell";
import TablePaging          from "Dashboard/Components/Table/TablePaging";
import TableActionList      from "Dashboard/Components/Table/TableActionList";
import TableAction          from "Dashboard/Components/Table/TableAction";



/**
 * The Gateway List
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function GatewayList(props) {
    const { type } = props;
    const { load, loadFilter } = useList("subsidiaryGateway", type);

    const { pharmacyID } = Navigate.useParams();
    const navigate       = Navigate.useGoto();

    const data = Store.useState("subsidiaryGateway");
    const { canEdit, list, total, filters, sort, loading } = data;


    // The Current State
    const [ action, updateAction ] = Action.useAction();


    // Handles the Action
    const handleAction = (action, elemCode) => {
        if (action.isView) {
            updateAction();
            const subsidiaryID = Utils.getValue(list, "key", elemCode, "subsidiaryID");
            navigate("BUSINESS", "SUBSIDIARIES", subsidiaryID, "GATEWAYS");
        } else {
            updateAction(action, elemCode);
        }
    };

    // Handles the Filter Submit
    const handleFilter = (filters) => {
        updateAction();
        loadFilter(filters);
    };


    // Do the Render
    return <Main>
        <Header message="GATEWAYS_NAME" icon="subsidiary">
            <ActionList data={data} onAction={handleAction} />
        </Header>
        <Content>
            <Table
                fetch={load}
                sort={sort}
                none="GATEWAYS_NONE_AVAILABLE"
                isLoading={loading}
            >
                <TableHead>
                    <TableHeader field="subsidiaryFullName" message="SUBSIDIARIES_SINGULAR" isTitle grow="2" />
                    <TableHeader field="gatewayName"        message="GATEWAYS_SINGULAR"     />
                    <TableHeader field="isActive"           message="GENERAL_IS_ACTIVE_FEM" align="center" />
                    <TableHeader field="providerID"         message="GATEWAYS_USER_ID"      />
                    <TableHeader field="expirationTime"     message="GENERAL_EXPIRES_AT"    />
                </TableHead>
                <TableBody>
                    {list.map((elem) => <TableRow key={elem.key} elemID={elem.key}>
                        <TableCell message={elem.subsidiaryFullName} />
                        <TableCell message={elem.gatewayName}        />
                        <TableCell message={elem.isActiveText}       />
                        <TableCell message={elem.providerID}         />
                        <TableCell message={elem.expirationDate}     />
                    </TableRow>)}
                </TableBody>
                <TablePaging total={total} />
                <TableActionList onAction={handleAction} canEdit={canEdit}>
                    <TableAction action="VIEW" message="GATEWAYS_VIEW_TITLE" />
                </TableActionList>
            </Table>
        </Content>

        <GatewayFilter
            open={action.isFilter}
            filters={filters}
            pharmacyID={pharmacyID}
            onSubmit={handleFilter}
            onClose={updateAction}
        />
    </Main>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
GatewayList.propTypes = {
    type : PropTypes.string.isRequired,
};

export default GatewayList;
