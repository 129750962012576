import Store                from "Dashboard/Core/Store";
import Utils                from "Dashboard/Utils/Utils";
import Commons              from "Utils/Commons";
import { DashboardAgent }   from "Utils/API";



// The initial State
const initialState = {
    loading    : true,
    error      : false,
    edition    : 0,
    totals     : {
        totalValidated   : 0,
        totalProgress    : 0,
        avgTimeValidated : "",
        avgTimeAssigned  : "",
    },
    charts     : [],
    hasFilters : false,
    filters    : {},
};



// The Actions
const actions = {
    /**
     * Starts the Loader
     * @param {Function} dispatch
     * @returns {Void}
     */
    startLoader(dispatch) {
        dispatch({ type : "DASHBOARD_AGENT_LOADING" });
    },

    /**
     * Sets the new Filters
     * @param {Function} dispatch
     * @param {Object}   params
     * @returns {Void}
     */
    setFilters(dispatch, params) {
        const { hasFilters, filters } = Commons.parseDashboardFilters(params);
        dispatch({ type : "DASHBOARD_AGENT_FILTERS", data : { hasFilters, filters } });
    },

    /**
     * Fetches the Agent Dashboard
     * @param {Function} dispatch
     * @param {Object}   params
     * @returns {Promise}
     */
    async fetchList(dispatch, params) {
        const { hasFilters, filters } = Commons.parseDashboardFilters(params);

        const data = await DashboardAgent.getAll(filters);
        data.hasFilters = hasFilters;
        data.filters    = filters;
        dispatch({ type : "DASHBOARD_AGENT_LIST", data });
    },
};



/**
 * The Reducer
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, "DASHBOARD_AGENT_LIST")) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case "DASHBOARD_AGENT_LOADING":
        return {
            ...state,
            loading    : true,
        };

    case "DASHBOARD_AGENT_FILTERS":
        return {
            ...state,
            hasFilters : action.data.hasFilters,
            filters    : action.data.filters,
        };

    case "DASHBOARD_AGENT_LIST":
        return {
            ...state,
            loading    : false,
            error      : false,
            totals     : action.data.totals,
            charts     : action.data.charts,
            hasFilters : action.data.hasFilters,
            filters    : action.data.filters,
        };

    default:
        return state;
    }
};




// The public API
export default Store.createSlice(initialState, actions, reducer);
