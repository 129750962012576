import React                from "react";
import Styled               from "styled-components";
import Store                from "Dashboard/Core/Store";
import Navigate             from "Dashboard/Core/Navigate";

// Components
import OperativeMenu        from "Components/Core/User/Operative/OperativeMenu";
import OperativeRouter      from "Components/Core/User/Operative/OperativeRouter";
import SupervisorRouter     from "Components/Core/User/Supervisor/SupervisorRouter";
import SupervisorMenu       from "Components/Core/User/Supervisor/SupervisorMenu";
import AgentMenu            from "Components/Core/User/Agent/AgentMenu";
import AgentRouter          from "Components/Core/User/Agent/AgentRouter";
import CatalogMenu          from "Components/Core/Admin/Catalog/CatalogMenu";
import CatalogRouter        from "Components/Core/Admin/Catalog/CatalogRouter";
import FinanceMenu          from "Components/Core/Admin/Finance/FinanceMenu";
import FinanceRouter        from "Components/Core/Admin/Finance/FinanceRouter";
import AdminRouter          from "Components/Core/Admin/Admin/AdminRouter";
import AdminMenu            from "Components/Core/Admin/Admin/AdminMenu";
import SuperAdminRouter     from "Components/Core/Admin/SuperAdmin/SuperAdminRouter";
import SuperAdminMenu       from "Components/Core/Admin/SuperAdmin/SuperAdminMenu";
import NotificationsBar     from "Components/App/Profile/Notifications/NotificationsBar";

// Dashboard
import Container            from "Dashboard/Components/Core/Container";
import TopBar               from "Dashboard/Components/Core/TopBar";
import SideBar              from "Dashboard/Components/Core/SideBar";
import Inside               from "Dashboard/Components/Core/Inside";
import MenuItem             from "Dashboard/Components/Menu/MenuItem";
import Image                from "Dashboard/Components/Common/Image";

// Images
import Logo                 from "Styles/Images/Logo.png";



// Styles
const Title = Styled.h2`
    margin: 0 24px 0 0;
    font-family: var(--title-font);
    font-weight: var(--title-font-weight);
    font-size: 18px;
`;



/**
 * The App
 * @returns {React.ReactElement}
 */
function App() {
    const navigate = Navigate.useGoto();

    const { credential, edition, pharmacyName, pharmacyImage, subsidiaryName, subsidiaryImage } = Store.useState("auth");

    const { logout } = Store.useAction("auth");
    const { hideTooltip } = Store.useAction("core");


    // Handles the User Logout
    const handleLogout = async () => {
        hideTooltip();
        const result = await logout();
        if (result.isAnyUser) {
            navigate("BUSINESS", "USERS");
        } else if (result.isAnyAdmin) {
            navigate("SETTINGS", "ADMIN");
        }
    };



    // Variables
    const showSubsidiary = Boolean(subsidiaryName);
    const showPharmacy   = Boolean(pharmacyName && !subsidiaryName);


    // Do the Render
    return <Container withTopBar>
        <NotificationsBar />

        <TopBar
            variant="light"
            logo={Logo}
            avatarUrl="PROFILE"
            avatarEmail={credential.email}
            avatarAvatar={credential.avatar}
            avatarEdition={edition}
            withDev
            menuItems={[
                <MenuItem
                    key="user"
                    icon="user"
                    message="ACCOUNT_MINE"
                    url="PROFILE"
                />,
                <MenuItem
                    key="logout"
                    icon="logout"
                    message="GENERAL_LOGOUT"
                    onClick={handleLogout}
                />,
            ]}
        >
            {showSubsidiary && <>
                <Image
                    source={subsidiaryImage}
                    message={subsidiaryName}
                    size="32"
                />
                <Title>{subsidiaryName}</Title>
            </>}
            {showPharmacy && <>
                <Image
                    source={pharmacyImage}
                    message={pharmacyName}
                    size="32"
                />
                <Title>{pharmacyName}</Title>
            </>}
        </TopBar>

        <SideBar
            variant="accent"
            topSpace="40"
            expandMobile
        >
            <OperativeMenu />
            <SupervisorMenu />
            <AgentMenu />

            <CatalogMenu />
            <FinanceMenu />
            <AdminMenu />
            <SuperAdminMenu />
        </SideBar>

        <Inside>
            <OperativeRouter />
            <SupervisorRouter />
            <AgentRouter />

            <CatalogRouter />
            <FinanceRouter />
            <AdminRouter />
            <SuperAdminRouter />
        </Inside>
    </Container>;
}

export default App;
