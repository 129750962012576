import React                from "react";
import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";

// Dashboard
import Main                 from "Dashboard/Components/Core/Main";
import Content              from "Dashboard/Components/Core/Content";
import Header               from "Dashboard/Components/Header/Header";
import PageContainer        from "Dashboard/Components/Page/PageContainer";
import PageAccordion        from "Dashboard/Components/Page/PageAccordion";
import PageFooter           from "Dashboard/Components/Page/PageFooter";
import AccordionItem        from "Dashboard/Components/Accordion/AccordionItem";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";
import InputItem            from "Dashboard/Components/Form/InputItem";
import Button               from "Dashboard/Components/Form/Button";



/**
 * The Logistic Page
 * @returns {React.ReactElement}
 */
function LogisticPage() {
    const { subsidiaryID } = Navigate.useParams();

    const { charging, canEdit, edition, list, logistics, saleTypes } = Store.useState("subsidiaryLogistic");
    const { fetchList, editAll } = Store.useAction("subsidiaryLogistic");


    // The Current Data
    const [ selected, setSelected ] = React.useState("");

    // The Initial Data
    const initialData = {
        logistics : {},
    };

    // Handles the Logistic
    const handleLogistic = (logisticCode, name, value) => {
        const elem      = { ...data.logistics[logisticCode], [name] : value };
        const logistics = { ...data.logistics, [logisticCode] : elem };
        handleChange("logistics", logistics);
    };

    // Handles the Edit
    const handleEdit = async () => {
        const response = await editAll({ subsidiaryID, logistics : JSON.stringify(data.logistics) });
        if (response.success) {
            fetchList(subsidiaryID);
        }
    };

    // The Form State
    const {
        loading, data, errors, setElem, handleChange, handleSubmit,
    } = useForm("subsidiaryLogistic", initialData, handleEdit);


    // Load the Data
    React.useEffect(() => {
        if (subsidiaryID) {
            fetchList(subsidiaryID);
        }
    }, [ subsidiaryID ]);

    // Store the Data
    React.useEffect(() => {
        if (logistics.length) {
            setSelected(logistics[0].logisticCode);
            setElem({ logistics : list });
        }
    }, [ edition ]);

    // Returns the Errors for the Current Logistic
    const getErrors = (logisticCode) => {
        const result = {};
        for (const [ key, error ] of Object.entries(errors)) {
            if (key.startsWith(logisticCode)) {
                const newKey = key.replace(`${logisticCode}-`, "");
                result[newKey] = error;
            }
        }
        return result;
    };


    // Do the Render
    return <Main>
        <Header message="LOGISTICS_NAME" icon="logistic" />
        <Content isLoading={charging || loading}>
            <PageContainer error={errors.form}>
                <PageAccordion
                    selected={selected}
                    onChange={setSelected}
                >
                    {logistics.map((elem) => {
                        const item   = data.logistics[elem.logisticCode] ?? {};
                        const errors = getErrors(elem.logisticCode);

                        return <AccordionItem
                            key={elem.logisticCode}
                            value={elem.logisticCode}
                            message={elem.logisticCode}
                            withGap
                        >
                            <InputField
                                type="toggle"
                                name="isActive"
                                label="GENERAL_IS_ACTIVE_FEM"
                                value={item.isActive || 0}
                                onChange={(name, value) => handleLogistic(elem.logisticCode, name, value)}
                                isDisabled={!canEdit}
                                withBorder
                            />
                            <InputField
                                type="chooser"
                                name="saleTypeIDs"
                                label="SALE_TYPES_NAME"
                                options={saleTypes}
                                value={item.saleTypeIDs || ""}
                                error={errors.saleTypeIDs}
                                onChange={(name, value) => handleLogistic(elem.logisticCode, name, value)}
                                isDisabled={!canEdit}
                                withBorder
                            />

                            <Columns amount="2">
                                <InputField
                                    type="toggle"
                                    name="useFee"
                                    label="LOGISTICS_USE_FEE"
                                    value={item.useFee || ""}
                                    onChange={(name, value) => handleLogistic(elem.logisticCode, name, value)}
                                    withBorder
                                />

                                <InputField
                                    type="double"
                                    label="LOGISTICS_FEE"
                                    error={errors.feeValue}
                                    onChange={(name, value) => handleLogistic(elem.logisticCode, name, value)}
                                >
                                    <InputItem
                                        type="select"
                                        name="feeType"
                                        options="SELECT_FEE_TYPES"
                                        value={item.feeType || ""}
                                    />
                                    <InputItem
                                        type="number"
                                        name="feeValue"
                                        value={item.feeValue || ""}
                                        icon={item.feeType === "fixed" ? "price" : ""}
                                        postIcon={item.feeType === "percent" ? "percent" : ""}
                                        minValue={0}
                                        step={0.01}
                                    />
                                </InputField>
                            </Columns>

                            <Columns>
                                <InputField
                                    type="number"
                                    name="minKM"
                                    label="LOGISTICS_MIN_KM"
                                    value={item.minKM || ""}
                                    error={errors.minKM}
                                    onChange={(name, value) => handleLogistic(elem.logisticCode, name, value)}
                                    minValue={0}
                                    step={1}
                                />
                                <InputField
                                    type="number"
                                    name="maxKM"
                                    label="LOGISTICS_MAX_KM"
                                    value={item.maxKM || ""}
                                    error={errors.maxKM}
                                    onChange={(name, value) => handleLogistic(elem.logisticCode, name, value)}
                                    minValue={0}
                                    step={1}
                                />
                            </Columns>
                        </AccordionItem>;
                    })}
                </PageAccordion>

                <PageFooter isHidden={!canEdit}>
                    <Button
                        variant="primary"
                        message="GENERAL_SAVE"
                        onClick={() => handleSubmit()}
                        isDisabled={loading}
                    />
                </PageFooter>
            </PageContainer>
        </Content>
    </Main>;
}

export default LogisticPage;
