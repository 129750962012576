import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";

// Components
import DocumentList         from "./DocumentList";

// Dashboard
import InputField           from "Dashboard/Components/Form/InputField";
import NoneAvailable        from "Dashboard/Components/Common/NoneAvailable";



// Styles
const Container = Styled.aside`
    box-sizing: border-box;
    height: var(--document-height);
`;

const List = Styled.section`
    box-sizing: border-box;
    padding: 8px;
    overflow: auto;
    height: calc(var(--document-height) - 50px);
`;

const None = Styled(NoneAvailable)`
    text-align: center;
`;



/**
 * The Document Aside
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function DocumentAside(props) {
    const { roots, onClick } = props;


    // The Current State
    const [ search, setSearch ] = React.useState("");


    // Filters the Documents
    const filterDocuments = (document, search, isVisible) => {
        document.isVisible = false;
        if (isVisible) {
            document.isVisible = isVisible;
        } else if (!search.length) {
            document.isVisible = true;
        } else if (String(document.name).toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
            document.isVisible = true;
        }

        let childVisible = document.isVisible;
        for (const child of document.children) {
            const isVisible = filterDocuments(child, search, document.isVisible);
            if (isVisible) {
                childVisible = true;
            }
        }
        if (!document.isVisible && childVisible) {
            document.isVisible = true;
        }
        return document.isVisible;
    };

    // Filter the Documents
    const documents = React.useMemo(() => {
        for (const root of roots) {
            root.isVisible = filterDocuments(root, search);
        }
        return roots;
    }, [ search, JSON.stringify(roots) ]);



    // Do the Render
    const showHeader   = documents.length > 0;
    const hasDocuments = documents.filter((elem) => elem.isVisible).length > 0;

    return <Container>
        {showHeader && <header>
            <InputField
                icon="search"
                placeholder="GENERAL_SEARCH"
                value={search}
                onChange={(name, value) => setSearch(value)}
                hasClear
                isSmall
            />
        </header>}

        <List>
            {!hasDocuments && <None
                message="DOCUMENTS_NONE_AVAILABLE"
            />}
            {hasDocuments && <DocumentList
                documents={documents}
                onClick={onClick}
            />}
        </List>
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
DocumentAside.propTypes = {
    roots   : PropTypes.array.isRequired,
    onClick : PropTypes.func.isRequired,
};

export default DocumentAside;
