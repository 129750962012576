import React                from "react";
import Navigate             from "Dashboard/Core/Navigate";
import Store                from "Dashboard/Core/Store";

// Components
import SubsidiaryPage       from "./Subsidiary/SubsidiaryPage";
import SubsidiaryView       from "./Subsidiary/SubsidiaryView";
import ScheduleList         from "./Schedule/ScheduleList";
import ClosedList           from "./Closed/ClosedList";
import CoverageList         from "./Coverage/CoverageList";
import GatewayPage          from "./Gateway/GatewayPage";
import LogisticPage         from "./Logistic/LogisticPage";
import UserList             from "./User/UserList";
import ContactList          from "./Contact/ContactList";
import OrderList            from "Components/App/Order/Order/OrderList";
import OrderPage            from "Components/App/Order/Order/OrderPage";

// Dashboard
import Router               from "Dashboard/Components/Route/Router";
import AdminRoute           from "Dashboard/Components/Route/AdminRoute";
import SecondaryNav         from "Dashboard/Components/Navigation/SecondaryNav";
import NavigationList       from "Dashboard/Components/Navigation/NavigationList";
import NavigationItem       from "Dashboard/Components/Navigation/NavigationItem";



/**
 * The Subsidiary Container
 * @returns {React.ReactElement}
 */
function SubsidiaryContainer() {
    const { subsidiaryID } = Navigate.useParams();

    const { isFinance } = Store.useState("auth");

    const { elem } = Store.useState("subsidiary");
    const { fetchElem } = Store.useAction("subsidiary");


    // Load the Data
    React.useEffect(() => {
        fetchElem(subsidiaryID);
    }, [ subsidiaryID ]);


    // Do the Render
    return <>
        <SecondaryNav
            message={elem.fullName}
            fallback="SUBSIDIARIES_SINGULAR"
            href={Navigate.useFrom()}
        >
            <NavigationList>
                <NavigationItem
                    message="GENERAL_INFO"
                    url="INFORMATION"
                    icon="subsidiary"
                />
                <NavigationItem
                    message="SCHEDULES_NAME"
                    url="SCHEDULES"
                    icon="schedule"
                />
                <NavigationItem
                    message="CLOSED_DAYS_NAME"
                    url="CLOSED_DAYS"
                    icon="closed-day"
                />
                <NavigationItem
                    message="COVERAGES_NAME"
                    url="COVERAGES"
                    icon="coverage"
                />
                <NavigationItem
                    message="GATEWAYS_NAME"
                    url="GATEWAYS"
                    icon="gateway"
                />
                <NavigationItem
                    message="LOGISTICS_NAME"
                    url="LOGISTICS"
                    icon="logistic"
                />
            </NavigationList>

            <NavigationList>
                <NavigationItem
                    message="ORDERS_NAME"
                    url="ORDERS"
                    icon="order"
                />
                <NavigationItem
                    isHidden={isFinance}
                    message="USERS_NAME"
                    url="USERS"
                    icon="user"
                />
                <NavigationItem
                    message="CONTACTS_NAME"
                    url="CONTACTS"
                    icon="contact"
                />
            </NavigationList>
        </SecondaryNav>

        <Router type="SUBSIDIARY">
            <AdminRoute url="INFORMATION" component={SubsidiaryPage} isHidden={isFinance} />
            <AdminRoute url="INFORMATION" component={SubsidiaryView} isHidden={!isFinance} />
            <AdminRoute url="SCHEDULES"   component={ScheduleList}   />
            <AdminRoute url="CLOSED_DAYS" component={ClosedList}     />
            <AdminRoute url="COVERAGES"   component={CoverageList}   />
            <AdminRoute url="GATEWAYS"    component={GatewayPage}    />
            <AdminRoute url="LOGISTICS"   component={LogisticPage}   />
            <AdminRoute url="USERS"       component={UserList}       />
            <AdminRoute url="CONTACTS"    component={ContactList}    />

            <AdminRoute url="ORDERS"      component={OrderList}      />
            <AdminRoute url="ORDERS_TAB"  component={OrderList}      />
            <AdminRoute url="ORDER"       component={OrderPage}      />
        </Router>
    </>;
}

export default SubsidiaryContainer;
