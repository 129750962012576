import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import DialogMessage        from "Dashboard/Components/Dialog/DialogMessage";
import InputField           from "Dashboard/Components/Form/InputField";



// Styles
const List = Styled.ul`
    margin-top: 0;
    margin-bottom: 16px;
    padding-left: 32px;
`;



/**
 * The Space Import Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function SpaceImport(props) {
    const { open, elemID, elemName, onClose, onSubmit } = props;

    const { uploadImport, importProducts } = Store.useAction("storeSpace");


    // The Current State
    const [ step,        setStep        ] = React.useState("file");
    const [ importClass, setImportClass ] = React.useState("");
    const [ importText,  setImportText  ] = React.useState("");
    const [ products,    setProducts    ] = React.useState([]);

    // The Initial Data
    const initialData = {
        file     : null,
        fileName : "",
        filePath : "",
    };


    // Restore the State
    React.useEffect(() => {
        if (open) {
            setElem({ ...initialData });
            setStep("file");
        }
    }, [ open ]);

    // Handles the Edit
    const handleEdit = (data) => {
        if (step === "file") {
            return uploadImport({ ...data, storeSpaceID : elemID });
        }
        return importProducts({
            storeSpaceID : elemID,
            filePath     : data.filePath,
        });
    };

    // Handles the After Submit
    const handleAfterSubmit = (data) => {
        if (step === "import" && data.success) {
            onSubmit();
            return;
        }

        setElem({ filePath : data.filePath });
        if (data.products.length) {
            setImportClass("text-red");
            setImportText(NLS.format("SPACES_IMPORT_INVALID", data.total, data.products.length));
        } else {
            setImportClass("text-green");
            setImportText(NLS.format("SPACES_IMPORT_VALID", data.total));
        }
        setProducts(data.products);
        setStep("import");
    };

    // The Form State
    const {
        loading, data, errors, setElem, setError, handleChange, handleSubmit,
    } = useForm("storeSpace", initialData, handleEdit, handleAfterSubmit, false, open);



    // Do the Render
    const showFile   = step === "file";
    const showImport = step === "import";

    return <EditDialog
        open={open}
        icon="import"
        title="SPACES_IMPORT_TITLE"
        error={errors.form}
        primary={showImport ? "GENERAL_CONTINUE" : "GENERAL_IMPORT"}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <DialogMessage
            message={NLS.format("SPACES_IMPORT_TEXT", elemName)}
        />

        {showFile && <>
            <DialogMessage message="SPACES_IMPORT_FILE" />
            <InputField
                isHidden={!showFile}
                type="file"
                name="file"
                label="MEDIA_SINGULAR"
                value={data.fileName}
                error={errors.file}
                maxSize={process.env.REACT_APP_MAX_SIZE}
                onChange={handleChange}
                onError={setError}
            />
        </>}

        {showImport && <>
            <DialogMessage
                className={importClass}
                message={importText}
            />
            <List>
                {products.map((name) => <li
                    key={name}
                >{name}</li>)}
            </List>
            <DialogMessage message="SPACES_IMPORT_CONTINUE" />
        </>}
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
SpaceImport.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
    elemName : PropTypes.string,
};

export default SpaceImport;
