import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";

// Components
import ProcessFile          from "../ProcessFile";
import ProcessSection       from "../Utils/ProcessSection";

// Dashboard
import InputField           from "Dashboard/Components/Form/InputField";
import Button               from "Dashboard/Components/Form/Button";



// Styles
const Container = Styled(ProcessSection)`
    grid-area: request;
    min-height: 120px;
`;

const Notes = Styled.div`
    margin-bottom: 16px;
    b {
        display: block;
        margin-bottom: 4px;
    }
`;



/**
 * The Process View Request
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ProcessRequest(props) {
    const { data, errors, onChange } = props;

    const { elem, recipeFiles } = Store.useState("dashboardProcess");

    // The Current State
    const [ recipeIndex, setRecipeIndex ] = React.useState(0);


    // Variables
    const showObservations = Boolean(!elem.canEditObservations && elem.observations);
    const recipe           = recipeFiles[recipeIndex - 1] ?? {};


    // Do the Render
    return <>
        <Container message="ORDERS_CLIENT_REQUEST">
            <Notes>
                <b>{NLS.get("ORDERS_CLIENT_PRODUCTS")}</b>
                {elem.products || NLS.get("GENERAL_NONE")}
            </Notes>

            {elem.canEditObservations && <InputField
                type="textarea"
                name="observations"
                label="ORDERS_AGENT_NOTES"
                value={data.observations}
                error={errors.observations}
                onChange={onChange}
                rows={2}
                maxLength={250}
            />}

            {showObservations && <Notes>
                <b>{NLS.get("ORDERS_AGENT_NOTES")}</b>
                {elem.observations || NLS.get("GENERAL_NONE")}
            </Notes>}

            {recipeFiles.map((elem, index) => <Button
                key={index}
                variant="outlined"
                icon="attachment"
                message={NLS.format("ORDERS_RECIPE_VIEW", index + 1)}
                onClick={() => setRecipeIndex(index + 1)}
                fullWidth
                isSmall
            />)}
        </Container>

        <ProcessFile
            open={recipeIndex !== 0}
            title={NLS.format("ORDERS_RECIPE_VIEW", String(recipeIndex))}
            isPDF={recipe.isPDF ?? false}
            isImage={recipe.isImage ?? false}
            fileUrl={recipe.url ?? ""}
            onClose={() => setRecipeIndex(0)}
        />
    </>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ProcessRequest.propTypes = {
    data     : PropTypes.object.isRequired,
    errors   : PropTypes.object.isRequired,
    onChange : PropTypes.func.isRequired,
};

export default ProcessRequest;
