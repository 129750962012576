import React                from "react";
import Store                from "Dashboard/Core/Store";

// Components
import DashboardTotals      from "../../Utils/Components/DashboardTotals";
import DashboardTotal       from "../../Utils/Components/DashboardTotal";



/**
 * The Agent Totals
 * @returns {React.ReactElement}
 */
function AgentTotals() {
    const { totals } = Store.useState("dashboardAgent");


    // Do the Render
    return <DashboardTotals>
        <DashboardTotal
            message="DASHBOARD_AGENT_VALIDATED"
            tooltip="DASHBOARD_AGENT_VALIDATED_TP"
            value={totals.totalValidated}
        />
        <DashboardTotal
            message="DASHBOARD_AGENT_ASSGINED"
            tooltip="DASHBOARD_AGENT_ASSGINED_TP"
            value={totals.totalProgress}
        />

        <DashboardTotal
            message="DASHBOARD_AGENT_AVG_VALIDATED"
            tooltip="DASHBOARD_AGENT_AVG_VALIDATED_TP"
            value={totals.avgTimeValidated}
            isText
        />
        <DashboardTotal
            message="DASHBOARD_AGENT_AVG_ASSIGNED"
            tooltip="DASHBOARD_AGENT_AVG_ASSIGNED_TP"
            value={totals.avgTimeAssigned}
            isText
        />
    </DashboardTotals>;
}

export default AgentTotals;
