import React                from "react";
import { Line }             from "react-chartjs-2";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import Charts               from "Utils/Charts";

// Components
import DashboardReport      from "../../Utils/Components/DashboardReport";



/**
 * The Commercial Evolution
 * @returns {React.ReactElement}
 */
function CommercialEvolution() {
    const { evolutionChart, holidays, events } = Store.useState("dashboardCommercial");

    const { labels, retirements } = evolutionChart;


    // Generates the Datasets
    const datasets = React.useMemo(() => {
        const result = [];
        for (const [ index, name ] of Object.entries(retirements)) {
            result.push({
                order            : Number(result.length),
                yAxisID          : "y",
                label            : NLS.format("DASHBOARD_DAILY_ORDERS_CHART", name),
                data             : evolutionChart[`totalRetirements${index}`],
                borderColor      : Charts.getColor(result.length),
                backgroundColor  : Charts.getColor(result.length),
                pointBorderWidth : 4,
                tension          : 0.1,
            });
            result.push({
                order            : Number(result.length),
                yAxisID          : "y",
                label            : NLS.format("DASHBOARD_DAILY_EFFECTIVE_CHART", name),
                data             : evolutionChart[`effectiveRetirements${index}`],
                borderColor      : Charts.getColor(result.length),
                backgroundColor  : Charts.getColor(result.length),
                pointBorderWidth : 4,
                tension          : 0.1,
            });
        }
        return result;
    }, [ JSON.stringify(evolutionChart) ]);


    // Do the Render
    return <DashboardReport
        message="DASHBOARD_COMMERCIAL_ORDER_EVOLUTION"
        tooltip="DASHBOARD_COMMERCIAL_ORDER_EVOLUTION_TP"
        height="400"
    >
        <Line
            options={{
                maintainAspectRatio : false,
                interaction : {
                    mode      : "index",
                    intersect : false,
                },
                scales : {
                    x : Charts.getDateScale(holidays, events, true),
                    y : Charts.getSimpleScale(false, false, false),
                },
                plugins : {
                    legend  : Charts.getLegendOptions("top"),
                    tooltip : Charts.getDateTooltip(holidays, events, true),
                },
            }}
            data={{
                labels   : labels,
                datasets : datasets,
            }}
        />
    </DashboardReport>;
}

export default CommercialEvolution;
