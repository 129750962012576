import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";

// Dashboard
import Html                 from "Dashboard/Components/Common/Html";



// Styles
const Container = Styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const Title = Styled.h4`
    margin: 0;
    font-weight: 400;
    font-style: italic;
    color: var(--darkest-gray);
`;



/**
 * The View Item
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function ViewItem(props) {
    const {
        isHidden, className, viewClass,
        message, value, hideEmpty, children,
    } = props;


    // Do the Render
    if (isHidden || (!value && !children && hideEmpty)) {
        return <React.Fragment />;
    }

    return <Container className={className}>
        <Title>{NLS.get(message)}</Title>
        {value && <Html
            className={viewClass}
            content={String(value)}
        />}
        {!value && <div className={viewClass}>
            {children}
        </div>}
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
ViewItem.propTypes = {
    isHidden  : PropTypes.bool,
    className : PropTypes.string,
    viewClass : PropTypes.string,
    message   : PropTypes.string.isRequired,
    value     : PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    hideEmpty : PropTypes.bool,
    children  : PropTypes.any,
};

export default ViewItem;
