import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";

// Dashboard
import Icon                 from "Dashboard/Components/Common/Icon";



// Styles
const Container = Styled.h3`
    position: relative;
    margin: 0;
    color: var(--alternative-color);
    font-family: var(--title-font);
    font-weight: var(--title-font-weight);
    font-size: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Help = Styled(Icon)`
    position: absolute;
    top: 0;
    right: 0;
    font-size: 20px;
    color: var(--primary-color);
`;



/**
 * The Dashboard Title
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function DashboardTitle(props) {
    const { message, tooltip } = props;


    // Do the Render
    return <Container>
        {NLS.get(message)}
        <Help
            isHidden={!tooltip}
            icon="help"
            tooltip={tooltip}
            tooltipWidth={200}
            tooltipDelay={0.2}
        />
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
DashboardTitle.propTypes = {
    message : PropTypes.string.isRequired,
    tooltip : PropTypes.string,
};

export default DashboardTitle;
