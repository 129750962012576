import React                from "react";
import Store                from "Dashboard/Core/Store";

// Components
import DoughnutChart        from "../../Utils/Charts/DoughnutChart";



/**
 * The Commercial Retirements
 * @returns {React.ReactElement}
 */
function CommercialRetirements() {
    const { retirementTotals } = Store.useState("dashboardCommercial");


    // Do the Render
    return <DoughnutChart
        title="DASHBOARD_COMMERCIAL_RETIREMENTS"
        tooltip="DASHBOARD_COMMERCIAL_RETIREMENTS_TP"
        field="effectiveOrders"
        data={retirementTotals}
    />;
}

export default CommercialRetirements;
