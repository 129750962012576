import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import NLS                  from "Dashboard/Core/NLS";
import Store                from "Dashboard/Core/Store";
import useForm              from "Dashboard/Hooks/Form";
import useDialog            from "Dashboard/Hooks/Dialog";

// Dashboard
import EditDialog           from "Dashboard/Components/Dialogs/EditDialog";
import Columns              from "Dashboard/Components/Form/Columns";
import InputField           from "Dashboard/Components/Form/InputField";



// Styles
const Title = Styled.h3`
    margin: 16px 0 0 0;
    font-family: var(--title-font);
    font-weight: var(--title-font-weight);
    font-size: 18px;
`;



/**
 * The Pharmacy Edit Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function PharmacyEdit(props) {
    const { open, elemID, onClose, onSubmit } = props;

    const { editElem } = Store.useAction("pharmacy");


    // The Initial Data
    const initialData = {
        pharmacyID         : 0,
        name               : "",
        image              : "",
        color              : "",
        hasWebChat         : 0,
        webChatID          : "",
        retireDistance     : "",
        enableAgent        : 1,
        hideInPharmacyList : 0,
        forTesting         : 0,
        orderTesting       : 0,
    };

    // The Form Pharmacy
    const {
        data, errors, setElem, handleChange, handleSubmit,
    } = useForm("pharmacy", initialData, editElem, onSubmit);

    // Load the Data
    const { loading } = useDialog("pharmacy", open, elemID, null, setElem);


    // Do the Render
    return <EditDialog
        open={open}
        icon="pharmacy"
        title={elemID ? "PHARMACIES_EDIT_TITLE" : "PHARMACIES_CREATE_TITLE"}
        error={errors.form}
        onSubmit={handleSubmit}
        onClose={onClose}
        isLoading={loading}
    >
        <Columns>
            <InputField
                name="name"
                label="GENERAL_NAME"
                value={data.name}
                error={errors.name}
                onChange={handleChange}
                isRequired
            />
            <InputField
                type="color"
                name="color"
                label="GENERAL_COLOR"
                value={data.color}
                error={errors.color}
                onChange={handleChange}
            />
            <InputField
                className="columns-double"
                name="image"
                label="GENERAL_IMAGE_URL"
                value={data.image}
                error={errors.image}
                onChange={handleChange}
            />

            <Title className="columns-double">
                {NLS.get("GENERAL_OPTIONS")}
            </Title>

            <InputField
                className={!data.hasWebChat ? "columns-double" : ""}
                type="toggle"
                name="hasWebChat"
                label="PHARMACIES_HAS_WEB_CHAT"
                value={data.hasWebChat}
                error={errors.hasWebChat}
                onChange={handleChange}
                withBorder
            />
            <InputField
                isHidden={!data.hasWebChat}
                name="webChatID"
                label="PHARMACIES_WEB_CHAT_ID"
                helperText="PHARMACIES_WEB_CHAT_ID_HELP"
                value={data.webChatID}
                error={errors.webChatID}
                onChange={handleChange}
            />

            <InputField
                type="number"
                name="retireDistance"
                label="PHARMACIES_RETIRE_DISTANCE"
                helperText="PHARMACIES_RETIRE_DISTANCE_HELP"
                value={data.retireDistance}
                error={errors.retireDistance}
                onChange={handleChange}
            />
            <InputField
                type="toggle"
                name="enableAgent"
                label="PHARMACIES_ENABLE_AGENT"
                value={data.enableAgent}
                error={errors.enableAgent}
                onChange={handleChange}
                withBorder
            />
            <InputField
                className="columns-double"
                type="toggle"
                name="hideInPharmacyList"
                label="PHARMACIES_HIDE_IN_LIST"
                value={data.hideInPharmacyList}
                error={errors.hideInPharmacyList}
                onChange={handleChange}
                withBorder
            />
            <InputField
                type="toggle"
                name="forTesting"
                label="PHARMACIES_FOR_TESTING"
                value={data.forTesting}
                error={errors.forTesting}
                onChange={handleChange}
                withBorder
            />
            <InputField
                type="toggle"
                name="orderTesting"
                label="PHARMACIES_ORDER_TESTING"
                value={data.orderTesting}
                error={errors.orderTesting}
                onChange={handleChange}
                withBorder
            />
        </Columns>
    </EditDialog>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
PharmacyEdit.propTypes = {
    open     : PropTypes.bool.isRequired,
    onClose  : PropTypes.func.isRequired,
    onSubmit : PropTypes.func.isRequired,
    elemID   : PropTypes.number,
};

export default PharmacyEdit;
